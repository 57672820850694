import { isRootLinkActive } from '../Sidebar';
const getSessionStorage = (key) => {
    try {
        return sessionStorage.getItem(key);
    }
    catch (error) {
        return undefined;
    }
};
export default function useActiveTab({ sidebarItems, defaultTab, tabs }) {
    var _a, _b, _c;
    const activeTabs = tabs.filter(tab => {
        return isRootLinkActive(tab.homeApp.path) || sidebarItems.filter(({ tabs = [] }) => tabs.includes(tab.id)).some(app => isRootLinkActive(app.path));
    });
    const activeTab = activeTabs.length > 1
        ? (_a = getSessionStorage('lastActiveTab')) !== null && _a !== void 0 ? _a : defaultTab
        : (_c = (_b = activeTabs === null || activeTabs === void 0 ? void 0 : activeTabs[0]) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : defaultTab;
    const setLastActiveTab = (value) => sessionStorage.setItem('lastActiveTab', value);
    return {
        activeTab,
        setLastActiveTab
    };
}
