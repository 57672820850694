import React from 'react';
import { BotNodeId } from '../../../types';
import { INeedMoreHelpThatHelped } from '../../common';
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage';
const payoutNotFoundFlow = [
    {
        id: BotNodeId.SETTLEMENTS_PAYOUT_NOT_FOUND,
        botMessage: null,
        options: () => [
            {
                next: BotNodeId.SETTLEMENTS_PAYOUT_NOT_FOUND_ORDER_NUMBER_NOT_IN_REPORT,
                value: 'helpbot.settlements.payoutNotFound.orderNumberNotFound.question'
            },
            {
                next: BotNodeId.SETTLEMENTS_PAYOUT_NOT_FOUND_CHECK_IF_PAID_FOR_ORDER,
                value: 'helpbot.settlements.payoutNotFound.checkIfPaidForOrder.question'
            }
        ]
    },
    {
        id: BotNodeId.SETTLEMENTS_PAYOUT_NOT_FOUND_ORDER_NUMBER_NOT_IN_REPORT,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.settlements.payoutNotFound.orderNumberNotFound.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.SETTLEMENTS_PAYOUT_NOT_FOUND_CHECK_IF_PAID_FOR_ORDER,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.settlements.payoutNotFound.checkIfPaidForOrder.answer' })),
        options: () => INeedMoreHelpThatHelped
    }
];
export default payoutNotFoundFlow;
