import * as Sentry from '@sentry/react';
export default function handleError(error, options = {}) {
    // eslint-disable-next-line no-console
    const { componentStack, errorLogger = console.error, extras = {} } = options;
    errorLogger('Experimentation error caught:', error);
    if (componentStack) {
        errorLogger('Component stack:', componentStack);
    }
    const isExpectedError = error.request && [0, 400, 401, 403, 415].includes(error.request.status);
    if (!isExpectedError) {
        const mergedExtras = Object.assign({ request: error.request, response: error.response }, extras);
        let errorId;
        Sentry.withScope(scope => {
            scope.setTag('team', 'mpp');
            scope.setExtras(mergedExtras);
            errorId = Sentry.captureException(error);
        });
        errorLogger('Error id:', errorId);
        return errorId;
    }
}
