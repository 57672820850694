import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectors as coreSelectors } from 'mage-core';
import { updateClientGenerator } from 'mage-conversation';
export const useRefreshClientGenerator = () => {
    // @ts-ignore
    const token = useSelector(coreSelectors.getAccessToken);
    const generateClient = useSelector(coreSelectors.createBackendClient);
    useEffect(() => {
        updateClientGenerator(generateClient);
    }, [token]);
};
