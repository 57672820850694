const AMAZON_CONNECT_CONFIG = {
    development_eu: {
        snippet: 'QVFJREFIZ2ZySXd1eE1EWFNRRkRQSWFXZjRvaGxjT0xmd1pOWWViVU4vd0hnZnByS1FGMm0wN0VqWlJTM21VUjY1S0J2cXgzQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNSlVXV2FkVWk5bUJmcjVnK0FnRVFnQ3VhYlNMSUhmV1MwdTFTVDdncHJnL2RyRVVtbXNCaEhUVnExS29MSk5mZFFxR1JudTIzN3liRGRYcVE6OlhHRVgweHE5Znp6d1N3bmdKck1Gd01oQ0xwYWhVcU5zWTA2a1RtbWhlNHBpcC9hb1I1WWdOVmtJVzhUYklPNEdqQlgrZm1rQ2duVTJMbVQvRnhsWWREeDUzL2hzWnhKZlB2QjFYU0xsYVpVNnl2RHlVN3dCd2lsMlZ6dTVNTm5SNEVlNlUvRFJWZXFwcUtmVTgwWDlYdHpabGhES09ZTT0=',
        widgetId: '77ca9d6e-4672-4d3f-aede-43d2e79d6f2b'
    },
    staging_eu: {
        snippet: 'QVFJREFIZ2ZySXd1eE1EWFNRRkRQSWFXZjRvaGxjT0xmd1pOWWViVU4vd0hnZnByS1FGMm0wN0VqWlJTM21VUjY1S0J2cXgzQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNSlVXV2FkVWk5bUJmcjVnK0FnRVFnQ3VhYlNMSUhmV1MwdTFTVDdncHJnL2RyRVVtbXNCaEhUVnExS29MSk5mZFFxR1JudTIzN3liRGRYcVE6OlhHRVgweHE5Znp6d1N3bmdKck1Gd01oQ0xwYWhVcU5zWTA2a1RtbWhlNHBpcC9hb1I1WWdOVmtJVzhUYklPNEdqQlgrZm1rQ2duVTJMbVQvRnhsWWREeDUzL2hzWnhKZlB2QjFYU0xsYVpVNnl2RHlVN3dCd2lsMlZ6dTVNTm5SNEVlNlUvRFJWZXFwcUtmVTgwWDlYdHpabGhES09ZTT0=',
        widgetId: '77ca9d6e-4672-4d3f-aede-43d2e79d6f2b'
    },
    staging_us: {
        snippet: 'QVFJREFIZ2ZySXd1eE1EWFNRRkRQSWFXZjRvaGxjT0xmd1pOWWViVU4vd0hnZnByS1FGMm0wN0VqWlJTM21VUjY1S0J2cXgzQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNSlVXV2FkVWk5bUJmcjVnK0FnRVFnQ3VhYlNMSUhmV1MwdTFTVDdncHJnL2RyRVVtbXNCaEhUVnExS29MSk5mZFFxR1JudTIzN3liRGRYcVE6OlhHRVgweHE5Znp6d1N3bmdKck1Gd01oQ0xwYWhVcU5zWTA2a1RtbWhlNHBpcC9hb1I1WWdOVmtJVzhUYklPNEdqQlgrZm1rQ2duVTJMbVQvRnhsWWREeDUzL2hzWnhKZlB2QjFYU0xsYVpVNnl2RHlVN3dCd2lsMlZ6dTVNTm5SNEVlNlUvRFJWZXFwcUtmVTgwWDlYdHpabGhES09ZTT0=',
        widgetId: '77ca9d6e-4672-4d3f-aede-43d2e79d6f2b'
    },
    staging_ap: {
        snippet: 'QVFJREFIZ2ZySXd1eE1EWFNRRkRQSWFXZjRvaGxjT0xmd1pOWWViVU4vd0hnZnByS1FGMm0wN0VqWlJTM21VUjY1S0J2cXgzQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNSlVXV2FkVWk5bUJmcjVnK0FnRVFnQ3VhYlNMSUhmV1MwdTFTVDdncHJnL2RyRVVtbXNCaEhUVnExS29MSk5mZFFxR1JudTIzN3liRGRYcVE6OlhHRVgweHE5Znp6d1N3bmdKck1Gd01oQ0xwYWhVcU5zWTA2a1RtbWhlNHBpcC9hb1I1WWdOVmtJVzhUYklPNEdqQlgrZm1rQ2duVTJMbVQvRnhsWWREeDUzL2hzWnhKZlB2QjFYU0xsYVpVNnl2RHlVN3dCd2lsMlZ6dTVNTm5SNEVlNlUvRFJWZXFwcUtmVTgwWDlYdHpabGhES09ZTT0=',
        widgetId: '77ca9d6e-4672-4d3f-aede-43d2e79d6f2b'
    }
};
export const getConfig = (ctx) => {
    const { env, region } = ctx;
    const configKey = `${env}_${region}`;
    // TODO: Add the real values for production and update the default return
    return AMAZON_CONNECT_CONFIG[configKey] || AMAZON_CONNECT_CONFIG.development_eu;
};
