export const SIDEBAR_DEFAULT_WIDTH = 256;
export const SIDEBAR_SUBMENU_WIDTH = 240;
export const SIDEBAR_TABLET_WIDTH = 68;
export const SIDEBAR_SUB_MENU_POPOVER_WIDTH = 240;
export const SIDEPANEL_WIDTH = 360;
export const APP_CONTENT_MAX_WIDTH = 1608;
export const zIndex = {
    root: 0,
    mainContent: 1,
    sidePanel: 2,
    topbar: 3,
    sidebarContainer: 4,
    appContent: {
        root: 0,
        main: 1,
        pageHeader: 2
    },
    sidebar: {
        backdrop: 1,
        mobileBackground: 2,
        menu: 3,
        hamburgerMenu: 4,
        subMenu: 5
    }
};
