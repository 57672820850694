/* eslint-disable no-undef */
import React, { createContext, useContext, useState } from 'react';
import { getBotNode } from '../helpbotFlow';
import { useHelpContext } from '../../../hooks';
import { BotNodeId, UxEvent } from '../types';
import { HELPBOT_SESSION_STORAGE_KEY } from '../../../constants';
import { storeDataIntoSession, getDataFromSessionStorage } from '../../../utilities/sessionStorage';
const HELPBOT_ROOT_NODE_ID = BotNodeId.WHAT_HELP_WITH;
export const HelpbotState = createContext(undefined);
export const HelpbotStateProvider = ({ children }) => {
    // @ts-ignore
    const helpHook = useHelpContext();
    const [userPath, setUserPath] = useState(getInitialState());
    const isLastStep = (pathIndex) => pathIndex === userPath.length - 1;
    const lastStepNodeId = userPath[userPath.length - 1];
    const lastStepNode = getBotNode(lastStepNodeId);
    const getStepResponseValue = (botNode, pathIndex) => {
        if (isLastStep(pathIndex))
            return null;
        const pickedOption = botNode.options().find(o => o.next === userPath[pathIndex + 1]);
        if (!pickedOption)
            throw Error('cannot get the user answer');
        return pickedOption.value;
    };
    const getActiveNodeId = () => userPath[userPath.length - 1];
    const getBotStep = (nodeId) => {
        const pathIndex = userPath.indexOf(nodeId);
        if (pathIndex < 0)
            throw Error('cannot fetch bot step as not in user path');
        const botNode = getBotNode(nodeId);
        const responseValue = getStepResponseValue(botNode, pathIndex);
        return { node: botNode, responseValue };
    };
    const isUxEvent = (value) => Object.keys(UxEvent).includes(value);
    const handleAnswerForActiveStep = (selectedValue) => {
        const pickedOption = getLatestPickedOption(selectedValue);
        if (!pickedOption)
            throw Error('cannot handle helpbot next step: the selected value is wrong');
        if (isUxEvent(pickedOption.next)) {
            handleUxEvent(pickedOption.next);
            return;
        }
        handleNextNode(pickedOption.next);
    };
    const getLatestPickedOption = (selectedValue) => lastStepNode.options().find(o => o.value === selectedValue);
    const handleNextNode = (nextNodeId) => {
        const newPath = [...userPath, nextNodeId];
        setUserPath(newPath);
        storeDataIntoSession(HELPBOT_SESSION_STORAGE_KEY, newPath);
    };
    const goToChannelSelection = () => helpHook.navigation.push('supportChannelSelector');
    const handleUxEvent = (uxEvent) => {
        switch (uxEvent) {
            case UxEvent.GO_TO_HELP_HOME:
                helpHook.navigation.reset();
                break;
            case UxEvent.GO_TO_CHANNEL_SELECTION:
                setUserPath([...userPath, UxEvent.GO_TO_CHANNEL_SELECTION]);
                if (helpHook.isChatSelectorInChatEnabled)
                    return;
                goToChannelSelection();
                break;
            default:
                throw Error('cannot handle unknown UX event');
        }
    };
    const stateManager = {
        userPath,
        getBotStep,
        handleAnswerForActiveStep,
        handleUxEvent,
        getActiveNodeId
    };
    return (React.createElement(HelpbotState.Provider, { value: stateManager }, children));
};
export const useHelpbotState = () => {
    const value = useContext(HelpbotState);
    if (!value)
        throw new Error('cannot get state manager as not been provided');
    return value;
};
const getInitialState = () => {
    const storedState = getDataFromSessionStorage(HELPBOT_SESSION_STORAGE_KEY);
    // @ts-ignore
    if (storedState)
        return storedState;
    return [HELPBOT_ROOT_NODE_ID];
};
