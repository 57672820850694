import { Field } from '@klarna/bubble-ui';
import { selectors as configSelectors } from 'mage-config';
import { hooks as i18nHooks } from 'mage-i18n';
import insights, { TrackImpression } from 'mage-insights';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { OriginValidationError, validateOrigin } from '../utils';
export default function OriginInputField({ error, setError, newOrigin, setNewOrigin, isDisabled = false }) {
    const environment = useSelector(configSelectors.environment);
    const isProduction = environment === 'production';
    const protocol = isProduction ? 'https://' : '';
    const t = i18nHooks.useTranslator();
    if (newOrigin === '') {
        setNewOrigin(protocol);
    }
    const validateOriginOnBlur = useCallback(() => {
        setError({ error: false, validation: false, errorMessage: '' });
        const { isValid, error } = validateOrigin(newOrigin, !isProduction);
        if (!isValid) {
            const errorMessage = error === OriginValidationError.INVALID_PROTOCOL
                ? t('credentials.originInputField.invalidProtocolError')
                : t('credentials.originInputField.validationError');
            setError({ error: true, validation: true, errorMessage });
            insights.event({
                category: error === OriginValidationError.INVALID_PROTOCOL
                    ? 'origin-input-field/error/protocol'
                    : 'origin-input-field/error/domain',
                action: 'error'
            });
        }
    }, [setError, newOrigin, isProduction]);
    const handleOriginOnChange = useCallback((inputValue) => {
        setNewOrigin(protocol + inputValue.substring(protocol.length));
    }, [setNewOrigin]);
    const impressionEvent = {
        category: 'origin-input-field',
        action: 'impression'
    };
    return (React.createElement(TrackImpression, { event: impressionEvent },
        React.createElement(Field, { label: error.error && error.validation ? error.errorMessage : t('credentials.originInputField.label'), onChange: handleOriginOnChange, onBlur: validateOriginOnBlur, value: newOrigin, error: error.error && error.validation, "data-testid": 'inputFieldOrigin', disabled: isDisabled })));
}
