import { IllustrationErrorUFO, SpacerVertical } from '@klarna/bubble-ui'
import { Layout } from '@klarna/mp-ui'
import { CenteredContent } from 'mage-components'
import { hooks as coreHooks } from 'mage-core'
import { hooks as i18nHooks } from 'mage-i18n'
import { selectors as stateSelectors } from 'mage-state'
import * as R from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'

import AppCategory from './AppCategory'

function AppCategories () {
  const t = i18nHooks.useTranslator()

  const tokenHelper = coreHooks.useTokenHelper()

  const categories = useSelector(stateSelectors.getAppCategories)

  const getCategorizedApps = useSelector(stateSelectors.getCategorizedApps)
  const apps = getCategorizedApps(tokenHelper)

  const noAppsAvailable = R.isEmpty(R.flatten(Object.values(apps)))

  return (
    <div id='apps' style={{ width: '100%' }}>
      {noAppsAvailable ? (
        <Layout.Section>
          <Layout.Column>
            <CenteredContent
              illustration={<IllustrationErrorUFO />}
              title={t('home-fe.no-apps.title')}
              summary={t('home-fe.no-apps.subtitle')}
            />
          </Layout.Column>
        </Layout.Section>
      ) : categories.map((category, i) => {
        const { id, title } = category
        const appsInCategory = R.pathOr([], [title], apps)
        const hasAppsInCategory = (appsInCategory.length) > 0
        const isNKOCategory = R.all(R.pathEq(true, ['config', 'isNkoApp']))(appsInCategory)

        return hasAppsInCategory &&
          <div key={id}>
            <AppCategory
              id={id}
              title={title}
              apps={appsInCategory}
              isNko={isNKOCategory}
            />
            {i < categories.length - 1 && <SpacerVertical spaceToken='space.300' />}
          </div>
      })}
    </div>
  )
}

export default AppCategories
