const init = {
    apps: {},
    common: {
        environment: {},
        keycloak: {},
        terms: {},
        insights: {},
        assets: {},
        sentry: {}
    }
};
export default (state = init) => state;
