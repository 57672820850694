export const getMustDisplayBoostApp = ({ selectedMid, clientId, hasKeys, availableApps, permissions, appId }) => {
    const areCredentialsReady = !!selectedMid && !!clientId && hasKeys;
    return areCredentialsReady
        && isAppEnabled(availableApps, appId)
        && hasAnyAccess(permissions, appId);
};
const isAppEnabled = (apps, appId) => apps.find(app => app.clientId === appId) !== undefined;
const hasAnyAccess = (permissions, appId) => {
    if (!permissions || !permissions[appId])
        return false;
    return permissions[appId].length > 0;
};
