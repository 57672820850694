
import * as c from '../constants'

export const getUserProperties = ({ markets, email, language }) => ({
  market: getLocale(markets),
  email,
  language
})

const getLocale = (markets) => markets.length === 1 ? c.LOCALES[markets[0]] : null
