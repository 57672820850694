import { ColoredIllustrationProblem, ColoredIllustrationSecurity, ColoredIllustrationSuccess } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
import React from 'react';
export const IllustrationTypes = PropTypes.oneOf(['problem', 'success', 'security']);
Illustration.propTypes = {
    type: IllustrationTypes
};
function Illustration({ type }) {
    switch (type) {
        case 'problem':
            return React.createElement(ColoredIllustrationProblem, null);
        case 'success':
            return React.createElement(ColoredIllustrationSuccess, null);
        case 'security':
            return React.createElement(ColoredIllustrationSecurity, null);
    }
}
export default Illustration;
