import { Link } from '@klarna/bubble-ui';
import { Error } from '@merchant-portal/framework';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import React from 'react';
const ErrorComponent = ({ errorId }) => {
    const t = i18nHooks.useTranslator();
    const errorDescription = errorId ? t('core.errorTemplates.default.errorDescription', { errorId }) : '';
    const contactContent = t('core.errorTemplates.default.contact', {
        errorId: React.createElement("strong", null, errorId),
        supportLink: children => (React.createElement(Link, { textToken: 'text-style/text/paragraphs/body/medium', isUnderlined: true, href: 'https://www.klarna.com/merchant-support', target: '_blank' }, children))
    }, 
    /* Fallback if translation is not found */
    'Please try refreshing the page');
    return (React.createElement(Error, { title: t('core.errorTemplates.default.title', {}, 'Unexpected Error'), description: errorDescription, contactContent: [contactContent], reloadButton: {
            label: t('core.errorTemplates.reloadButton', {}, 'Reload')
        } }));
};
ErrorComponent.propTypes = {
    errorId: PropTypes.string.isRequired
};
export default ErrorComponent;
