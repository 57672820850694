import { DesignTokens, IconError } from '@klarna/bubble-ui';
import { useGetToken } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { COLORS } from '../constants';
TextArea.propTypes = {
    testId: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    errorLabel: PropTypes.string
};
const { Salmiak, Pumice, Ming, Flint, Cardinal, Scarlet, Ballet } = COLORS;
const LINE_HEIGHT = 25;
export default function TextArea({ testId, value, onChange, onBlur, placeholder, errorLabel }) {
    const [isFocused, setIsFocused] = useState(false);
    const contentEditableRef = useRef();
    const cornerRadiusS = useGetToken('corner-radius/s').value();
    const designContext = DesignTokens.useDesignContext();
    const font = designContext.get('text-style/text/paragraphs/body/regular');
    useEffect(() => {
        if (contentEditableRef.current) {
            contentEditableRef.current.innerText = value;
        }
    }, [contentEditableRef.current]);
    return (React.createElement("div", { style: {
            borderRadius: cornerRadiusS,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: toRGB(borderColor(isFocused, errorLabel)),
            textRendering: 'geometricprecision',
            WebkitFontSmoothing: 'antialiased',
            lineHeight: `${LINE_HEIGHT}px`,
            padding: 15,
            backgroundColor: errorLabel ? toRGB(Ballet) : 'transparent'
        } },
        React.createElement("div", { style: {
                fontFamily: font.fontFamily,
                fontWeight: font.fontWeight,
                position: 'relative'
            } },
            React.createElement("div", { ref: contentEditableRef, "data-testid": testId, style: {
                    minHeight: LINE_HEIGHT * 2,
                    maxHeight: LINE_HEIGHT * 6,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    outline: 'none',
                    whiteSpace: 'pre-wrap',
                    caretColor: toRGB(Ming),
                    color: toRGB(Salmiak)
                }, onInput: event => onChange(event.target.innerText), onFocus: () => setIsFocused(true), onBlur: () => {
                    setIsFocused(false);
                    onBlur();
                }, contentEditable: true }),
            React.createElement("div", { style: {
                    pointerEvents: 'none',
                    display: !isFocused && (!value || value.trim() === '') ? 'block' : 'none',
                    position: 'absolute',
                    top: 0,
                    color: errorLabel ? toRGB(Scarlet) : toRGB(Flint)
                } }, placeholder),
            errorLabel && (React.createElement("div", { style: { color: toRGB(Cardinal), display: 'flex', alignItems: 'center' } },
                React.createElement("div", { style: { marginRight: 9, display: 'flex', alignItems: 'center' } },
                    React.createElement(IconError, { color: 'red' })),
                React.createElement("div", null, errorLabel))))));
}
// eslint-disable-next-line @typescript-eslint/naming-convention
function toRGB(color) {
    return `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.alpha})`;
}
function borderColor(isFocused, errorLabel) {
    if (isFocused) {
        return Salmiak;
    }
    else if (errorLabel) {
        return Scarlet;
    }
    else {
        return Pumice;
    }
}
