import { useResponsiveContext } from '@klarna/mp-ui';
import insights from 'mage-insights';
import { useCallback, useState } from 'react';
var BannerLocalStorageState;
(function (BannerLocalStorageState) {
    BannerLocalStorageState["SHOWN"] = "shown";
    BannerLocalStorageState["HIDDEN"] = "hidden";
})(BannerLocalStorageState || (BannerLocalStorageState = {}));
const LOCAL_STORAGE_KEY = 'boostHomePromotionBannerState';
function getBannerStateFromLocalStorage() {
    const bannerState = localStorage.getItem(LOCAL_STORAGE_KEY);
    // if the banner state is not set, we should show the banner
    return bannerState === BannerLocalStorageState.SHOWN || bannerState === null;
}
function setBannerStateToLocalStorage(state) {
    localStorage.setItem(LOCAL_STORAGE_KEY, state);
}
export default function usePromotionBanner() {
    const [isShown, setIsShown] = useState(getBannerStateFromLocalStorage());
    const { isDesktop, isXLDesktop } = useResponsiveContext();
    const shouldShowDesktopView = isDesktop || isXLDesktop;
    const handleClose = useCallback(() => {
        insights.event({
            category: 'v3/boost-home/promotion-banner/closed',
            action: 'click'
        });
        setIsShown(false);
        setBannerStateToLocalStorage(BannerLocalStorageState.HIDDEN);
    }, []);
    return {
        handleClose,
        isShown,
        shouldShowDesktopView
    };
}
