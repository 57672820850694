import { AlertBlockError } from '@klarna/bubble-ui'
import { useFeature } from '@merchant-portal/experimentation'
import { constants as commonConstants } from 'mage-common'
import { selectors as configSelectors } from 'mage-config'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'

const ImportantBanner = ({ environment, envs, regions, goTo, linkHref, linkLabel, region, shouldDisplay, content, title }) => {
  const bannerExperiment = useFeature('merchant-portal.important-banner')
  if (bannerExperiment) {
    title = bannerExperiment.title
    content = bannerExperiment.content
    linkLabel = bannerExperiment.linkLabel
    linkHref = bannerExperiment.linkHref
  }

  const BannerComponent = (
    <AlertBlockError
      title={title}
      content={[content]}
      linkLabel={linkLabel}
      onLinkClick={() => {
        goTo(linkHref)
      }}
    />
  )

  if (bannerExperiment) {
    return BannerComponent
  } else if (shouldDisplay) {
    const hasFilters = R.or(!R.isEmpty(envs), !R.isEmpty(regions))
    const matchesEnv = R.or(R.isEmpty(envs), R.includes(environment, envs))
    const matchesRegion = R.or(R.isEmpty(regions), R.includes(region, regions))
    const matchesFilters = R.and(matchesEnv, matchesRegion)
    if (!hasFilters || (hasFilters && matchesFilters)) {
      return BannerComponent
    }
  }
  return null
}

ImportantBanner.propTypes = {
  content: PropTypes.string.isRequired,
  envs: PropTypes.arrayOf(PropTypes.oneOf(Object.values(commonConstants.ENVS))),
  environment: PropTypes.oneOf(Object.values(commonConstants.ENVS)),
  goTo: PropTypes.func,
  linkHref: PropTypes.string,
  linkLabel: PropTypes.string,
  region: PropTypes.oneOf(Object.values(commonConstants.REGIONS)),
  regions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(commonConstants.REGIONS))),
  shouldDisplay: PropTypes.bool,
  title: PropTypes.string
}

ImportantBanner.defaultProps = {
  envs: [],
  regions: [],
  goTo: newHref => {
    window.open(newHref, '_blank')
  },
  shouldDisplay: false
}

export default R.compose(
  connect(
    state => ({
      environment: configSelectors.environment(state),
      region: configSelectors.region(state)
    }))
)(ImportantBanner)

export { ImportantBanner }
