import { hooks as i18nHooks } from 'mage-i18n';
import React, { createContext, useContext } from 'react';
export const errorContext = createContext(undefined);
export const ErrorProvider = ({ children }) => {
    const t = i18nHooks.useTranslator();
    const [errorId, setErrorId] = React.useState(undefined);
    const [sentryErrorId, setSentryErrorId] = React.useState(undefined);
    const [errorMessage, setErrorMessage] = React.useState(undefined);
    const setErrorToTranslate = (translationKey, errorId, sentryErrorId) => {
        setSentryErrorId(sentryErrorId);
        setErrorId(errorId);
        setErrorMessage(t(translationKey));
    };
    const resetError = () => {
        setErrorMessage(undefined);
        setSentryErrorId(undefined);
        setErrorId(undefined);
    };
    const accessController = {
        errorId,
        sentryErrorId,
        errorMessage,
        setErrorToTranslate,
        resetError
    };
    return (React.createElement(errorContext.Provider, { value: accessController }, children));
};
export const useError = () => {
    const value = useContext(errorContext);
    if (!value)
        throw new Error('cannot get ErrorContext as not been provided');
    return value;
};
