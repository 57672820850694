import DateRangeField from '@bubble-contrib/molecule-field-date-range';
import { addMonths, format, subMonths } from 'date-fns';
import { hooks as i18nHooks, selectors as i18nSelectors } from 'mage-i18n';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createMonthsOfTheYear, createWeekDayOneLetterNames } from '../dateConstants';
const TODAY = new Date();
const CURRENT_YEAR = format(TODAY, 'yyyy');
function DateRangePicker({ id, label, presets, onChange, isClearable = true, range, disableDatesHandler }) {
    const t = i18nHooks.useTranslator();
    const dateFormatter = useSelector(i18nSelectors.formatters.date);
    const [open, setOpen] = useState(false);
    const [currentDate, setCurrentDate] = useState(TODAY);
    const onToggle = () => setOpen(open => !open);
    const onGoToNextMonth = () => {
        const nextMonth = addMonths(currentDate, 1);
        setCurrentDate(nextMonth);
    };
    const onGoToPreviousMonth = () => {
        const previousMonth = subMonths(currentDate, 1);
        setCurrentDate(previousMonth);
    };
    return (React.createElement(DateRangeField, { id: id, monthNames: createMonthsOfTheYear(t), weekDayOneLetterNames: createWeekDayOneLetterNames(t), currentYear: CURRENT_YEAR, monthCurrentlyShown: format(currentDate, 'yyyy-MM'), onGoToPreviousMonth: onGoToPreviousMonth, onGoToNextMonth: onGoToNextMonth, onChange: onChange, disableDatesHandler: disableDatesHandler, onToggle: onToggle, formatter: (value) => dateFormatter.format(new Date(value)), isClearable: isClearable, isExpanded: open, startDateLabel: t('core.dateRangePicker.startLabel'), endDateLabel: t('core.dateRangePicker.endLabel'), label: label, presets: presets, range: range }));
}
DateRangePicker.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isClearable: PropTypes.bool,
    range: PropTypes.array.isRequired,
    presets: PropTypes.array,
    disableDatesHandler: PropTypes.func
};
export default DateRangePicker;
