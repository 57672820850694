import { useEffect } from 'react';
import { isFreschatWidgetReady, restoreConversationIfAnyActive } from 'mage-conversation';
import { useChatContext } from './useChatContext';
const CHAT_INIT_INTERVAL = 100;
const RESTORE_MAX_TIME = 15 * 1000;
export const useRestoreConversation = () => {
    const context = useChatContext();
    useEffect(() => {
        const intervalProcess = setInterval(() => {
            if (!isFreschatWidgetReady())
                return;
            safeClearInterval();
            restoreConversationIfAnyActive(context);
        }, CHAT_INIT_INTERVAL);
        const safeClearInterval = () => {
            if (intervalProcess) {
                clearInterval(intervalProcess);
            }
        };
        setTimeout(safeClearInterval, RESTORE_MAX_TIME);
        return safeClearInterval;
    }, []);
};
