import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import FormikStore from 'formik-store'
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core'
import { hooks as i18nHooks, selectors as i18nSelectors } from 'mage-i18n'
import {
  SpacerVertical,
  TitlePrimary,
  AlertBannerError,
  Typography,
  Link
} from '@klarna/bubble-ui'
import useSupportCenterFetch from '../../hooks/useSupportCenterFetch'
import handleError from '../../handleError'
import validationSchema from './validationSchema'
import Fields from './Fields'

const TOO_MANY_REQUESTS_STATUS_CODE = 429
const SESSION_STORAGE_KEY = 'create-ticket-form'

CreateTicketPage.propTypes = {
  setTicketId: PropTypes.func.isRequired,
  setRegion: PropTypes.func.isRequired
}

export default function CreateTicketPage ({ setTicketId, setRegion }) {
  const t = i18nHooks.useTranslator()
  const language = useSelector(i18nSelectors.language)
  const supportCenterFetch = useSupportCenterFetch()
  const tokenHelper = coreHooks.useTokenHelper()
  const getMerchantRegion = useSelector(coreSelectors.getMerchantRegion)
  const formRef = useRef(null)
  const [showTempUnavailableError, setShowTempUnavailableError] = useState(false)
  const [showGenericError, setShowGenericError] = useState(false)

  const mids = tokenHelper.getMids()

  const initialValues = {
    mid: mids.length === 1 ? mids[0] : '',
    phoneNumber: '',
    topic: '',
    subject: '',
    description: ''
  }

  const contactLink = (
    <Link
      textToken='text-style/text/paragraphs/body/regular'
      isUnderlined
      data-testid='create-ticket-page-contact-link'
      href='https://www.klarna.com/merchant-support'
      target='_blank'
    >
      {t('help-tool.support-page.create-ticket.contact-link')}
    </Link>
  )

  return (
    <div data-testid='create-ticket-page'>
      {showTempUnavailableError && (
        <div data-testid='temp-unavailable-error'>
          <AlertBannerError
            content={t('help-tool.support-page.create-ticket.service-unavailable-error', {
              link: contactLink
            })}
          />
          <SpacerVertical small />
        </div>
      )}
      {showGenericError && (
        <div data-testid='generic-error'>
          <AlertBannerError
            content={t('help-tool.support-page.create-ticket.generic-error', {
              link: contactLink
            })}
          />
          <SpacerVertical small />
        </div>
      )}
      <TitlePrimary>{t('help-tool.support-page.create-ticket.title')}</TitlePrimary>
      <Typography textToken='text-style/text/paragraphs/body/regular'>{t('help-tool.support-page.create-ticket.instructions')}</Typography>
      <SpacerVertical small />
      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        <Form ref={formRef}>
          <Fields />
          {window.sessionStorage && (
            <FormikStore name={SESSION_STORAGE_KEY} storage={window.sessionStorage} />
          )}
        </Form>
      </Formik>
    </div>
  )

  async function onSubmit (values, actions) {
    try {
      const form = formRef.current
      const data = new FormData(form)

      const region = getMerchantRegion(values.mid)

      setShowTempUnavailableError(false)
      setShowGenericError(false)

      data.append('referer', window.location.toString())
      data.append('description', values.description)
      data.append('language', language)

      const response = await supportCenterFetch('/v1/tickets', { method: 'POST', data, region })

      if (window.sessionStorage) {
        window.sessionStorage.removeItem(SESSION_STORAGE_KEY)
      }

      setTicketId(response.data.id)
      setRegion(region?.toLowerCase())
    } catch (error) {
      actions.setSubmitting(false)

      if (error.response?.status === TOO_MANY_REQUESTS_STATUS_CODE) {
        setShowTempUnavailableError(true)
      } else {
        setShowGenericError(true)
      }
      handleError('msx', error)
    }
  }
}
