import { useGetToken } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Column } from './Column';
import { GridContainer } from './Container';
import { Row } from './Row';
import { withResponsive } from './utils/withResponsive';
const styles = {
    absoluteContainer: {
        height: '100vh',
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 9000
    },
    relativeContainer: {
        position: 'relative'
    },
    breakpointContainer: {
        left: 10,
        position: 'absolute',
        top: 10,
        zIndex: 9001
    },
    breakpointPill: borderRadius => ({
        borderRadius: `${borderRadius}px`,
        fontSize: '20px',
        justifyContent: 'center',
        minHeight: '30px',
        minWidth: '0px',
        padding: '5px 10px'
    }),
    breakpointColor: {
        mobile: {
            backgroundColor: '#ffb3c7'
        },
        tablet: {
            backgroundColor: '#daeebc'
        },
        desktop: {
            backgroundColor: '#bacaff'
        }
    },
    column: {
        backgroundColor: 'rgba(241, 222, 207, 0.4)',
        color: 'rgba(241, 222, 207, 0)',
        height: '100vh'
    }
};
const GridOverlayHelperContainer = ({ breakpoint, children, debug = false }) => {
    const cornerRadiusS = useGetToken('corner-radius/s').value();
    return debug ? (React.createElement(React.Fragment, null,
        React.createElement("div", { style: styles.absoluteContainer },
            React.createElement("div", { style: styles.relativeContainer },
                React.createElement("div", { style: styles.breakpointContainer },
                    React.createElement("span", { style: Object.assign(Object.assign({}, styles.breakpointPill(cornerRadiusS)), styles.breakpointColor[breakpoint]) }, breakpoint)),
                React.createElement(GridContainer, null,
                    React.createElement(Row, null,
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")),
                        React.createElement(Column, { columns: { mobile: 1 } },
                            React.createElement("div", { style: styles.column }, "Column")))))),
        React.createElement(React.Fragment, null, children))) : (React.createElement(React.Fragment, null, children));
};
GridOverlayHelperContainer.propTypes = {
    breakpoint: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    debug: PropTypes.bool
};
export const GridOverlayHelper = withResponsive(GridOverlayHelperContainer);
