import { node } from 'prop-types';
import React from 'react';
import useCMSRequest from '../hooks/useCMSRequest';
import CMSContext from './CMSContext';
CMSContextProvider.propTypes = {
    children: node
};
function CMSContextProvider({ children }) {
    const contentTypesResponse = useCMSRequest('/content_types', null);
    return React.createElement(CMSContext.Provider, { value: { contentTypesResponse } }, children);
}
export default CMSContextProvider;
