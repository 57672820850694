import { useResponsiveContext } from '@klarna/mp-ui';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
export const SidebarContext = createContext({
    items: [],
    isOpen: false
});
const mergeSidebarConfig = (config, updatedConfig) => {
    return Object.assign(Object.assign(Object.assign({}, config), updatedConfig), { items: config === null || config === void 0 ? void 0 : config.items.map((item) => {
            const updatedConfigItem = updatedConfig === null || updatedConfig === void 0 ? void 0 : updatedConfig.items.find((updatedItem) => item.id === updatedItem.id);
            return Object.assign(Object.assign({}, item), (updatedConfigItem || {}));
        }) });
};
export const SidebarProvider = ({ config, children }) => {
    var _a;
    const { isDesktop } = useResponsiveContext();
    const [isOpen, setSidebarOpen] = useState((_a = isDesktop !== null && isDesktop !== void 0 ? isDesktop : config === null || config === void 0 ? void 0 : config.isOpen) !== null && _a !== void 0 ? _a : false);
    const [sidebarConfig, setSidebarConfig] = useState(config);
    useEffect(() => {
        if (isDesktop !== undefined) {
            setSidebarOpen(isDesktop);
        }
    }, [isDesktop]);
    useEffect(() => {
        setSidebarConfig((prevConfig) => mergeSidebarConfig(prevConfig, config));
    }, [config, mergeSidebarConfig]);
    const value = useMemo(() => (Object.assign(Object.assign({}, sidebarConfig), { isOpen,
        setSidebarOpen,
        setSidebarConfig })), [isOpen, setSidebarOpen, sidebarConfig, setSidebarConfig]);
    return (React.createElement(SidebarContext.Provider, { value: value }, children));
};
export const useSidebarContext = () => React.useContext(SidebarContext);
export const useSetSubmenu = () => {
    const { setSidebarConfig, items } = useSidebarContext();
    const setSubmenu = useCallback((parentId, submenuItems) => {
        const index = items.findIndex((item) => item.id === parentId);
        if (index >= 0) {
            const menuItems = [...items];
            menuItems[index] = Object.assign(Object.assign({}, items[index]), { subMenu: {
                    parentId,
                    items: submenuItems.map((item) => (Object.assign(Object.assign({}, item), { isSubmenuItem: true })))
                } });
            setSidebarConfig((prev) => (Object.assign(Object.assign({}, prev), { items: menuItems })));
        }
    }, [setSidebarConfig]);
    return setSubmenu;
};
export const useToggleSidebarHandler = () => {
    const { setSidebarOpen } = React.useContext(SidebarContext);
    return useCallback(() => {
        setSidebarOpen((prev) => !prev);
    }, [setSidebarOpen]);
};
const sanitizePath = (url = '') => {
    return url
        .replace(/#.+$/, '') // remove hash part
        .replace(/\?.+$/, '') // remove query string
        .replace(/^\/+/, '') // remove beginning slashes
        .replace(/\/+$/, '') // remove trailing slashes
        .trim();
};
const getLocation = () => { var _a; return ((_a = window === null || window === void 0 ? void 0 : window.location) !== null && _a !== void 0 ? _a : {}); };
export const isRootLinkActive = (path) => {
    const location = getLocation();
    const sanitizedLocationPath = sanitizePath(location.pathname);
    const sanitizedPath = sanitizePath(path);
    if (sanitizedPath === '') {
        return sanitizedLocationPath === sanitizedPath;
    }
    return sanitizedLocationPath.startsWith(sanitizedPath);
};
export const isPartOfCurrentPath = (path) => {
    const location = getLocation();
    const sanitizedLocationPath = sanitizePath(location.pathname);
    const sanitizedPath = sanitizePath(path);
    return new Set(sanitizedLocationPath.split('/')).has(sanitizedPath);
};
// Allows storybook to get props
/* istanbul ignore next */
export const StorybookSidebarContextProps = () => null;
