import { createActions } from 'redux-actions';
const actions = createActions({
    CLIENT: {
        CAPABILITIES: {
            LOADING: undefined,
            SET: undefined
        }
    }
}).client;
export default actions;
