import { SpacerVertical, Typography } from '@klarna/bubble-ui'
import { Message } from 'mage-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import Apps from './Apps'
import NkoApps from './NkoApps'

function AppCategory ({ id, title, apps, isNko = false }) {
  return (
    <>
      <Typography textToken='text-style/headings/blocks/bold/primary'>
        <Message path={`core.appCategory.${id}`} fallback={title} />
      </Typography>
      <SpacerVertical spaceToken='space.300' />
      {isNko ? <NkoApps apps={apps} /> : <Apps apps={apps} />}
    </>
  )
}

AppCategory.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  apps: PropTypes.arrayOf(PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    newApp: PropTypes.bool
  })).isRequired,
  isNko: PropTypes.bool
}

export default AppCategory
