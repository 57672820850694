const actions = {
  SET_SIDEBAR_OPEN: 'SET_SIDEBAR_OPEN',
  SET_SIDEBAR_OPENING: 'SET_SIDEBAR_OPENING',
  NAVIGATE_PUSH: 'NAVIGATE_PUSH',
  NAVIGATE_POP: 'NAVIGATE_POP',
  NAVIGATE_REPLACE: 'NAVIGATE_REPLACE',
  NAVIGATE_RESET: 'NAVIGATE_RESET',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_IS_SEARCHING: 'SET_IS_SEARCHING',
  RESET_HELPTOOL: 'RESET_HELPTOOL',
  RESET: 'RESET',
  OPEN_WIDGET: 'OPEN_WIDGET',
  CLOSE_WIDGET: 'CLOSE_WIDGET'
}

export default actions
