import { MultiSelector, Selector } from '@klarna/mp-ui';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import { useSelector } from 'react-redux';
import { mapMidsToStoreSelectorOptions } from './utils';
const isMultiSelectStoreSelector = (props) => {
    return props.isMultiselect;
};
const StoreSelector = (props) => {
    const { borderless, children, closeText, disabled, disabledMids = [], fullWidthTrigger, id, label, loading, maxHeight, maxWidth, mids = [], onChange = () => null, onClose = () => null, pendingMids = [] } = props;
    const t = i18nHooks.useTranslator();
    const merchants = useSelector(coreSelectors.getMerchants);
    const options = mapMidsToStoreSelectorOptions(mids, merchants, { disabledMids, pendingMids });
    return (React.createElement(React.Fragment, null, isMultiSelectStoreSelector(props)
        ? (React.createElement(MultiSelector, { borderless: borderless, closeLabel: closeText || t('core.storesSelector.closeText'), closeWithSelectionLabel: props.closeTextWithSelection || t('core.storesSelector.closeTextWithSelection'), deselectAllOptionLabel: props.deselectAllOptionText || t('core.storesSelector.deselectAllOptionText'), disabled: disabled, emptyStateLabel: props.emptyStateText || t('core.storesSelector.emptyStateText'), isFullWidthTrigger: fullWidthTrigger, id: id, label: label || t('core.storesSelector.label'), loading: loading, maxHeight: maxHeight, maxWidth: maxWidth, 
            // @ts-expect-error: FIXME - types in mp-ui
            onChange: onChange, onClose: onClose, options: options, searchLabel: props.searchFieldLabel || t('core.storesSelector.searchFieldLabel'), selectAllOptionLabel: props.selectAllOptionText || t('core.storesSelector.selectAllOptionText'), selectedAllLabel: props.selectedAllText || t('core.storesSelector.selectedAllText'), selectedLabel: props.selectedText || t('core.storesSelector.selectedText'), values: props.values || [], withSearch: mids.length > 4, withSelectAll: mids.length > 1 }, children))
        : (React.createElement(Selector, { borderless: borderless, closeLabel: closeText || t('core.storeSelector.closeText'), disabled: disabled, isFullWidthTrigger: fullWidthTrigger, id: id, label: label || t('core.storeSelector.label'), loading: loading, maxHeight: maxHeight, maxWidth: maxWidth, 
            // @ts-expect-error: FIXME - types in mp-ui
            onChange: onChange, onClose: onClose, options: options, value: typeof props.value === 'undefined' ? '' : props.value }, children))));
};
export default StoreSelector;
