import { ActionIconChevronRight, Typography } from '@klarna/bubble-ui';
import React from 'react';
import styled from 'styled-components';
const ContainerStyled = styled.div `
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  :hover {
    background-color: #F5F5F5;
    cursor: pointer;
  }
`;
const HeaderStyled = styled.div `
  display: 'flex',
  gap: '16px',
  width: '100%',
  alignItems: 'center'
`;
const ModalButton = ({ onClick, children, label }) => {
    return (React.createElement(ContainerStyled, { onClick: onClick },
        React.createElement("div", null,
            React.createElement(HeaderStyled, null,
                children,
                React.createElement("div", null,
                    React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/bold' }, label)))),
        React.createElement(ActionIconChevronRight, null)));
};
export default ModalButton;
