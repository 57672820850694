import { __awaiter } from "tslib";
export default function AmazonConnectWidget(config, callbacks) {
    (function (w, d, x, id) {
        const s = d.createElement('script');
        s.src = 'https://d1ddlw7p7j6hta.cloudfront.net/amazon-connect-chat-interface-client.js';
        // @ts-ignore
        s.async = 1;
        s.id = id;
        d.getElementsByTagName('head')[0].appendChild(s);
        w[x] = w[x] || function () { (w[x].ac = w[x].ac || []).push(arguments); };
    })(window, document, 'amazon_connect', config.widgetId);
    window.amazon_connect('styles', { iconType: 'CHAT', openChat: { color: '#ffffff', backgroundColor: '#123456' }, closeChat: { color: '#ffffff', backgroundColor: '#123456' } });
    window.amazon_connect('snippetId', config.snippet);
    window.amazon_connect('supportedMessagingContentTypes', ['text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response']);
    window.amazon_connect('authenticate', function (callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield callbacks.onAuthRequested();
            callback(token);
        });
    });
    window.amazon_connect('customLaunchBehavior', { skipIconButtonAndAutoLaunch: true, alwaysHideWidgetButton: true });
    window.amazon_connect('registerCallback', {
        CHAT_ENDED: callbacks.onChatEnded
    });
    callbacks.onWidgetOpen();
}
