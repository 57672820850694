import React from 'react'
import PropTypes from 'prop-types'
import { SpacerVertical, Typography } from '@klarna/bubble-ui'

export function returnFileSize (number) {
  switch (true) {
    case number < 1024:
      return number + ' bytes'
    case number >= 1024 && number < 1048576:
      return (number / 1024).toFixed(1) + 'KB'
    case number >= 1048576:
      return (number / 1048576).toFixed(1) + 'MB'
    default:
      return null
  }
}

FilesPreview.propTypes = {
  files: PropTypes.array
}

export default function FilesPreview ({ files }) {
  return (
    <>
      <SpacerVertical small />
      {files.map((file, index) => {
        const fileInfo = (
          <Typography textToken='text-style/text/paragraphs/default/regular'>{`${file.name}, ${returnFileSize(file.size)}`}</Typography>
        )

        return (
          <div key={index} data-testid='file-item'>
            {file.type?.includes('image/') && (
              <a href={URL.createObjectURL(file)} target='_blank' rel='noreferrer'>
                <img
                  src={URL.createObjectURL(file)}
                  style={{ maxHeight: '300px', maxWidth: '-webkit-fill-available' }}
                />
              </a>
            )}
            {fileInfo}
          </div>
        )
      })}
    </>
  )
}
