import { handleActions } from 'redux-actions';
export const cmsConstants = {
    NAME: 'cms',
    SET_CMS_ENVIRONMENT: 'SET_CMS_ENVIRONMENT'
};
function getCmsEnvironment() {
    try {
        return sessionStorage.getItem(cmsConstants.CMS_ENVIRONMENT_SESSION_STORAGE_KEY);
    }
    catch (error) {
        return null;
    }
}
const initialState = {
    preview: window.location.search.includes('preview=true'),
    cmsEnvironment: getCmsEnvironment()
};
export const cmsReducer = handleActions({
    [cmsConstants.SET_CMS_ENVIRONMENT]: (state, { payload }) => (Object.assign(Object.assign({}, state), { cmsEnvironment: payload }))
}, initialState);
