import React from 'react'
import PropTypes from 'prop-types'
import { ButtonPrimary, IconAgent } from '@klarna/bubble-ui'
import { useResponsiveContext } from '@klarna/mp-ui'
import { hooks } from 'mage-i18n'
import { useHelpContext } from '../../hooks'
import { useRestoreConversation } from '../../hooks/useRestoreConversation'
import { getButtonStyles } from './styles'

export default function HelpbotButton ({ container }) {
  const SUPPORT_HELPBOT_NAV_PAGE = 'supportHelpbot'
  const ROUTES_TO_HIDE_BUTTON = ['supportHelpbot', 'support', 'supportChannelSelector']
  const { navigation } = useHelpContext()

  useRestoreConversation()

  const t = hooks.useTranslator()
  const { isXLDesktop } = useResponsiveContext()

  function onClick () {
    navigation.push(SUPPORT_HELPBOT_NAV_PAGE)
  }

  if (ROUTES_TO_HIDE_BUTTON.includes(navigation.activePage.path)) {
    return null
  }

  return (
    <div
      style={getButtonStyles({
        isXLDesktop
      })}
    >
      <ButtonPrimary
        data-browser-test-id='uhqm-chat-button'
        icon={IconAgent}
        size='medium'
        onClick={() => onClick()}
      >
        {t('helpbot.button.label')}
      </ButtonPrimary>
    </div>
  )
}

HelpbotButton.propTypes = {
  container: PropTypes.instanceOf(Element)
}
