import { ButtonQuaternary } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React, { useState } from 'react';
function CopyToClipboardButton({ contentToCopy, label: labelProp, labelOnCopy: labelOnCopyProp }) {
    const t = i18nHooks.useTranslator();
    const [copied, setCopied] = useState(false);
    const label = labelProp || t('credentials.copyToClipboardButton.label');
    const labelOnCopy = labelOnCopyProp || t('credentials.copyToClipboardButton.labelOnCopy');
    const copyToClipboard = () => {
        setCopied(true);
        navigator.clipboard.writeText(contentToCopy);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };
    return (React.createElement(ButtonQuaternary, { size: 'small', onClick: copyToClipboard }, copied ? labelOnCopy : label));
}
export default CopyToClipboardButton;
