import { actions as componentsActions } from 'mage-components'

const setDialogShown = userId => dispatch => {
  const data = {
    has_accepted_nko_terms: true
  }
  return dispatch(componentsActions.updateRequester(userId, data))
}

export {
  setDialogShown
}
