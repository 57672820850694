import { ButtonPrimary } from '@klarna/bubble-ui';
import { completeAuthentication } from 'mage-common';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
const ButtonContainer = styled.div `
  display: inline-flex;
`;
const LoginRequired = () => {
    const t = i18nHooks.useTranslator();
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonContainer, null,
            React.createElement(ButtonPrimary, { onClick: () => completeAuthentication(), size: 'medium' }, t('core.stepUpAuthentication.login.continueButton')))));
};
export default LoginRequired;
