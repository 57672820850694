import React from 'react';
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage';
import { BotNodeId } from '../../../types';
import { INeedMoreHelpThatHelped } from '../../common';
export const entrypoint = {
    value: 'helpbot.orders.reservations.title',
    next: BotNodeId.ORDER_RESERVATIONS
};
export const flow = [
    {
        id: BotNodeId.ORDER_RESERVATIONS,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.orders.orderManagement.extendExpiryReservation.question',
                next: BotNodeId.ORDER_MANAGEMENT_EXTEND_EXPIRY_RESERVATION
            },
            {
                value: 'helpbot.orders.reservations.whyUnableToExtendReservation.question',
                next: BotNodeId.ORDER_RESERVATIONS_UNABLE_TO_EXTEND_TIME
            }
        ]
    },
    {
        id: BotNodeId.ORDER_RESERVATIONS_UNABLE_TO_EXTEND_TIME,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.orders.reservations.whyUnableToExtendReservation.answer' })),
        options: () => INeedMoreHelpThatHelped
    }
];
