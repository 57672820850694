import { createAction } from 'redux-actions';
import { cmsConstants } from '../reducers/mage-cms';
const internalSetCmsEnvironment = createAction(cmsConstants.SET_CMS_ENVIRONMENT);
const setCmsEnvironment = environment => {
    sessionStorage.setItem(cmsConstants.CMS_ENVIRONMENT_SESSION_STORAGE_KEY, environment);
    return dispatch => dispatch(internalSetCmsEnvironment(environment));
};
export default {
    setCMSEnvironment: setCmsEnvironment
};
