/* eslint-disable no-undef */
import React, { createContext, useContext, useState } from 'react';
import { useChannelDataState } from './ChannelDataStateProvider';
export const ChannelSelectorContext = createContext(undefined);
export const ChannelSelectorProvider = ({ children }) => {
    const { data: { markets } } = useChannelDataState();
    const initialState = new Map(markets.map(m => ([m.countryCode, false])));
    const [isChatLoadingByMarket, setIsChatLoadingByMarket] = useState(initialState);
    const getIsChatLoading = (market) => isChatLoadingByMarket.get(market) || false;
    const setChatIsLoading = (market) => {
        isChatLoadingByMarket.set(market, true);
        setIsChatLoadingByMarket(new Map(isChatLoadingByMarket));
    };
    const resetIsChatLoading = () => {
        setIsChatLoadingByMarket(new Map(initialState));
    };
    const stateManager = {
        getIsChatLoading,
        setChatIsLoading,
        resetIsChatLoading
    };
    return (React.createElement(ChannelSelectorContext.Provider, { value: stateManager }, children));
};
export const useChannelSelectorState = () => {
    const value = useContext(ChannelSelectorContext);
    if (!value)
        throw new Error('cannot get ChannelSelectorProvider state manager as not been provided');
    return value;
};
