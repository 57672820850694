import { utils as clientUtils } from 'mage-client'

export const NAME = 'home-fe'

export const CAPS = clientUtils.desiredCapabilities({
  UPDATE_USER: 'user/update'
})

// Copied from https://stash.int.klarna.net/projects/META/repos/mp-ui/browse/src/constants/index.ts#1
export const BREAKPOINT_PX = {
  DESKTOP: 1024,
  TABLET: 730
}

export const B2B_COLORS = {
  Vanilla: '#FFFAB0',
  Mint: '#B8FFCC',
  Pool: '#B3F9FF ',
  Lavender: '#E9D6FF ',
  Sky: '#BDD5FF ',
  Peach: '#FFD7AD'
}
