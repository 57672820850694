import { __awaiter } from "tslib";
export const memoizeAsync = (fn, cacheTime) => {
    let memoizedValue;
    return (...args) => __awaiter(void 0, void 0, void 0, function* () {
        if (!(memoizedValue === null || memoizedValue === void 0 ? void 0 : memoizedValue.value) || new Date().getTime() - (memoizedValue === null || memoizedValue === void 0 ? void 0 : memoizedValue.lastCalled.getTime()) > cacheTime) {
            const value = yield fn(...args);
            memoizedValue = {
                lastCalled: new Date(),
                value
            };
        }
        return memoizedValue.value;
    });
};
export const memoize = (fn, cacheTime) => {
    let memoizedValue;
    return (...args) => {
        if (!(memoizedValue === null || memoizedValue === void 0 ? void 0 : memoizedValue.value) || new Date().getTime() - (memoizedValue === null || memoizedValue === void 0 ? void 0 : memoizedValue.lastCalled.getTime()) > cacheTime) {
            const value = fn(...args);
            memoizedValue = {
                lastCalled: new Date(),
                value
            };
        }
        return memoizedValue.value;
    };
};
