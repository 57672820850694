import insights from 'mage-insights';
import { useApiKey } from '../../hooks/useApiKey';
import { useMid } from '../../hooks/useMid';
export default function useGenerateButton() {
    const { areActionsEnabled, createApiKey } = useApiKey();
    const { selectedMid } = useMid();
    const event = {
        category: 'v1/boost-home/generate-api-key',
        action: 'impression',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    function handleUnlockedClick() {
        insights.event(Object.assign(Object.assign({}, event), { category: event.category + '/unlocked', action: 'click' }));
        createApiKey();
    }
    function handleLockedClick() {
        insights.event(Object.assign(Object.assign({}, event), { category: event.category + '/locked', action: 'click' }));
    }
    return {
        event,
        handleLockedClick,
        handleUnlockedClick,
        areActionsEnabled
    };
}
