export default ({ storage = window.localStorage } = {}) => {
    function isAvailable() {
        const testKey = `test${Date.now()}`;
        try {
            storage.setItem(testKey, 'test');
            storage.getItem(testKey);
            storage.removeItem(testKey);
            return true;
        }
        catch (err) {
            return false;
        }
    }
    const get = (key, defaultValue = null) => {
        var _a;
        try {
            const value = JSON.parse((_a = storage.getItem(key)) !== null && _a !== void 0 ? _a : 'null');
            return value !== null ? value : defaultValue;
        }
        catch (err) {
            return defaultValue;
        }
    };
    const set = (key, value) => {
        try {
            return storage.setItem(key, JSON.stringify(value));
        }
        catch (err) { /* empty */ }
    };
    const remove = key => {
        try {
            return storage.removeItem(key);
        }
        catch (err) { /* empty */ }
    };
    const clear = () => {
        try {
            storage.clear();
        }
        catch (err) { /* empty */ }
    };
    return {
        isAvailable,
        get,
        set,
        remove,
        clear
    };
};
