export const getImagePath = (imageName) => {
    let imagePath;
    try {
        imagePath = require(`../images/${imageName}`);
    }
    catch (e) {
        imagePath = require('../images/placeholder.png');
    }
    return imagePath;
};
