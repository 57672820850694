import { useEffect, useRef } from 'react'

const observerConfig = { attributes: true, childList: true, subtree: true }

export default function useElementDetector (
  selectors,
  onDetectionChange,
  timeout = null,
  onDetectionEnd = () => {}
) {
  const rootNodeRef = useRef()
  const foundRef = useRef(false)

  if (!rootNodeRef.current) {
    rootNodeRef.current = document.getElementById('root')
  }

  useEffect(() => {
    checkIfElementIsPresent()

    const observer = new MutationObserver(checkIfElementIsPresent)

    observer.observe(rootNodeRef.current, observerConfig)

    const handler = timeout
      ? setTimeout(() => {
        observer.disconnect()
        onDetectionEnd(foundRef.current)
      }, timeout)
      : null

    return () => {
      observer.disconnect()
      clearTimeout(handler)
    }
  }, [JSON.stringify(selectors)])

  function checkIfElementIsPresent () {
    if (!selectors) return false

    const found = selectors.filter(Boolean).some(selector => document.querySelector(selector))

    if (found !== foundRef.current) {
      foundRef.current = found
      onDetectionChange(found)
    }
  }
}
