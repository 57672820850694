import { Typography } from '@klarna/bubble-ui';
import React from 'react';
import styled from 'styled-components';
export const NotificationTitle = ({ children }) => (React.createElement(React.Fragment, null,
    React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/bold' }, children)));
export const CenteredContent = styled.div `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
