import { selectors as coreSelectors } from 'mage-core';
import { selectors as i18nSelectors } from 'mage-i18n';
import * as R from 'ramda';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions';
import handleError from '../handleError';
import * as selectors from '../selectors';
const useFetchFeatures = ({ errorLogger } = {}) => {
    const client = useSelector(coreSelectors.createMerchantPortalApiClient);
    const isAuthenticated = useSelector(coreSelectors.isAuthenticated);
    const app = useSelector(coreSelectors.clientId);
    const country = useSelector(i18nSelectors.country);
    const language = useSelector(i18nSelectors.language);
    const sessionId = useSelector(coreSelectors.sessionId);
    const features = useSelector(selectors.features);
    const dispatch = useDispatch();
    const setFeatures = R.pipe(actions.setFeatures, dispatch);
    const setPrivateFeaturesLoaded = () => dispatch(actions.setPrivateFeaturesLoaded(true));
    const fetchFeatures = () => {
        if (isAuthenticated) {
            client
                .request('/features', {
                params: {
                    app,
                    country,
                    language,
                    sessionId
                }
            })
                .then(R.pathOr({}, ['data', 'features']))
                .then(setFeatures)
                .catch(error => {
                if (!features) {
                    setFeatures({});
                }
                handleError(error, { errorLogger });
            })
                .finally(setPrivateFeaturesLoaded);
        }
    };
    useEffect(() => {
        fetchFeatures();
    }, [isAuthenticated, app, country, language, sessionId]);
};
export default useFetchFeatures;
