import merge from 'deepmerge';
import { utils as commonUtils } from 'mage-common';
import { INSIGHTS_EVENT } from './constants';
const translateKeys = (obj, ...translators) => {
    const translator = merge.all(translators);
    return commonUtils.renameKeys(translator, obj);
};
/*
Usually called with
additionalProps = {
  label: 'xyz',
  value: 123
}
However this is just convention and is optional
*/
const createInsightsMetaObject = (category, action, additionalProps = {}) => ({
    [INSIGHTS_EVENT]: Object.assign({ category,
        action }, additionalProps)
});
export { createInsightsMetaObject, translateKeys };
