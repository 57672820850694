import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@klarna/bubble-ui'
import { useHelpContext } from '../hooks'

HelpArticleLink.propTypes = {
  children: PropTypes.node.isRequired,
  slug: PropTypes.string.isRequired
}

function HelpArticleLink ({ children, slug, ...props }) {
  const { navigation } = useHelpContext()

  function handleClick (e) {
    e.preventDefault()
    e.stopPropagation()
    navigation.push('article', { articleSlug: slug })
  }

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Link textToken='text-style/text/paragraphs/body/medium' isUnderlined onPress={handleClick} {...props}>
      {children}
    </Link>
  )
}

export default HelpArticleLink
