export const storeDataIntoSession = (key, data) => {
    try {
        checkStorageKey(key);
        sessionStorage.setItem(key, JSON.stringify(data));
    }
    catch (e) {
        // @ts-ignore: narrow type of e
        console.log('cannot store data into storage session: ', e.message);
    }
};
export const getDataFromSessionStorage = (key) => {
    try {
        const storedData = sessionStorage.getItem(key);
        if (!storedData)
            return null;
        return JSON.parse(storedData);
    }
    catch (e) {
        // @ts-ignore: narrow type of e
        console.log('cannot get data from storage session: ', e.message);
        return null;
    }
};
export const cleanDataIntoSession = (key) => {
    try {
        checkStorageKey(key);
        sessionStorage.setItem(key, '');
    }
    catch (e) {
        // @ts-ignore: narrow type of e
        console.log('cannot clean data into storage session: ', e.message);
    }
};
const checkStorageKey = (key) => {
    if (key.length === 0) {
        throw new Error('storage key cannot be an empty string');
    }
};
