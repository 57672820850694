import React from 'react';
import { ChatBubble } from '@klarna/bubble-ui';
const styles = {
    chatBubble: {
        marginBottom: '10px'
    },
    chatAuthor: {
        margin: '0px',
        marginBottom: '5px',
        fontFamily: 'Klarna Text',
        fontStyle: 'normal',
        color: '#787573',
        fontSize: '12px',
        fontWeight: 400
    }
};
const HelpbotQuestion = ({ Message }) => {
    return (React.createElement("div", { style: styles.chatBubble },
        React.createElement("p", { style: styles.chatAuthor }, "Klarna"),
        React.createElement(ChatBubble, { direction: 'left', color: 'gray', maxWidth: 270 },
            React.createElement(Message, null))));
};
export { HelpbotQuestion };
