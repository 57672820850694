import insights from 'mage-insights';
import { useClientId } from '../../hooks/useClientId';
import { useMid } from '../../hooks/useMid';
export default function useGenerateButton() {
    const { areActionsEnabled, openAddOriginModal } = useClientId();
    const { selectedMid } = useMid();
    const event = {
        category: 'v1/boost-home/generate-client-id',
        action: 'impression',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    function handleUnlockedClick() {
        insights.event(Object.assign(Object.assign({}, event), { category: event.category + '/unlocked', action: 'click' }));
        openAddOriginModal();
    }
    function handleLockedClick() {
        insights.event(Object.assign(Object.assign({}, event), { category: event.category + '/locked', action: 'click' }));
    }
    return {
        event,
        handleLockedClick,
        handleUnlockedClick,
        areActionsEnabled
    };
}
