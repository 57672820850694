import { Typography } from '@klarna/bubble-ui';
import { Themes, useUIThemeContext } from '@klarna/mp-ui';
import React from 'react';
export function ThemeSelector() {
    const { theme, setTheme } = useUIThemeContext();
    const options = Object.values(Themes).filter(value => value !== Themes.LiquoriceDark);
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, null, "Theme"),
        React.createElement("select", { style: { height: 30 }, value: theme, onChange: e => setTheme(e.target.value) }, options.map(option => (React.createElement("option", { key: option, value: option }, option))))));
}
