import { __awaiter } from "tslib";
import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Modal } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import insights from 'mage-insights';
import React, { useCallback, useEffect, useState } from 'react';
import OriginInputField from '../OriginInputField';
import useOrigins from '../useOrigins';
export default function AddOriginModal({ mid, region, onSuccess, isVisible, setIsVisible }) {
    const t = i18nHooks.useTranslator();
    const [isLoading, setIsLoading] = useState(false);
    const [newOrigin, setNewOrigin] = useState('');
    const [error, setError] = useState({ error: false, validation: false, errorMessage: '' });
    const { addOrigin } = useOrigins({ mid, region });
    const insightsEventsCategory = 'add-origin-user-flow/landing-page';
    useEffect(() => {
        isVisible && insights.event({
            category: insightsEventsCategory,
            action: 'impression',
            label: 'add-origin-modal'
        });
    }, [isVisible]);
    const setErrorHandler = (error) => {
        setError(error);
        if (error.error) {
            insights.event({
                category: insightsEventsCategory,
                action: 'impression',
                label: 'add-origin-modal-error-banner'
            });
        }
    };
    function resetState() {
        setIsLoading(false);
        setNewOrigin('');
        setErrorHandler({ error: false, validation: false, errorMessage: '' });
    }
    const onCancel = useCallback(() => {
        resetState();
        insights.event({
            category: insightsEventsCategory,
            action: 'click',
            label: 'add-origin-modal-cancel-button'
        });
        setIsVisible(false);
    }, []);
    function onSubmit() {
        return __awaiter(this, void 0, void 0, function* () {
            setIsLoading(true);
            insights.event({
                category: insightsEventsCategory,
                action: 'click',
                label: 'add-origin-modal-submit-button'
            });
            try {
                yield addOrigin(newOrigin);
                onSuccess();
            }
            catch (err) {
                setErrorHandler({ error: true, validation: false, errorMessage: t('credentials.addOriginModal.errorMessage') });
            }
            setIsLoading(false);
        });
    }
    if (!isVisible) {
        return null;
    }
    return (React.createElement(Modal, { onClose: onCancel, buttonPrimary: {
            text: t('credentials.addOriginModal.buttonPrimaryText'),
            loading: isLoading,
            onClick: onSubmit,
            disabled: !!(error.error || newOrigin === ''),
            'data-testid': 'buttonSubmit'
        }, buttonSecondary: {
            text: t('credentials.addOriginModal.buttonSecondaryText'),
            onClick: onCancel,
            'data-testid': 'buttonCancel'
        }, generalError: error.error && !error.validation ? error.errorMessage : '' },
        React.createElement("div", null,
            React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/tertiary' }, t('credentials.addOriginModal.title')),
            React.createElement(SpacerVertical, { small: true }),
            React.createElement(Typography, { textToken: 'text-style/text/super-paragraphs/body/regular' }, t('credentials.addOriginModal.description')),
            React.createElement(SpacerVertical, { spaceToken: 'space.300' }),
            React.createElement(OriginInputField, { error: error, setError: setErrorHandler, newOrigin: newOrigin, setNewOrigin: setNewOrigin }))));
}
