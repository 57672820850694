import useAnalytics from '../hooks/useAnalytics';
export default function Analytics({ category, action, label, children, skip = false, debounce }) {
    const track = useAnalytics({
        category,
        action,
        label,
        trackOnMount: !children,
        skip,
        debounce
    });
    // @ts-ignore: children is always a function
    return children ? children(track) : null;
}
