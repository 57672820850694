import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core'
import * as R from 'ramda'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createAction, handleActions } from 'redux-actions'

import createRequests from './requests'

const INIT = 'data-provider/INIT'
const SUCCESS = 'data-provider/SUCCESS'
const FAILURE = 'data-provider/FAILURE'

const initRequest = createAction(INIT)
const widgetSuccess = createAction(SUCCESS)
const widgetFailure = createAction(FAILURE)

const dataProviderReducer = handleActions({
  [INIT]: state => ({
    ...state,
    isLoading: true,
    isError: false
  }),
  [SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    isError: false,
    data: payload
  }),
  [FAILURE]: state => ({
    ...state,
    isLoading: false,
    isError: true
  })
}, {
  isLoading: false,
  isError: false
})

export default function DataProvider ({ includeOrders, children, onLoading = () => null, onError = () => null }) {
  const createBackendClient = useSelector(coreSelectors.createBackendClient)
  const ordersClient = createBackendClient('orders')
  const notifierClient = createBackendClient('notifier')
  const tokenHelper = coreHooks.useTokenHelper()

  const [state, dispatch] = React.useReducer(dataProviderReducer, { isLoading: true })
  const { data, isLoading, isError } = state

  useEffect(() => {
    const fetchData = async () => {
      dispatch(initRequest())
      try {
        const requests = createRequests({ notifier: notifierClient, orders: ordersClient }, tokenHelper.getUserId(), tokenHelper.getRegion(), { includeOrders })
        const result = await Promise.all(requests)
        const payload = R.flatten(result)

        dispatch(widgetSuccess(payload))
      } catch (error) {
        dispatch(widgetFailure())
      }
    }
    fetchData()
  }, [])

  if (isLoading) {
    return onLoading()
  } else if (isError) {
    return onError()
  } else return children(data)
}
