import { __rest } from "tslib";
import { ButtonPrimary, IconPadlock } from '@klarna/bubble-ui';
import React from 'react';
import useMfaLockedButton from './useMfaLockedButton';
const MfaLockedButton = (_a) => {
    var { onLockedClick, onUnlockedClick, children } = _a, props = __rest(_a, ["onLockedClick", "onUnlockedClick", "children"]);
    const { isMfaLocked, handleClick } = useMfaLockedButton({
        onLockedClick,
        onUnlockedClick
    });
    return (React.createElement(ButtonPrimary, Object.assign({}, props, { size: props.size || 'medium', onClick: handleClick, icon: isMfaLocked ? IconPadlock : undefined }), children));
};
export default MfaLockedButton;
