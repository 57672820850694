import { SpacerVertical } from '@klarna/bubble-ui';
import { ClientKeyField } from '@merchant-portal/credentials';
import { hooks as i18nHooks } from 'mage-i18n';
import { TrackImpression } from 'mage-insights';
import React from 'react';
import TrackableLink from '../../../../../../components/TrackableLink';
import { useClientId } from '../../hooks/useClientId';
import { useMid } from '../../hooks/useMid';
export default function ManageClientId() {
    const { clientIdToken } = useClientId();
    const { selectedMid } = useMid();
    const t = i18nHooks.useTranslator();
    const event = {
        category: 'v3/boost-home/authenticate-and-connect/manage-client-id',
        action: 'impression',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    return (React.createElement(TrackImpression, { event: event },
        React.createElement(ClientKeyField, { clientKey: clientIdToken }),
        React.createElement(SpacerVertical, { spaceToken: 'space.200' }),
        React.createElement(TrackableLink, { href: '/settings/client-identifier', category: 'v3/boost-home/authenticate-and-connect/manage-client-id', colorToken: 'colors/text/default' }, t('home-fe.boosters-home-v03.client-identifier-section.manage-link'))));
}
