import { useFeature } from '@merchant-portal/experimentation'
import React, { useReducer, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { pipe, last, path, pick } from 'ramda'
import HelpContext from './HelpContext'
import reducer from './HelpContextReducer'
import actions from './HelpContextActions'
import { getInitialState, persistentStateKeys } from './HelpContextState'
import {
  UHQM_SIDEBAR_ANIMATION_DURATION,
  UHQM_SESSION_STORAGE_KEY
} from '../constants'

HelpContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  sidebarAnimationDuration: PropTypes.number
}

export const getActivePage = pipe(path(['navigationStack']), last)

const handleBeforeUnload = state => () => {
  const persistentState = pick(persistentStateKeys, state)
  window.sessionStorage.setItem(UHQM_SESSION_STORAGE_KEY, JSON.stringify(persistentState))
}

export default function HelpContextProvider ({
  children,
  location = window.location,
  history = window.history,
  sidebarAnimationDuration = UHQM_SIDEBAR_ANIMATION_DURATION
}) {
  const [state, dispatch] = useReducer(reducer, getInitialState(sidebarAnimationDuration, true))
  const isChatSelectorInChatEnabled = useFeature('poxe-market-selector-inside-chat')

  const setIsOpen = isOpen => dispatch({ type: actions.SET_SIDEBAR_OPEN, isOpen: !!isOpen })
  const setIsOpening = isOpening =>
    dispatch({ type: actions.SET_SIDEBAR_OPENING, isOpening: !!isOpening })
  const close = () => dispatch({ type: actions.SET_SIDEBAR_OPEN, isOpen: false })
  const setSearchQuery = useCallback(query => dispatch({ type: actions.SET_SEARCH_QUERY, query }), [actions.SET_SEARCH_QUERY])
  const setIsSearching = isSearching => dispatch({ type: actions.SET_IS_SEARCHING, isSearching })
  const resetHelptool = () => dispatch({ type: actions.RESET_HELPTOOL })
  const reset = () => dispatch({ type: actions.RESET })
  const openWidget = () => dispatch({ type: actions.OPEN_WIDGET })
  const closeWidget = () => dispatch({ type: actions.CLOSE_WIDGET })
  const toggleWidget = () => {
    if (!state.isWidgetOpen) {
      openWidget()
    } else {
      closeWidget()
    }
  }

  const navigation = {
    push: (path, parameters) => dispatch({ type: actions.NAVIGATE_PUSH, path, parameters }),
    pop: () => dispatch({ type: actions.NAVIGATE_POP }),
    replace: (path, parameters) => dispatch({ type: actions.NAVIGATE_REPLACE, path, parameters }),
    reset: () => dispatch({ type: actions.NAVIGATE_RESET }),
    activePage: getActivePage(state),
    stacksize: state.navigationStack.length,
    openHelpPage: openHelpPage
  }

  useEffect(() => {
    const handler = handleBeforeUnload(state)
    window.addEventListener('beforeunload', handler)
    return () => window.removeEventListener('beforeunload', handler)
  }, [state])

  useEffect(() => {
    if (state.sidebar.isOpening) {
      const handler = setTimeout(() => {
        setIsOpening(false)
      }, sidebarAnimationDuration)

      return () => {
        setIsOpening(false)
        clearTimeout(handler)
      }
    }
  }, [state.sidebar.isOpening])

  function openHelpPage (helpPage) {
    const [page, slug] = helpPage.split(':')

    switch (page) {
      case 'home':
        navigation.reset()
        setIsOpen(true)
        break
      case 'article':
        navigation.push('article', { articleSlug: slug })
        break
      case 'topic':
        navigation.push('topic', { topicSlug: slug })
        break
      case 'tour':
        navigation.push('tour', { tourSlug: slug })
        break
    }
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search)

    const helpPage = search.get('help-page')

    if (helpPage) {
      openHelpPage(helpPage)

      search.delete('help-page')

      const separator = search.toString().length > 0 ? '?' : ''

      history.replaceState({}, document.title, `${window.location.pathname}${separator}${search}`)
    }
  }, [])

  return (
    <HelpContext.Provider
      value={{
        isOpen: state.sidebar.isOpen,
        topics: state.topics,
        sidebar: state.sidebar,
        close,
        setIsOpen,
        navigation,
        searchQuery: navigation.activePage.searchQuery,
        setSearchQuery,
        isSearching: navigation.activePage.isSearching,
        setIsSearching,
        resetHelptool,
        reset,
        isChatSelectorInChatEnabled,
        isWidgetOpen: state.isWidgetOpen,
        openWidget,
        closeWidget,
        toggleWidget
      }}
    >
      {children}
    </HelpContext.Provider>
  )
}
