import { __awaiter } from "tslib";
import { requestWrapper } from '../api/requestWrapper';
import { RequestMethodEnum, RegionEnum } from '../components/SupportHelpBot/types';
import { DAY_MS, HOUR_MS, MINUTE_MS } from '../constants';
import { memoizeAsync } from '../utilities/memoize';
import { getTransformedRestoreId, getTransformedWorkingHours } from './chat';
import { getCountryCodesAndRegions, getCountryCodes, getCountryCodesAndFeatureData, mergeAndGetMarkets } from './markets';
const getMarketsResponse = memoizeAsync((client) => __awaiter(void 0, void 0, void 0, function* () {
    return requestWrapper(client, RequestMethodEnum.GET, 'v1/markets');
}), HOUR_MS);
const getChatExpFeature = memoizeAsync((client, markets) => __awaiter(void 0, void 0, void 0, function* () {
    return requestWrapper(client, RequestMethodEnum.GET, 'v1/chat/exp/feature', { markets }, [RegionEnum.EU]);
}), MINUTE_MS * 5);
const getWorkingHoursResponse = memoizeAsync((client) => __awaiter(void 0, void 0, void 0, function* () {
    return requestWrapper(client, RequestMethodEnum.GET, 'v1/chat/working-hours');
}), DAY_MS);
const getRestoreIdResponse = memoizeAsync((client) => __awaiter(void 0, void 0, void 0, function* () {
    return requestWrapper(client, RequestMethodEnum.GET, 'v1/chat/restore-id');
}), DAY_MS);
export const getMarkets = (client) => __awaiter(void 0, void 0, void 0, function* () {
    const marketsResponse = yield getMarketsResponse(client);
    const countryCodesAndRegions = getCountryCodesAndRegions(marketsResponse);
    const countryCodes = getCountryCodes(countryCodesAndRegions);
    const chatExpFeatures = yield getChatExpFeature(client, countryCodes);
    const countryCodesAndFeatureData = getCountryCodesAndFeatureData(chatExpFeatures);
    return mergeAndGetMarkets(countryCodesAndRegions, countryCodesAndFeatureData);
});
export const getRestoreId = (client) => __awaiter(void 0, void 0, void 0, function* () {
    const restoreIdResponse = yield getRestoreIdResponse(client);
    return getTransformedRestoreId(restoreIdResponse);
});
export const getWorkingHours = (client) => __awaiter(void 0, void 0, void 0, function* () {
    const workingHoursResponse = yield getWorkingHoursResponse(client);
    return getTransformedWorkingHours(workingHoursResponse);
});
