import { useChannelDataState } from '../context/ChannelDataStateProvider';
import { isSupportOpen } from '../utilities/workingHours';
const useIsChatActive = () => {
    const { data: { markets, workingHours } } = useChannelDataState();
    const getIsChatActive = (marketCountryCode) => {
        if (!isChatFeatureEnabled(marketCountryCode, markets))
            return false;
        const marketWorkingHours = workingHours.find(wh => wh.marketCountryCodes.includes(marketCountryCode));
        if (!marketWorkingHours)
            return false;
        return isSupportOpen(marketWorkingHours);
    };
    const getWorkingHoursRegion = (marketCountryCode) => { var _a; return (_a = workingHours.find(wh => wh.marketCountryCodes.includes(marketCountryCode))) === null || _a === void 0 ? void 0 : _a.region; };
    return { getIsChatActive, getWorkingHoursRegion };
};
const isChatFeatureEnabled = (marketCountryCode, markets) => {
    const foundFeature = markets.find(m => m.countryCode === marketCountryCode);
    if (!foundFeature)
        return false;
    return foundFeature.isChatFeatureEnabled;
};
export default useIsChatActive;
