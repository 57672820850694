import React from 'react';
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage';
import { BotNodeId } from '../../../types';
import { INeedMoreHelpThatHelped } from '../../common';
const disputesHandling = [
    {
        id: BotNodeId.DISPUTES_HANDLING,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.disputes.disputesHandling.whatIsDispute.question',
                next: BotNodeId.DISPUTES_HANDLING_WHAT_IS_DISPUTE
            },
            {
                value: 'helpbot.disputes.disputesHandling.howManyTimesDefend.question',
                next: BotNodeId.DISPUTES_HANDLING_HOW_MANY_TIMES_DEFEND
            },
            {
                value: 'helpbot.disputes.disputesHandling.doIReceiveNotification.question',
                next: BotNodeId.DISPUTES_HANDLING_DO_I_RECEIVE_NOTIFICATION
            },
            {
                value: 'helpbot.disputes.disputesHandling.howToAvoidDisputesFees.question',
                next: BotNodeId.DISPUTES_HANDLING_HOW_TO_AVOID_DISPUTES_FEES
            }
        ]
    },
    {
        id: BotNodeId.DISPUTES_HANDLING_WHAT_IS_DISPUTE,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesHandling.whatIsDispute.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_HANDLING_HOW_MANY_TIMES_DEFEND,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesHandling.howManyTimesDefend.answer' }),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_HANDLING_DO_I_RECEIVE_NOTIFICATION,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesHandling.doIReceiveNotification.answer' }),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_HANDLING_HOW_TO_AVOID_DISPUTES_FEES,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesHandling.howToAvoidDisputesFees.answer' }),
        options: () => INeedMoreHelpThatHelped
    }
];
export default disputesHandling;
