import { __rest } from "tslib";
import { useGetToken } from '@klarna/mp-ui';
import styled, { css, keyframes } from 'styled-components';
const setFadeAnimation = ({ fadeOut, durationMs }) => {
    const translateFromY = fadeOut ? '0' : '100%';
    const translateToY = fadeOut ? '100%' : '0px';
    const animation = keyframes `
    0% {
      opacity: ${fadeOut ? 1 : 0};
      transform: translate3d(0, ${translateFromY}, 0);
    }
    100% {
      opacity: ${fadeOut ? 0 : 1};
      transform: translate3d(0, ${translateToY}, 0);
    }
  `;
    return css `
    animation: ${animation};
    animation-duration: ${durationMs}ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  `;
};
export const NotificationsContainer = styled.div(() => {
    const bottomLeftPosition = useGetToken('space.400').value();
    return css `
    left: auto;
    display: flex;
    position: fixed;
    bottom: ${bottomLeftPosition}px;
    margin-left: ${bottomLeftPosition}px;
    z-index: 110;
    overflow: hidden;
    max-width: 500px;
    flex-direction: column;
    gap: 10px;
  `;
});
export const NotificationsContainerMobile = styled(NotificationsContainer)(() => {
    const rightLeftPosition = useGetToken('space.300').value();
    return css `
      left: ${rightLeftPosition}px;
      right: ${rightLeftPosition}px;
      max-width: 100%;
      margin-left: 0px;
    `;
});
export const FadingDiv = styled.div((_a) => {
    var { hideOnClick } = _a, animProps = __rest(_a, ["hideOnClick"]);
    const animCSS = setFadeAnimation(animProps);
    return css `
      ${animCSS};
      flex: 1;
      height: auto;
      overflow: hidden;
      position: relative;
      pointer-events: auto;
      transition: all 0.6s ease-out;

      cursor: ${hideOnClick ? 'pointer' : 'default'};
    `;
});
