import { __awaiter } from "tslib";
import { storeChatDataSession, cleanChatDataSession } from './restoreActiveChat';
import { getFreschatWidgetOptions } from './getFreshchatWidgetOptions';
import { getUserProperties } from './user';
import { getFreshchatWidget, isFreschatWidgetReady } from './getFreshchatWidget';
import { incrConversationsMetric } from './metrics';
import { getDataFromSessionStorage } from './utils/sessionStorage';
import { CHAT_SECURE_SESSION_STORAGE_KEY } from './constants';
import { postUserInfo } from './postUserInfo';
let generateClient = () => ({ request: () => null });
export const updateClientGenerator = (g) => {
    generateClient = g;
};
export const startConversationUserInfo = (ctx) => __awaiter(void 0, void 0, void 0, function* () {
    const markets = [ctx.market];
    const email = ctx.tokenHelper.getEmail();
    const language = ctx.language;
    const { env, region } = ctx;
    if (!region)
        return;
    if (!isFreschatWidgetReady())
        return;
    const widgetOptions = getFreschatWidgetOptions(ctx);
    const userProperties = getUserProperties({ markets, email, language });
    initChatUser(userProperties);
    initChatWidget(widgetOptions);
    newUserHandling(ctx, widgetOptions);
    openChatWidget();
    storeChatDataSession({ widgetOptions, env });
    firstMessageHandling(ctx);
    widgetOpenedHandling(ctx);
    widgetClosedHandling(ctx);
});
export const restoreConversationIfAnyActive = (ctx) => __awaiter(void 0, void 0, void 0, function* () {
    const storedData = getDataFromSessionStorage(CHAT_SECURE_SESSION_STORAGE_KEY);
    if (storedData && storedData.market) {
        startConversationUserInfo(Object.assign(Object.assign({}, ctx), storedData));
    }
});
const initChatWidget = (widgetOptions) => getFreshchatWidget().init(widgetOptions);
const openChatWidget = () => getFreshchatWidget().open();
const initChatUser = (userProperties) => getFreshchatWidget().user.setProperties(userProperties);
const newUserHandling = (ctx, widgetOptions) => getFreshchatWidget().on('user:created', res => {
    const { onNewUser, region } = ctx;
    if (canCallNewUserCallback(res, widgetOptions)) {
        onNewUser(res.data.restoreId, region);
    }
});
const canCallNewUserCallback = (res, widgetOptions) => { var _a; return ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.restoreId) && !(widgetOptions.restoreId); };
const firstMessageHandling = (ctx) => {
    getFreshchatWidget().on('message:sent', () => {
        getUserAndPostTheirInfo(ctx);
        getFreshchatWidget().off('message:sent');
    });
};
const getUserAndPostTheirInfo = ({ region }) => {
    getFreshchatWidget().user.get(({ data, success }) => {
        if (!success)
            return;
        postUserInfo(generateClient('support-chat'), region, data.alias);
    });
};
const widgetOpenedHandling = ({ chatClient, env, region, market, onChatWidgetOpens }) => {
    getFreshchatWidget().on('widget:opened', () => {
        incrConversationsMetric({ chatClient, env, region, market });
        onChatWidgetOpens();
    });
};
const widgetClosedHandling = ({ onCloseChat }) => {
    getFreshchatWidget().on('widget:closed', () => {
        cleanChatDataSession();
        getFreshchatWidget().destroy();
        onCloseChat();
    });
};
