import { construct, path } from 'ramda';
export class CapabilityForObj {
    constructor(capability, resource) {
        this.capability = capability;
        this.resource = resource;
    }
    get KEY() {
        return this.capability.KEY;
    }
    get LOADING() {
        return this.capability.LOADING;
    }
    get CAPABILITY() {
        return this.capability.CAPABILITY;
    }
    get options() {
        return path(['capabilities', this.KEY], this.resource);
    }
    get valid() {
        return !!this.options;
    }
}
export default construct(CapabilityForObj);
