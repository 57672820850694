import { useApiKeys } from '@merchant-portal/credentials';
import { useEffect, useState } from 'react';
export const useHasActiveApiKeys = ({ mid }) => {
    const [hasAlreadyActiveKeys, setHasAlreadyActiveKeys] = useState(false);
    const { getApiKeys } = useApiKeys({ mid: mid !== null && mid !== void 0 ? mid : '', fetchingApiKeysInitially: false });
    useEffect(() => {
        if (!mid)
            return;
        let isCancelled = false;
        getApiKeys()
            .then(apiKeys => {
            if (isCancelled)
                return;
            setHasAlreadyActiveKeys(apiKeys.some(apiKey => apiKey.keyType === 'API' && apiKey.enabled === true));
        })
            .catch(() => {
            if (isCancelled)
                return;
            setHasAlreadyActiveKeys(false);
        });
        return () => {
            isCancelled = true;
        };
    }, [mid]);
    if (!mid) {
        return {
            hasAlreadyActiveKeys: false,
            setHasAlreadyActiveKeys
        };
    }
    return {
        hasAlreadyActiveKeys,
        setHasAlreadyActiveKeys
    };
};
