import * as R from 'ramda';
class Capability {
    constructor(key) {
        this.KEY = key;
        this.options = {};
        this.valid = true;
    }
    get LOADING() {
        return `capabilities/${this.KEY}/loading`;
    }
    get CAPABILITY() {
        return `capabilities/${this.KEY}`;
    }
    toString() {
        return this.CAPABILITY;
    }
}
const desiredCapabilities = R.map(R.construct(Capability));
export { Capability };
export default desiredCapabilities;
