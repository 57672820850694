import { createStorage } from 'mage-common';
import actions from './actions';
export default ({ sessionStorage: realSessionStorage = window.sessionStorage } = {}) => next => action => {
    const sessionStorage = createStorage({ storage: realSessionStorage });
    switch (action.type) {
        // @ts-ignore: redux-actions createActions returns incorrect type
        case actions.keycloak.realm.set.toString():
            sessionStorage.set('realm', action.payload);
            break;
        // @ts-ignore: redux-actions createActions returns incorrect type
        case actions.keycloak.realm.clear.toString():
            sessionStorage.remove('realm');
            break;
    }
    return next(action);
};
