import React from 'react';
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage';
import { BotNodeId } from '../../../types';
import { INeedMoreHelpThatHelped } from '../../common';
const merchantDisputesApp = [
    {
        id: BotNodeId.MERCHANT_DISPUTES_APP,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.disputes.disputeManagement',
                next: BotNodeId.DISPUTES_MANAGEMENT
            },
            {
                value: 'helpbot.disputes.contactInfoAndNotifications',
                next: BotNodeId.DISPUTES_CONTACT_INFO_NOTIFICATIONS
            }
        ]
    },
    {
        id: BotNodeId.DISPUTES_MANAGEMENT,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.disputes.merchantDisputesApp.respondToDispute.question',
                next: BotNodeId.DISPUTES_APP_RESPOND_TO_DISPUTE
            },
            {
                value: 'helpbot.disputes.merchantDisputesApp.overviewDisputesCustomer.question',
                next: BotNodeId.DISPUTES_APP_OVERVIEW_DISPUTES_CUSTOMER
            },
            {
                value: 'helpbot.disputes.merchantDisputesApp.overviewDisputesResponse.question',
                next: BotNodeId.DISPUTES_APP_OVERVIEW_DISPUTES_RESPONSE
            },
            {
                value: 'helpbot.disputes.merchantDisputesApp.improveDisputesPerformance.question',
                next: BotNodeId.DISPUTES_APP_IMPROVE_DISPUTES_PERFORMANCE
            },
            {
                value: 'helpbot.disputes.merchantDisputesApp.overviewDisputesPerformance.question',
                next: BotNodeId.DISPUTES_APP_OVERVIEW_DISPUTES_PERFORMANCE
            }
        ]
    },
    {
        id: BotNodeId.DISPUTES_CONTACT_INFO_NOTIFICATIONS,
        botMessage: null,
        options: () => [{
                value: 'helpbot.disputes.merchantDisputesApp.updateContactInformation.question',
                next: BotNodeId.DISPUTES_APP_UPDATE_CONTACT_INFORMATION
            },
            {
                value: 'helpbot.disputes.merchantDisputesApp.updateCustomerServiceOnKlarnaApp.question',
                next: BotNodeId.DISPUTES_APP_UPDATE_CUSTOMER_SERVICE_ON_KLARNA_APP
            }]
    },
    {
        id: BotNodeId.DISPUTES_APP_RESPOND_TO_DISPUTE,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.merchantDisputesApp.respondToDispute.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_APP_OVERVIEW_DISPUTES_CUSTOMER,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.merchantDisputesApp.overviewDisputesCustomer.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_APP_OVERVIEW_DISPUTES_RESPONSE,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.merchantDisputesApp.overviewDisputesResponse.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_APP_UPDATE_CONTACT_INFORMATION,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.merchantDisputesApp.updateContactInformation.answer' }),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_APP_UPDATE_CUSTOMER_SERVICE_ON_KLARNA_APP,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.merchantDisputesApp.updateCustomerServiceOnKlarnaApp.answer' }),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_APP_IMPROVE_DISPUTES_PERFORMANCE,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.merchantDisputesApp.improveDisputesPerformance.answer' }),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_APP_OVERVIEW_DISPUTES_PERFORMANCE,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.merchantDisputesApp.overviewDisputesPerformance.answer' }),
        options: () => INeedMoreHelpThatHelped
    }
];
export default merchantDisputesApp;
