import React from 'react';
import { Badge, Typography } from '@klarna/bubble-ui';
import styled, { keyframes } from 'styled-components';
const gradientAnimation = keyframes `
  0% {
    background-position: 20% 0%;
  }
  5% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
`;
const AnimatedBadge = styled(Badge) `
  background: linear-gradient(to bottom right, #ffb3c7 0%, #e26eef 131.3%);
  background-size: 400% 400%;
  border-radius: 10px;
  animation-name: ${gradientAnimation};
  animation-delay: 2500ms;
  animation-timing-function: ease-out;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transition: all 0.1s ease-out;
`;
const BadgeInnerContainer = styled.span `
  padding: 1px 2px;
`;
export const GradientBadge = ({ content }) => {
    return (React.createElement(AnimatedBadge, { backgroundColorToken: 'colors/backgrounds/transparent', textColorToken: 'colors/text/default' },
        React.createElement(BadgeInnerContainer, null,
            React.createElement(Typography, { textToken: 'text-style/text/labels/tiny/medium' }, content))));
};
