import React from 'react'

import { Loader } from '@klarna/bubble-ui'

const FallbackLoader = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: 100 }}>
      <Loader />
    </div>
  )
}

export default FallbackLoader
