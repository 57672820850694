import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import clientActions from '../actions/mage-client';
const defaultState = {
    capabilities: {
        hasInitialised: false,
        isFetching: false,
        error: undefined,
        value: undefined
    }
};
export const clientConstants = {
    NAME: 'client'
};
export const clientReducer = handleActions({
    [clientActions.capabilities.loading]: R.assocPath(['capabilities', 'isFetching'], true),
    [clientActions.capabilities.set]: (state, { error, payload }) => (Object.assign(Object.assign({}, state), { capabilities: {
            hasInitialised: true,
            isFetching: false,
            error: error ? payload : undefined,
            value: error ? {} : payload.data.capabilities
        } }))
}, defaultState);
