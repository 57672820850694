import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import insights from 'mage-insights';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useError } from './useError';
import { usePlugin } from './usePlugin';
export const MidContext = createContext(undefined);
export const MidProvider = ({ children }) => {
    var _a;
    const { resetError } = useError();
    // @ts-ignore - coreHooks is not typed
    const userMids = coreHooks.useTokenHelper().getMids();
    const merchants = useSelector(coreSelectors.getMerchants);
    const [selectedMid, setSelectedMid] = useState(userMids[0] || null);
    const { pluginId } = usePlugin();
    coreHooks.useCacheMidInSessionStorage(selectedMid, setSelectedMid);
    const handleMidChange = (mid) => {
        resetError();
        setSelectedMid(mid);
    };
    useEffect(() => {
        if (!selectedMid) {
            setSelectedMid(userMids[0] || null);
        }
    }, [userMids]);
    useEffect(() => {
        insights.event({
            category: `boost-home/plugin-guide/${pluginId}`,
            action: 'mid-selected',
            label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
        });
    }, [selectedMid]);
    const selectedStoreName = ((_a = merchants.find(m => m.merchantId === selectedMid)) === null || _a === void 0 ? void 0 : _a.storeName) || null;
    const midController = {
        userMids,
        selectedMid,
        selectedStoreName,
        handleMidChange
    };
    return (React.createElement(MidContext.Provider, { value: midController }, children));
};
export const useMid = () => {
    const value = useContext(MidContext);
    if (!value)
        throw new Error('cannot get MidContext as not been provided');
    return value;
};
