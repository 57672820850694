import * as Sentry from '@sentry/react';
export default function handleError(error, extras = {}) {
    // eslint-disable-next-line no-console
    console.error('mage-cms error caught:', error);
    if (extras.componentStack) {
        // eslint-disable-next-line no-console
        console.error('Component stack:', extras.componentStack);
    }
    const isExpectedError = error.request && [0, 400, 401, 403, 415].includes(error.request.status);
    if (!isExpectedError) {
        const mergedExtras = Object.assign({ request: error.request, response: error.response }, extras);
        let errorId;
        Sentry.withScope(scope => {
            scope.setTag('team', 'msx');
            scope.setExtras(mergedExtras);
            errorId = Sentry.captureException(error);
        });
        // eslint-disable-next-line no-console
        console.error('Error id:', errorId);
        return errorId;
    }
}
