import styled from 'styled-components';
import React from 'react';
import { ActionIconClose } from '@klarna/bubble-ui';
const StyledActionIconClose = styled(ActionIconClose) `
  position: relative;
  top: 2px;
  margin: 0 4px;
`;
function CustomActionClose() {
    return React.createElement(StyledActionIconClose, null);
}
export default CustomActionClose;
