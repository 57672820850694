import { BotNodeId, UxEvent } from '../../../types';
import * as orderProcessing from './orderProcessing';
import * as orderModification from './orderModificationAndIssues';
import * as refundIssues from './refundIssuesFlow';
import * as reservations from './reservationsFlow';
import * as paymentOrder from './paymentOrdersFlow';
const ordersFlow = [
    {
        id: BotNodeId.ORDERS,
        botMessage: null,
        options: () => [
            orderProcessing.entrypoint,
            orderModification.entrypoint,
            refundIssues.entrypoint,
            reservations.entrypoint,
            paymentOrder.entrypoint,
            {
                value: 'helpbot.options.otherIssue',
                next: UxEvent.GO_TO_CHANNEL_SELECTION
            }
        ]
    },
    ...orderProcessing.flow,
    ...orderModification.flow,
    ...refundIssues.flow,
    ...paymentOrder.flow,
    ...reservations.flow
];
export default ordersFlow;
