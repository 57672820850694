import * as R from 'ramda';
import { createSelector } from 'reselect';
import * as constants from './constants';
const getState = R.propOr({}, constants.NAME);
const capabilities = R.pipe(getState, R.prop('capabilities'));
const hasInitialised = R.pipe(capabilities, R.prop('hasInitialised'));
const isFetching = R.pipe(capabilities, R.prop('isFetching'));
const isCapabilitiesLoading = createSelector(hasInitialised, isFetching, (hasInitialised, isFetching) => !!(!hasInitialised || isFetching));
const getCapabilities = R.pipe(capabilities, R.prop('value'));
const getCapabilitiesError = R.pipe(capabilities, R.prop('error'));
export { getCapabilities, getCapabilitiesError, isCapabilitiesLoading };
