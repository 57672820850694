import { Typography } from '@klarna/bubble-ui';
import { useResponsiveContext } from '@klarna/mp-ui';
import { useCMSEnvironment } from 'mage-cms';
import { cmsActions } from 'mage-state';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
const options = [
    {
        value: '',
        hidden: true
    },
    {
        value: 'master'
    },
    {
        value: 'editor'
    },
    {
        value: 'dev'
    },
    {
        value: 'test'
    }
];
function CMSEnvironmentSelector() {
    const dispatch = useDispatch();
    const cmsEnvironment = useCMSEnvironment();
    const { isMobile } = useResponsiveContext();
    if (!cmsEnvironment)
        return null;
    const label = isMobile ? 'CMS' : 'CMS Environment';
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular' }, label),
        React.createElement(Select, { value: cmsEnvironment, options: options, onChange: onChange })));
    function onChange(value) {
        dispatch(cmsActions.setCMSEnvironment(value));
    }
}
export default CMSEnvironmentSelector;
Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};
function Select({ options, value, onChange }) {
    return (React.createElement("select", { id: 'cms-environment-selector', style: { height: 30 }, value: value, onChange: event => onChange(event.target.value) }, options.map(option => (React.createElement("option", { key: option.value, value: option.value, hidden: option.hidden }, option.value)))));
}
