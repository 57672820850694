import { __awaiter } from "tslib";
import { useEffect, useState } from 'react';
import { InviteChoices } from './Invite';
const emptyObject = {};
const mapToEntityId = (entityType, entities) => {
    return entities.filter(({ type }) => type === entityType)
        .map(({ id }) => id);
};
const useStores = (client, pendingEntities) => {
    const [isLoading, setIsLoading] = useState(false);
    const [stores, setStores] = useState(emptyObject);
    useEffect(() => {
        const fetchStores = (client, mids) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setIsLoading(true);
                const { data } = yield client.request({
                    method: 'GET',
                    url: '/merchants',
                    params: {
                        mid: mids
                    }
                });
                setIsLoading(false);
                const updatedStores = data.reduce((currentStores, store) => (Object.assign(Object.assign({}, currentStores), { [store.merchant_id]: store.store_name })), {});
                setStores(updatedStores);
            }
            catch (error) {
                setIsLoading(false);
                return {};
            }
        });
        const mids = mapToEntityId('merchant', pendingEntities);
        if (mids.length) {
            fetchStores(client, mids);
        }
    }, [client, pendingEntities]);
    return { isLoading, stores };
};
const usePartners = (client, pendingEntities) => {
    const [isLoading, setIsLoading] = useState(false);
    const [partners, setPartners] = useState(emptyObject);
    useEffect(() => {
        const fetchPartners = (client, partnerAccountIds) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setIsLoading(true);
                const requestPromises = partnerAccountIds.map(partnerAccountId => {
                    return client.request({
                        method: 'GET',
                        url: `/partners/${partnerAccountId}`
                    });
                });
                const responses = yield Promise.all(requestPromises);
                setIsLoading(false);
                const updatedPartners = responses.reduce((currentStores, response) => (Object.assign(Object.assign({}, currentStores), { [response.data.id]: response.data.name })), {});
                setPartners(updatedPartners);
            }
            catch (error) {
                setIsLoading(false);
                return [];
            }
        });
        const partnerAccountIds = mapToEntityId('klarna_resource', pendingEntities);
        if (partnerAccountIds.length) {
            fetchPartners(client, partnerAccountIds);
        }
    }, [client, pendingEntities]);
    return { isLoading, partners };
};
const useInvites = (client, userId, choices, handleInvite) => {
    const [isInviting, setIsInviting] = useState(false);
    const [errors, setErrors] = useState([]);
    const handleInvites = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsInviting(true);
        const invitePromises = choices.map(choice => {
            switch (choice.value) {
                case InviteChoices.ACCEPT:
                    return client.put(`/users/${userId}/entities`, {
                        entity_type: choice.entityType,
                        entity_id: choice.entityId,
                        status: 'ACTIVE'
                    })
                        .catch(ex => (Object.assign(Object.assign({}, choice), { error: ex })));
                case InviteChoices.DECLINE:
                    return client.delete(`/users/${userId}/entities?entity_type=${choice.entityType}&entity_id=${choice.entityId}`)
                        .catch(ex => (Object.assign(Object.assign({}, choice), { error: ex })));
            }
        });
        setIsInviting(false);
        const responses = yield Promise.all(invitePromises);
        const inviteErrors = responses.filter(response => 'error' in response);
        if (inviteErrors.length) {
            setErrors(inviteErrors);
        }
        else {
            handleInvite();
        }
    });
    return { isInviting, errors, handleInvites };
};
export { useInvites, usePartners, useStores };
