import PropTypes from 'prop-types';
import React from 'react';
import { StyleProvider } from './utils/context';
import { calculateSpaceWithFactor, getSpaceFactorFromBreakpoint, getValueFromObject, getValueWithBreakpointFallback } from './utils/responsive';
import { withResponsive } from './utils/withResponsive';
const getRowStyles = ({ alignItems: _alignItems, display: _display, spacing: _spacing, justify, breakpoint, extraStyles }) => {
    const alignItems = getValueWithBreakpointFallback(_alignItems, breakpoint, 'normal');
    const display = getValueFromObject(_display, breakpoint, 'flex');
    const justifyContent = getValueWithBreakpointFallback(justify, breakpoint, 'normal');
    const spacingFactor = getValueWithBreakpointFallback(_spacing, breakpoint, getSpaceFactorFromBreakpoint(breakpoint));
    const gutter = calculateSpaceWithFactor(spacingFactor);
    return Object.assign(Object.assign({}, extraStyles), { flexDirection: 'row', flexWrap: 'wrap', marginLeft: -gutter / 2, marginRight: -gutter / 2, width: `calc(100% + ${gutter}px)`, alignItems,
        display,
        justifyContent });
};
const RowContainer = ({ spacing, alignItems, breakpoint, children, display, justify, spaceBottom, extraStyles = {} }) => {
    const initialStyle = { spacing, spaceBottom };
    return (React.createElement(StyleProvider, { initialStyle: initialStyle },
        React.createElement("div", { className: 'mp-grid-row', style: getRowStyles({ alignItems, justify, spacing, breakpoint, display, extraStyles }) }, children)));
};
RowContainer.propTypes = {
    breakpoint: PropTypes.string.isRequired,
    alignItems: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.any,
    display: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    justify: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    spaceBottom: PropTypes.bool,
    spacing: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    extraStyles: PropTypes.object
};
export const Row = withResponsive(RowContainer);
