import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Typography, SpacerVertical } from '@klarna/bubble-ui';
import { useGetToken } from '@klarna/mp-ui';
import BackButton from './TopBar/BackButton';
import { zIndex } from './constants';
const ContainerStyled = styled.header(() => {
    const backgroundColor = useGetToken('colors/backgrounds/default').toString();
    return css({
        position: 'sticky',
        top: 0,
        width: '100%',
        backgroundColor,
        zIndex: zIndex.appContent.pageHeader
    });
});
const HeaderStyled = styled.div(() => {
    const paddingVertical = useGetToken('space.200').value();
    return css({
        width: '100%',
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical
    });
});
function PageHeaderContainer({ title, description, backNavigation, setHeight }) {
    const [isCompact, setIsCompact] = useState(false);
    /** This is used by the parent component to update the top margin
     * of the main content when the page header collapses/expands
     */
    const containerRef = useCallback((node) => {
        if (!setHeight || !node) {
            return;
        }
        const resizeObserver = new ResizeObserver((entries) => {
            const { height } = entries[0].contentRect;
            setHeight(height);
        });
        resizeObserver.observe(node);
    }, []);
    /** This is used to detect whether the page content has scrolled
     * outside of the viewport and determine if the header should
     * collapse without listening on page scroll events
     */
    const targetRef = useCallback((node) => {
        if (!node) {
            return;
        }
        const intersectionObserver = new IntersectionObserver((entries) => {
            const isElementOffViewport = !entries[0].isIntersecting;
            setIsCompact(isElementOffViewport);
        });
        intersectionObserver.observe(node);
    }, []);
    if (!title && !backNavigation) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ContainerStyled, { ref: containerRef },
            React.createElement(PageHeader, { title: title, description: description, backNavigation: backNavigation, isCompact: isCompact })),
        React.createElement("div", { ref: targetRef })));
}
function PageHeader({ title, description, backNavigation, isCompact }) {
    return (React.createElement(HeaderStyled, null,
        backNavigation && (React.createElement(React.Fragment, null,
            React.createElement(BackButton, Object.assign({}, backNavigation)),
            React.createElement(SpacerVertical, { spaceToken: isCompact ? 'space/unit' : 'space/200' }))),
        React.createElement("div", { "data-testid": 'header-app-title' },
            React.createElement(Typography, { textToken: isCompact
                    ? 'text-style/headings/titles/bold/grande'
                    : 'text-style/headings/titles/bold/huge' }, title)),
        !isCompact && description && (React.createElement(React.Fragment, null,
            React.createElement(SpacerVertical, { spaceToken: 'space/unit' }),
            React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular', colorToken: 'colors/text/neutral' }, description)))));
}
PageHeaderContainer.propTypes = {
    backNavigation: PropTypes.shape({
        path: PropTypes.string,
        onClick: PropTypes.func,
        label: PropTypes.string.isRequired
    }),
    description: PropTypes.string,
    setHeight: PropTypes.func,
    title: PropTypes.string.isRequired
};
export default PageHeaderContainer;
export { PageHeader };
