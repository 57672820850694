import { __rest } from "tslib";
import { getConfig, setConfig } from '../config';
import { InternalEventType } from './types';
const convertEventType = (strType) => {
    if (strType) {
        return InternalEventType[strType] || InternalEventType.Unknown;
    }
    return InternalEventType.Unknown;
};
const getEnvironment = () => {
    const { region, type: environment } = getConfig('environment', {});
    return { region, environment };
};
const withWrapper = (fn) => {
    const enabled = getConfig('insights.mParticle.enabled', false);
    if (!enabled)
        return;
    const mParticle = window.mParticle;
    if (!mParticle) {
        return;
    }
    fn(mParticle);
};
let mParticleCustomerId;
// TODO Save customerid so we can compare it and avoid unnecessary re-login
const identityCallback = result => {
    if (result.getUser()) {
        const user = result.getUser();
        const identities = user.getUserIdentities();
        const { region, environment } = getEnvironment();
        user.setUserAttribute('region', region);
        user.setUserAttribute('environment', environment);
        mParticleCustomerId = identities.customerid;
    }
};
const pageview = (pathname, query, title) => withWrapper((mParticle) => {
    const appName = getConfig('clientId', '');
    const pageTitle = title || (document === null || document === void 0 ? void 0 : document.title) || 'Klarna Merchant Portal';
    const pageAttributes = {
        appName,
        page: pathname,
        query
    };
    mParticle.ready(function () {
        mParticle.logPageView(pageTitle, pageAttributes);
    });
});
const loginOnce = (mParticle, customerid) => {
    if (customerid != null && customerid !== mParticleCustomerId) {
        const identityRequest = {
            userIdentities: {
                customerid
            }
        };
        mParticle.Identity.login(identityRequest, identityCallback);
    }
};
const setUser = userData => withWrapper((mParticle) => {
    const { email, language, username, user_id: personaId } = userData;
    if (personaId !== getConfig('userData.personaId')) {
        loginOnce(mParticle, personaId);
        // TODO Should we use Identity.modify or login here?
        // const identityRequest = {
        //   userIdentities: { customerid: personaId }
        // }
        // mParticle.Identity.modify(identityRequest, identityCallback)
    }
    setConfig('userData', { email, language, username, personaId });
});
const event = (_a) => {
    var { customData, name, type: strType } = _a, eventData = __rest(_a, ["customData", "name", "type"]);
    return withWrapper((mParticle) => {
        var _a;
        const page = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.pathname;
        const appName = getConfig('clientId', '');
        const type = convertEventType(strType);
        // Generate event name if not provided
        const eventName = name || `${eventData.category}/${eventData.action}`;
        const eventInfo = Object.assign(Object.assign({ appName,
            page }, customData), eventData);
        mParticle.ready(function () {
            mParticle.logEvent(eventName, type, eventInfo);
        });
    });
};
const logError = ({ type = 'n/a' }) => withWrapper((mParticle) => {
    const clientId = getConfig('clientId', '');
    mParticle.ready(function () {
        mParticle.logError(`${clientId}/${type}`);
    });
});
export { event, logError, pageview, setUser };
