import React from 'react'
import PropTypes from 'prop-types'
import handleError from '../handleError'
import ErrorPage from './ErrorPage'

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    showErrorPage: PropTypes.bool,
    children: PropTypes.node.isRequired
  }

  state = {
    errorId: null
  }

  componentDidCatch (error, errorInfo) {
    const errorId = handleError('developer-experience', error, errorInfo)
    this.setState({ errorId })
  }

  render () {
    if (this.state.errorId) {
      return this.props.showErrorPage ? (
        <ErrorPage
          errorId={this.state.errorId}
          clearError={() => this.setState({ errorId: null })}
        />
      ) : null
    } else {
      return this.props.children
    }
  }
}
