import { ActivityIndicator, Flex } from '@klarna/mp-ui';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLatestOrderDatesFromMetadata, insightsDateUtils } from '../model';
export const LastUpdatedWidget = ({ mids }) => {
    const t = i18nHooks.useTranslator();
    const merchantData = useSelector(coreSelectors.getMerchants);
    const lastOrderDates = getLatestOrderDatesFromMetadata({ merchantData, selectedMids: mids });
    if (!lastOrderDates.length) {
        return null;
    }
    const elapsedTime = insightsDateUtils.getShortestElapsedTime(lastOrderDates);
    if (!lastOrderDates.length || elapsedTime === null) {
        return null;
    }
    return (React.createElement(Flex, null,
        React.createElement(ActivityIndicator, { label: t('home-fe.insightsWidget.elapsedTime', {
                hours: elapsedTime.elapsedHours,
                minutes: Math.max(elapsedTime.elapsedMinutes, 1)
            }) })));
};
LastUpdatedWidget.propTypes = {
    mids: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]).isRequired
};
