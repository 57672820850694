import React from 'react';
import { useHelpbotState } from '../contexts/HelpbotStateProvider';
import { HelpbotStep } from './HelpbotStep';
const Helpbot = () => {
    const { userPath } = useHelpbotState();
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column-reverse', overflow: 'auto' } }, userPath.slice().reverse().map((nodeId) => {
        return (React.createElement("div", { key: nodeId },
            React.createElement(HelpbotStep, { nodeId: nodeId })));
    })));
};
export { Helpbot };
