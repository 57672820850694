import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
import { selectors as coreSelectors } from 'mage-core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
const ORIGINS_BASE = '/external/origins';
export default function useOrigins({ mid, region: argsRegion, fetchingOriginsInitially = true }) {
    const createClient = useSelector(coreSelectors.createBackendClient);
    const getMerchantRegion = useSelector(coreSelectors.getMerchantRegion);
    const client = useMemo(() => createClient('credentials'), [createClient]);
    const midRegion = useMemo(() => getMerchantRegion(mid), [getMerchantRegion, mid]);
    const region = argsRegion !== null && argsRegion !== void 0 ? argsRegion : midRegion;
    const [isLoading, setIsLoading] = useState(false);
    const [origins, setOrigins] = useState([]);
    const [error, setError] = useState(null);
    const setErrorWithSentry = useCallback((e, message) => {
        const errorId = Sentry.captureException(e);
        const sentryError = { id: errorId, message: message !== null && message !== void 0 ? message : e.message };
        setError(sentryError);
        return sentryError;
    }, []);
    const getOrigins = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const response = yield client.request({ method: 'GET', url: `${ORIGINS_BASE}?mid=${mid}`, region });
            const origins = response.data.origins;
            setOrigins(origins);
            setError(null);
            return origins;
        }
        catch (e) {
            setOrigins([]);
            // @ts-ignore: TODO: type guard `e`
            const sentryError = setErrorWithSentry(e, 'Failed to get origins');
            throw sentryError;
        }
        finally {
            setIsLoading(false);
        }
    }), [client, mid, region]);
    const addOrigin = useCallback((origin) => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const response = yield client.request({
                method: 'POST',
                url: ORIGINS_BASE,
                data: {
                    mid,
                    origin
                },
                region
            });
            const origins = response.data.origins;
            setOrigins(origins);
            setError(null);
            return origins;
        }
        catch (e) {
            setOrigins([]);
            // @ts-ignore: TODO: type guard `e`
            const sentryError = setErrorWithSentry(e, 'Failed to add origin');
            throw sentryError;
        }
        finally {
            setIsLoading(false);
        }
    }), [client, mid, region]);
    const removeOrigin = useCallback((origin) => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const response = yield client.request({
                method: 'DELETE',
                url: ORIGINS_BASE,
                data: {
                    mid,
                    origin
                },
                region
            });
            const origins = response.data.origins;
            setOrigins(origins);
            setError(null);
            return origins;
        }
        catch (e) {
            setOrigins([]);
            // @ts-ignore: TODO: type guard `e`
            const sentryError = setErrorWithSentry(e, 'Failed to remove origin');
            throw sentryError;
        }
        finally {
            setIsLoading(false);
        }
    }), [client, mid, region]);
    useEffect(() => {
        if (fetchingOriginsInitially) {
            getOrigins()
                // eslint-disable-next-line no-console
                .catch(console.error);
        }
    }, [getOrigins, fetchingOriginsInitially]);
    return {
        isLoading,
        origins,
        getOrigins,
        addOrigin,
        removeOrigin,
        error
    };
}
