import { BotNodeId } from '../../../types';
import payoutNotFoundFlow from './payoutNotFound';
import payoutsFlow from './payouts';
import payoutScheduleFlow from './payoutSchedule';
import generatingReportsFlow from './generatingReports';
import payoutAndFeeCalculationFlow from './payoutAndFeeCalculation';
const settlementsFlow = [
    {
        id: BotNodeId.SETTLEMENTS,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.settlements.payoutNotFound.title',
                next: BotNodeId.SETTLEMENTS_PAYOUT_NOT_FOUND
            },
            {
                value: 'helpbot.settlements.payouts.title',
                next: BotNodeId.SETTLEMENTS_PAYOUT
            },
            {
                value: 'helpbot.settlements.payoutSchedule.title',
                next: BotNodeId.SETTLEMENTS_PAYOUT_SCHEDULE
            },
            {
                value: 'helpbot.settlements.generatingReports.title',
                next: BotNodeId.SETTLEMENTS_GENERATING_REPORTS
            },
            {
                value: 'helpbot.settlements.payoutAndFeeCalculation.title',
                next: BotNodeId.SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION
            }
        ]
    },
    ...payoutNotFoundFlow,
    ...payoutsFlow,
    ...payoutScheduleFlow,
    ...generatingReportsFlow,
    ...payoutAndFeeCalculationFlow
];
export default settlementsFlow;
