import { __awaiter } from "tslib";
import actions from './actions';
import * as selectors from './selectors';
const fetchPartners = (tokenHelper) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const partnerAccountIds = tokenHelper.getPartnerAccounts();
    const merchantPortalClient = selectors.createMerchantPortalApiClient(state);
    const requests = partnerAccountIds.map(partnerAccountId => {
        return merchantPortalClient.get(`/partners/${partnerAccountId}`);
    });
    const responses = yield Promise.allSettled(requests);
    const partnerAccounts = responses.filter(promise => promise.status === 'fulfilled')
        .map(fulfilledPromise => fulfilledPromise.value.data);
    // @ts-ignore: FIXME
    dispatch(actions.partnerAccounts.set(partnerAccounts));
});
export { fetchPartners };
