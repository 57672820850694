import { utils as insightsUtils } from 'mage-insights';
import * as R from 'ramda';
import { createActions } from 'redux-actions';
/** ACTIONS **/
const actions = createActions({
    I18N: {
        LANGUAGE: {
            SET: [
                R.objOf('language'),
                language => {
                    return insightsUtils.createInsightsMetaObject('language', 'set', {
                        label: language
                    });
                }
            ]
        }
    }
}).i18n;
export default actions;
