import useIsMfaLocked from '../../hooks/useIsMfaLocked';
const useMfaLockedButton = ({ onLockedClick, onUnlockedClick }) => {
    const isMfaLocked = useIsMfaLocked();
    const handleClick = () => {
        if (isMfaLocked) {
            onLockedClick();
            // call modal here
            alert('modal should be triggered here');
            return;
        }
        onUnlockedClick();
    };
    return {
        handleClick,
        isMfaLocked
    };
};
export default useMfaLockedButton;
