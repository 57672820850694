import React from 'react';
import styled, { css } from 'styled-components';
import styleMapping from './partnerLogoStyleMapping';
const Logo = styled.img(props => {
    var _a;
    const logoFileName = props.src.split('/').pop();
    return css(Object.assign(Object.assign({}, (_a = styleMapping[logoFileName]) !== null && _a !== void 0 ? _a : { height: '20px' }), { position: 'relative' }));
});
const PartnerLogoComponent = ({ src, alt, redirectUrl }) => {
    return (React.createElement("a", { href: redirectUrl, target: '_blank', rel: 'noopener noreferrer' },
        React.createElement(Logo, { src: src, alt: alt, "data-testid": 'testPartnerLogo' })));
};
export default PartnerLogoComponent;
