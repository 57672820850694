import React from 'react'
import { useSelector } from 'react-redux'
import {
  ButtonRoundPrimary,
  IconClose,
  SpacerVertical,
  SpacerHorizontal
} from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import { selectors as coreSelectors } from 'mage-core'
import { Flex, SearchField } from '@klarna/mp-ui'
import { useSidePanelContext } from '@merchant-portal/framework'
import { useHelpContext } from '../../hooks'
import Greeting from './Greeting'
import ShareButton from './ShareButton'
import { cleanDataIntoSession } from '../../utilities/sessionStorage'
import { HELPBOT_SESSION_STORAGE_KEY } from '../../constants'

const cleanHelpbotState = () => cleanDataIntoSession(HELPBOT_SESSION_STORAGE_KEY)

const Opal = '#D3DEDC'

export default function ViewportHeader () {
  const t = i18nHooks.useTranslator()

  const { close: closeHelptool, navigation, searchQuery, setSearchQuery, isSearching } = useHelpContext()
  const { setSidePanelOpen } = useSidePanelContext()

  const isKlarnaRealm = useSelector(coreSelectors.isKlarnaRealm)

  const CloseButton = () => (
    <ButtonRoundPrimary id='uhqm-close-button' size='small' onPress={onCloseClick}>
      <IconClose />
    </ButtonRoundPrimary>
  )

  function onCloseClick () {
    if (isSearching) {
      setSearchQuery('')
    }
    navigation.reset()
    cleanHelpbotState()
    closeHelptool()
    setSidePanelOpen(false)
  }

  const homeHeaderStyle = {
    padding: '16px 20px 30px 30px',
    backgroundColor: Opal
  }
  const pagesHeaderStyle = {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '10px 20px',
    height: 60,
    backgroundColor: Opal
  }
  const isHomePage = navigation.stacksize === 1

  return isHomePage ? (
    <header style={homeHeaderStyle}>
      <Flex justifyContent='flex-end'>
        <CloseButton />
      </Flex>
      <SpacerVertical medium />
      <Greeting />
    </header>
  ) : (
    <>
      <header style={pagesHeaderStyle}>
        <div style={{ display: 'flex' }}>
          {isKlarnaRealm && <ShareButton />}
          <SpacerHorizontal small />
          <CloseButton />
        </div>
      </header>
      {(isSearching) && (
        <div style={{ padding: '10px 20px' }}>
          <SearchField
            id='uhqm-search-input'
            focused
            value={searchQuery}
            onChange={setSearchQuery}
            label={t('help-tool.search.input', {}, 'Search for articles ...')}
          />
        </div>
      )}
    </>
  )
}
