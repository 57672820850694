import { RadioGroup, Separator, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Layout } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
const InviteChoices = {
    ACCEPT: 'Accept',
    DECLINE: 'Decline'
};
const Invite = (props) => {
    const { choice, hasError = false, isDisabled = false, onChange } = props;
    const t = i18nHooks.useTranslator();
    const handleOnChange = (value) => {
        onChange(choice, value);
    };
    const radioGroupProps = {
        isDisabled,
        isHorizontal: true,
        onChange: handleOnChange,
        options: [{
                value: InviteChoices.ACCEPT,
                label: t('home-fe.accept-invite-modal.invite.accept-label')
            }, {
                value: InviteChoices.DECLINE,
                label: t('home-fe.accept-invite-modal.invite.decline-label')
            }]
    };
    if (choice.value) {
        radioGroupProps.value = choice.value;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Separator, null),
        React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
        React.createElement("div", null,
            React.createElement(Layout.Grid, null,
                React.createElement(Layout.Section, { alignItems: 'center' },
                    React.createElement(Layout.Column, { mobileWidth: 6 },
                        React.createElement(Typography, { colorToken: hasError ? 'colors/text/error' : 'colors/text/default', textToken: 'text-style/text/paragraphs/body/bold' }, choice.name),
                        React.createElement(Typography, { colorToken: hasError ? 'colors/text/error' : 'colors/text/neutral', textToken: 'text-style/text/paragraphs/body/regular' }, choice.entityId)),
                    React.createElement(Layout.Column, { mobileWidth: 6 },
                        React.createElement(RadioGroup, Object.assign({}, radioGroupProps)))))),
        React.createElement(SpacerVertical, { spaceToken: 'space.500' })));
};
export default Invite;
export { InviteChoices };
