import { useField } from 'formik';
import { hooks as i18nHooks } from 'mage-i18n';
import { useContext } from 'react';
import FieldValidationContext from '../contexts/FieldValidationContext';
export default function useValidationErrorLabel(fieldName) {
    const context = useContext(FieldValidationContext);
    const t = i18nHooks.useTranslator();
    const [, meta] = useField(fieldName);
    if ((meta.touched || (context === null || context === void 0 ? void 0 : context.forceShowValidationErrors)) && meta.error) {
        return t(meta.error);
    }
}
