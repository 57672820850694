import { IconAnnouncement, IconAppLauncher, IconAssets, IconAssistant, IconCreditCard, IconDebt, IconDelivery, IconEdit, IconGroceries, IconGuidebook, IconHamburger, IconHome, IconInterest, IconInvestmentPurchase, IconKeyboard, IconKlarnaK, IconOrder, IconOther, IconPlay, IconProfile, IconProfileAlternative, IconRocket, IconSettings, IconShop, IconSpeechBubble, IconStores, IconSustainability, IconTransfers, IconTruck } from '@klarna/bubble-ui';
const supportedIcons = {
    IconAnnouncement,
    IconAppLauncher,
    IconAssets,
    IconAssistant,
    IconCreditCard,
    IconDebt,
    IconDelivery,
    IconEdit,
    IconGroceries,
    IconGuidebook,
    IconHamburger,
    IconHome,
    IconInterest,
    IconInvestmentPurchase,
    IconKeyboard,
    IconKlarnaK,
    IconOrder,
    IconOther,
    IconPlay,
    IconProfile,
    IconProfileAlternative,
    IconRocket,
    IconSettings,
    IconShop,
    IconSpeechBubble,
    IconStores,
    IconSustainability,
    IconTransfers,
    IconTruck
};
export default supportedIcons;
