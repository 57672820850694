import { __rest } from "tslib";
import { Link } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
const RouterLink = (_a) => {
    var { children, to, onClick } = _a, props = __rest(_a, ["children", "to", "onClick"]);
    const history = useHistory();
    return (React.createElement(Link, { textToken: 'text-style/text/paragraphs/body/medium', isUnderlined: true, href: to, UNSAFE_props: props, onPress: event => {
            if (onClick) {
                onClick();
            }
            event.preventDefault();
            history.push(to);
        } }, children));
};
RouterLink.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    onClick: PropTypes.func
};
export default RouterLink;
