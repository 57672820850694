import { handleActions } from 'redux-actions';
import * as constants from './constants';
const defaultState = {
    features: null,
    isAuthenticatedFeaturesLoaded: false
};
export default handleActions({
    [constants.SET_FEATURES]: (state, { payload }) => (Object.assign(Object.assign({}, state), { features: payload })),
    [constants.SET_IS_AUTHENTICATED_FEATURES_LOADED]: (state, { payload }) => (Object.assign(Object.assign({}, state), { isAuthenticatedFeaturesLoaded: payload }))
}, defaultState);
