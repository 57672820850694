import { SpacerVertical } from '@klarna/bubble-ui';
import { Flex } from '@klarna/mp-ui';
import React from 'react';
import CloseButton from './CloseButton';
import { DesktopView } from './DesktopView';
import MobileView from './MobileView';
import usePromotionBanner from './usePromotionBanner';
const containerFlexProps = {
    alignItems: 'center',
    style: {
        borderRadius: '16px',
        backgroundColor: '#F6F8FA',
        padding: '40px',
        paddingBottom: '0',
        position: 'relative'
    },
    children: null
};
export default function PromotionBanner() {
    const { isShown, shouldShowDesktopView, handleClose } = usePromotionBanner();
    if (!isShown) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SpacerVertical, { spaceToken: 'space.600' }),
        React.createElement(Flex, Object.assign({}, containerFlexProps),
            React.createElement(CloseButton, { onClick: handleClose }),
            shouldShowDesktopView ? React.createElement(DesktopView, null) : React.createElement(MobileView, null))));
}
