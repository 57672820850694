import { __awaiter } from "tslib";
import { useClientKeys, useOrigins } from '@merchant-portal/credentials';
import * as Sentry from '@sentry/react';
import React, { createContext, useContext, useEffect } from 'react';
import { useAccess } from './useAccess';
import { useError } from './useError';
import { useMid } from './useMid';
export const GENERIC_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.default';
export const GENERIC_ERROR_ID = 'default';
export const DATA_FETCH_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.fetch-data';
export const DATA_FETCH_ERROR_ID = 'fetch-data';
export const CREATE_CLIENT_ID_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.create-client-id';
export const CREATE_CLIENT_ID_ERROR_ID = 'create-client-id';
export const ClientIdContext = createContext(undefined);
export const ClientIdProvider = ({ children }) => {
    const { selectedMid } = useMid();
    const { setErrorToTranslate } = useError();
    const { canCreateClientId } = useAccess();
    const [domainOrigin, setDomainOrigin] = React.useState('');
    const [clientIdToken, setClientIdToken] = React.useState('');
    const [mustRefetchData, setMustRefetchData] = React.useState(false);
    const [isAddOriginModalVisible, setIsAddOriginModalVisible] = React.useState(false);
    const [isLoading, setIsloading] = React.useState(false);
    const isGenerateButtonVisible = !domainOrigin && !clientIdToken;
    const areActionsEnabled = !!selectedMid && canCreateClientId;
    let createFirstClientId;
    const { getOrigins } = useOrigins({
        mid: selectedMid || '',
        fetchingOriginsInitially: false
    });
    const { getClientKeys, createClientKey } = useClientKeys({
        mid: selectedMid || '',
        fetchingClientKeysInitially: false
    });
    if (selectedMid) {
        createFirstClientId = createClientKey;
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                startLoading();
                const origins = yield getOrigins();
                const clientIds = yield getClientKeys();
                setDomainOrigin(getFirstOrigin(origins));
                setClientIdToken(getFirstKeyToken(clientIds));
            }
            catch (error) {
                handleFetchDataError(error);
            }
            finally {
                setIsloading(false);
            }
        });
        useEffect(() => {
            fetchData();
        }, [selectedMid, mustRefetchData]);
    }
    function startLoading() {
        setDomainOrigin('');
        setClientIdToken('');
        setIsloading(true);
    }
    const handleFetchDataError = (error) => {
        const sentryErrorId = Sentry.captureException(error);
        setErrorToTranslate(DATA_FETCH_ERROR_KEY, DATA_FETCH_ERROR_ID, sentryErrorId);
    };
    const onAddOriginSuccess = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsAddOriginModalVisible(false);
        if (!createFirstClientId) {
            setErrorToTranslate(GENERIC_ERROR_KEY, GENERIC_ERROR_ID);
            return;
        }
        yield tryToCreateFirstClientId();
        setMustRefetchData(true);
    });
    const tryToCreateFirstClientId = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsloading(true);
            yield createFirstClientId();
        }
        catch (error) {
            handleCreateClientIdError(error);
        }
        finally {
            setIsloading(false);
        }
    });
    const handleCreateClientIdError = (error) => {
        const sentryErrorId = Sentry.captureException(error);
        setErrorToTranslate(CREATE_CLIENT_ID_ERROR_KEY, CREATE_CLIENT_ID_ERROR_ID, sentryErrorId);
    };
    const openAddOriginModal = () => {
        setIsAddOriginModalVisible(true);
    };
    const accessController = {
        domainOrigin,
        clientIdToken,
        areActionsEnabled,
        isGenerateButtonVisible,
        isAddOriginModalVisible,
        isLoading,
        openAddOriginModal,
        onAddOriginSuccess,
        setIsAddOriginModalVisible
    };
    return (React.createElement(ClientIdContext.Provider, { value: accessController }, children));
};
const getFirstOrigin = (origins) => {
    if (origins.length === 0)
        return '';
    return origins[0];
};
const getFirstKeyToken = (keys) => {
    if (keys.length === 0)
        return '';
    return keys[0].clientToken || '';
};
export const useClientId = () => {
    const value = useContext(ClientIdContext);
    if (!value)
        throw new Error('cannot get ClientIdContext as not been provided');
    return value;
};
