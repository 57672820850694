/**
 * This was copied from mage-components to remove circular dependency.
 * Ideally, this should be refactored after restructuring the support
 * libraries in a way that makes more sense (e.g. extracting the StoreSelector
 * into its own package)
 */
import * as R from 'ramda'

const sortAlphabeticallyByLabel = R.sortBy(R.prop('label'))

const mapMidsToStoreSelectorOptions = (mids, merchants = [], { disabledMids = [], pendingMids = [] } = {}) => {
  const options = mids.map(mid => {
    const merchant = R.find(R.propEq(mid, 'merchantId'), merchants)
    const storeName = R.prop('storeName', merchant)

    const isPending = pendingMids.includes(mid)
    const baseOption = {
      disabled: disabledMids.includes(mid),
      value: mid
    }
    if (storeName) {
      return {
        ...baseOption,
        description: isPending ? `${mid} - PENDING` : mid,
        label: storeName
      }
    } else {
      const option = {
        ...baseOption,
        label: mid
      }
      if (isPending) {
        option.description = 'PENDING'
      }
      return option
    }
  })

  return sortAlphabeticallyByLabel(options)
}

export { mapMidsToStoreSelectorOptions }
