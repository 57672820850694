import { DesignVersionProvider, ParticleDesignVersions, Tooltip } from '@klarna/bubble-ui';
import { EnvironmentId } from '@merchant-portal/framework';
import { constants as commonConstants } from 'mage-common';
import { selectors as configSelectors } from 'mage-config';
import { translator } from 'mage-i18n';
import React from 'react';
import { useSelector } from 'react-redux';
import CMSEnvironmentSelector from './CMSEnvironmentSelector';
import { ThemeSelector } from './UIThemeSelector';
const { ENVS } = commonConstants;
const tooltipStyle = {
    display: 'none',
    justifyContent: 'center',
    position: 'absolute',
    top: '25px',
    left: 'calc(50% - 150px)',
    minWidth: '300px',
    zIndex: 106
};
function toggleTooltip(evt) {
    evt.stopPropagation();
    const tooltip = document.getElementById('tooltip');
    if (tooltip) {
        tooltip.style.display = tooltip.style.display === 'none' ? 'flex' : 'none';
    }
}
const EnvironmentIdentifier = () => {
    const environment = useSelector(configSelectors.environment);
    const t = useSelector(translator);
    if (environment === ENVS.PRODUCTION || !Object.values(ENVS).includes(environment))
        return null;
    const devTooltip = (React.createElement("div", { id: 'tooltip', style: tooltipStyle },
        React.createElement(Tooltip, { inverse: true, isNotText: true, arrow: 'top' },
            React.createElement(ThemeSelector, null),
            React.createElement("hr", { width: '100%' }),
            React.createElement(CMSEnvironmentSelector, null))));
    return (React.createElement(DesignVersionProvider, { value: ParticleDesignVersions.PANCAKE_LIGHT },
        React.createElement(EnvironmentId, { id: 'mpui-environment-id-wrapper', env: environment, label: t(`config.environments.${environment}`), onClick: toggleTooltip }),
        [ENVS.STAGING, ENVS.DEVELOPMENT].includes(environment) && devTooltip));
};
export default EnvironmentIdentifier;
