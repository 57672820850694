const CLIENT_IDS = {
    NKO_APP: 'nko-app',
    ORDERS: 'orders-fe',
    NKO_ORDERS: 'nko-orders',
    SETTLEMENTS: 'settlements-fe',
    NKO_SETTLEMENTS: 'nko-settlements',
    LOGS: 'logs-app-fe',
    USERS: 'users-fe',
    SETTINGS: 'settings-fe',
    NKO_SETTINGS: 'nko-settings',
    SELLER: 'seller-fe',
    BRANDING: 'branding-fe',
    LENDING: 'lending-fe',
    UPSTREAM: 'upstream-fe',
    OPTIMIZATION: 'optimization-fe',
    PRODUCTS: 'products-fe',
    INTEGRATION_GUIDES: 'integration-guides-fe',
    HOME: 'home-fe',
    DISPUTES: 'disputes-fe',
    LOGISTICS: 'logistics-fe',
    SUPPORT: 'support',
    KEC: 'kec-fe',
    SIWK: 'siwk-fe',
    SELF_SERVICE: 'partner-self-service'
};
const ENVS = {
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    PLAYGROUND: 'playground',
    PRODUCTION: 'production'
};
const REGIONS = {
    LOCAL: 'local',
    EU: 'eu',
    US: 'us',
    AP: 'ap'
};
export { CLIENT_IDS, ENVS, REGIONS };
