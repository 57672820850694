import React from 'react'
import PropTypes from 'prop-types'
import { Message } from 'mage-i18n'
import { TitleSecondary } from '@klarna/bubble-ui'

ResultTitle.propTypes = {
  length: PropTypes.number.isRequired,
  singleResultTranslation: PropTypes.string.isRequired,
  multipleResultsTranslation: PropTypes.string.isRequired
}

export default function ResultTitle ({
  length,
  singleResultTranslation,
  multipleResultsTranslation
}) {
  if (length === 0) return null

  return (
    <TitleSecondary>
      <Message
        path={length === 1 ? singleResultTranslation : multipleResultsTranslation}
        params={{ count: length }}
      />
    </TitleSecondary>
  )
}
