import React from 'react';
import { hooks } from 'mage-i18n';
import { HelpbotOptions } from './HelpbotOptions';
import { HelpbotQuestion } from './HelpbotQuestion';
import { HelpbotResponse } from './HelpbotResponse';
import { useHelpbotState } from '../contexts/HelpbotStateProvider';
const generateKey = (obj) => JSON.stringify(obj);
const HelpbotStep = ({ nodeId }) => {
    const t = hooks.useTranslator();
    const { getBotStep } = useHelpbotState();
    const botStep = getBotStep(nodeId);
    const botNode = botStep.node;
    const OptionsComponent = botNode.OptionsComponent;
    const BotMessage = () => botNode.botMessage ? React.createElement(HelpbotQuestion, { Message: botNode.botMessage }) : React.createElement(React.Fragment, null);
    const BotOptions = () => OptionsComponent ? React.createElement(OptionsComponent, null) : React.createElement(HelpbotOptions, { options: botNode.options() });
    const BotResponse = () => botStep.responseValue ? React.createElement(HelpbotResponse, { message: t(botStep.responseValue) }) : React.createElement(BotOptions, null);
    return (React.createElement("div", { key: generateKey(botNode) },
        React.createElement(BotMessage, null),
        React.createElement(BotResponse, null)));
};
export { HelpbotStep };
