export const BASE_GUTTER = 5;
export const BREAKPOINT = {
    MOBILE_S: 0, // min-width should be 375px
    TABLET: 768,
    DESKTOP: 1150
};
// This is a value between 1 and 15 that will be multiplied by the BASE_GUTTER
// to obtain the correct gutter
export const DEFAULT_SPACING = {
    mobile: 4, // will result in a 20px gutter between columns
    tablet: 6, // will result in a 30px gutter between columns
    desktop: 10 // will result in a 50px gutter between columns
};
