import { __awaiter } from "tslib";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useChatClient } from '../hooks/useChatClient';
import { getMarkets, getRestoreId, getWorkingHours } from '../data/channelData';
export const ChannelDataContext = createContext(undefined);
export const ChannelDataStateProvider = ({ children }) => {
    const client = useChatClient();
    const marketsInitState = [];
    const workingHoursInitState = [];
    const restoreIdInitState = { eu: '', ap: '', us: '' };
    const state = {
        loading: true,
        markets: marketsInitState,
        workingHours: workingHoursInitState,
        restoreId: restoreIdInitState
    };
    const [currentState, setState] = useState(state);
    const loadData = (controller) => __awaiter(void 0, void 0, void 0, function* () {
        setState(currentState => (Object.assign(Object.assign({}, currentState), { loading: true })));
        // This hack is needed because the App.js component is rerendering the chat bubble multiple times
        // causing the effect to trigger multiple API requests.
        // Best solution would be to fix the App.js component renders, but that might take way more effort.
        // Once we remove all sidebar logic, we can extract the whole chat bubble feature out of the App.js
        // file, and then we can fix the rerendering issue.
        yield new Promise(resolve => setTimeout(resolve, 200));
        if (controller.signal.aborted)
            return;
        const [markets, workingHours, restoreId] = yield Promise.all([getMarkets(client), getWorkingHours(client), getRestoreId(client)]);
        setState({ markets, workingHours, restoreId, loading: false });
    });
    useEffect(() => {
        const controller = new AbortController();
        loadData(controller).catch(() => { });
        return () => {
            controller.abort();
        };
    }, []);
    const contextValue = {
        isLoading: currentState.loading,
        data: {
            markets: currentState.markets,
            workingHours: currentState.workingHours,
            restoreId: currentState.restoreId
        }
    };
    return (React.createElement(ChannelDataContext.Provider, { value: contextValue }, children));
};
export const useChannelDataState = () => {
    const value = useContext(ChannelDataContext);
    if (!value)
        throw new Error('cannot get ChannelData state manager as it has not been provided');
    return value;
};
