import { __rest } from "tslib";
import { DesignTokens, IconDownload } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React, { useEffect, useState } from 'react';
const styles = {
    downloadText: {
        marginLeft: '10px'
    },
    downloadLink: {
        borderRadius: '20px',
        height: 40,
        padding: '5px 26px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        width: '100%'
    }
};
function DownloadLocalDataLink({ id, data, type = 'text/plain', filename, onClick }) {
    const t = i18nHooks.useTranslator();
    const [hover, setHover] = useState(false);
    const [objectUrl, setObjectUrl] = useState('');
    const designContext = DesignTokens.useDesignContext();
    function getObjectUrl({ data, type }) {
        // @ts-ignore: FIXME
        return window.webkitURL.createObjectURL(new window.Blob([data], { type }));
    }
    useEffect(() => {
        var _a, _b;
        if ((_a = window.webkitURL) === null || _a === void 0 ? void 0 : _a.revokeObjectURL) {
            window.webkitURL.revokeObjectURL(objectUrl);
            setObjectUrl(getObjectUrl({ data, type }));
            return (_b = window.webkitURL) === null || _b === void 0 ? void 0 : _b.revokeObjectURL(objectUrl);
        }
    }, [data, type]);
    const buttonFont = designContext.get('text-style/text/labels/body/regular');
    const textColor = designContext.get('colors/text/inverse').toString();
    const bgColor = designContext.get('colors/backgrounds/inverse').toString();
    const bgColorHovered = designContext.get('colors/backgrounds/inverse', { interactiveState: 'hovered' }).toString();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- textDecoration results in a type error when used in the style prop
    const _a = buttonFont.props, { textDecoration } = _a, buttonFontProps = __rest(_a, ["textDecoration"]);
    return (React.createElement("a", { id: id, style: Object.assign(Object.assign(Object.assign({}, styles.downloadLink), buttonFontProps), { color: textColor, background: hover ? bgColorHovered : bgColor }), onMouseOver: () => setHover(true), onMouseOut: () => setHover(false), onClick: onClick, href: objectUrl, download: filename, type: type },
        React.createElement(IconDownload, { color: 'white' }),
        React.createElement("span", { style: styles.downloadText }, t('credentials.addApiKeySuccessModal.download'))));
}
export default DownloadLocalDataLink;
