import { __rest } from "tslib";
import { Modal } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import React from 'react';
const Dialog = (_a) => {
    var { portal = true, _closeOnClickOutside = true, _useWindowHeight = false, _closeOnEscape = true, children } = _a, props = __rest(_a, ["portal", "_closeOnClickOutside", "_useWindowHeight", "_closeOnEscape", "children"]);
    return (React.createElement(Modal, Object.assign({ usePortal: portal, closeOnClickOutside: true, closeOnEscape: true, useWindowHeight: true }, props), children));
};
Dialog.propTypes = {
    onClose: PropTypes.func,
    closeOnClickOutside: PropTypes.bool,
    useWindowHeight: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    large: PropTypes.bool,
    portal: PropTypes.bool,
    id: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    footer: PropTypes.array,
    unwrapped: PropTypes.bool
};
export default Dialog;
