import { createStorage } from 'mage-common';
import queryString from 'query-string';
import actions from './actions';
export default (store, location, { localStorage: realLocalStorage = window.localStorage, sessionStorage: realSessionStorage = window.sessionStorage } = {}) => {
    const parsedHash = queryString.parse(location.hash);
    const parsedSearch = queryString.parse(location.search);
    const localStorage = createStorage({ storage: realLocalStorage });
    const sessionStorage = createStorage({ storage: realSessionStorage });
    let realm;
    realm = parsedHash.realm ? parsedHash.realm : sessionStorage.get('realm', 'merchants');
    if (!['merchants', 'klarna'].includes(realm)) {
        realm = 'merchants';
    }
    // @ts-ignore: redux-actions createActions returns incorrect type
    store.dispatch(actions.keycloak.realm.set(realm));
    let isPartner;
    if (parsedSearch.partner) {
        isPartner = parsedSearch.partner === 'true';
        localStorage.set('partner', isPartner);
    }
    else {
        isPartner = localStorage.get('partner', false);
    }
    // @ts-ignore: redux-actions createActions returns incorrect type
    store.dispatch(actions.isPartner.set(isPartner));
};
