import { KlarnaLogoFull } from '@klarna/bubble-ui';
import { Flex, useGetToken } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { useDesktop, useTablet } from '../../../hooks/useMediaQuery';
import CustomActionClose from './customActionClose';
import { zIndex } from '../constants';
import PartnerLogo from './PartnerLogo';
const TopBarContainerStyled = styled.div(() => {
    const tabletMediaQuery = useTablet();
    const desktopMediaQuery = useDesktop();
    const height = useGetToken('space.800').value();
    const horizontalPaddingMobile = useGetToken('space.200').value();
    const horizontalPadding = useGetToken('space.300').value();
    return css({
        display: 'flex',
        gridArea: 'topbar',
        height,
        padding: `0 ${horizontalPaddingMobile}px`,
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: zIndex.topbar,
        [tabletMediaQuery]: {
            padding: `0 ${horizontalPadding}px`,
            gridArea: 'topbar/topbar/topbar/panel'
        },
        [desktopMediaQuery]: {
            gridArea: 'topbar'
        }
    });
});
const LogoContainer = styled.div(({ showDistributionPartnerLogo, showOnMobile, isVisible }) => {
    const tabletMediaQuery = useTablet();
    const logoHeight = useGetToken('space.200').value();
    return css(Object.assign(Object.assign({ visibility: showOnMobile && isVisible ? 'visible' : 'hidden', maxHeight: logoHeight }, showDistributionPartnerLogo && {
        display: 'flex',
        alignItems: 'baseline',
        gap: '8px'
    }), { [tabletMediaQuery]: {
            visibility: isVisible ? 'visible' : 'hidden'
        } }));
});
const CollaborationMarkerAndPartnerLogo = (distributionPartner) => (React.createElement(React.Fragment, null,
    React.createElement(CustomActionClose, null),
    React.createElement(PartnerLogo, { src: distributionPartner.logo_url, alt: distributionPartner.name, redirectUrl: distributionPartner.redirect_url }, " ")));
function TopBarLogo({ distributionPartner, showOnMobile }) {
    return (React.createElement(LogoContainer, { showDistributionPartnerLogo: !!distributionPartner, showOnMobile: showOnMobile, isVisible: distributionPartner !== undefined },
        React.createElement(KlarnaLogoFull, { "data-testid": 'mpui-fw-topbar-klarna-logo' }),
        (distributionPartner && CollaborationMarkerAndPartnerLogo(distributionPartner))));
}
const TopBar = ({ id, actionButtons = [], distributionPartner, showLogoOnMobile } = {}) => {
    return (React.createElement(TopBarContainerStyled, { id: id, "data-testid": 'header-appsmenu-button' },
        React.createElement(TopBarLogo, { distributionPartner: distributionPartner, showOnMobile: showLogoOnMobile }),
        React.createElement(Flex, { spacing: 'space.200' }, actionButtons.map((button, index) => (React.createElement(React.Fragment, { key: `mpui-fw-topbar-action-button-${index}` }, button))))));
};
TopBar.propTypes = {
    id: PropTypes.string,
    logo: PropTypes.node,
    actionButtons: PropTypes.arrayOf(PropTypes.node)
};
export default TopBar;
// Allows storybook to get props
export const TopBarPropsDoc = () => null;
