import { AlertBlock, ButtonPrimary, IconAgent, IconRefresh, IllustrationErrorExplodingBalloon, Badge, Separator, SpacerHorizontal, SpacerVertical, TitlePrimary, Typography } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
import React from 'react';
/** The error state for the Merchant Portal */
function Error({ actionButton, contactContent, label, id, illustration, description, reloadButton, title = 'Unexpected error' }) {
    const reloadHandler = () => window.location.reload();
    return (React.createElement("div", { id: id, style: { display: 'flex', flexDirection: 'column' } },
        label && (React.createElement(React.Fragment, null,
            React.createElement(Badge, { backgroundColorToken: 'colors/backgrounds/error-inline', borderColorToken: 'colors/backgrounds/error-inline', textColorToken: 'colors/text/error' }, label),
            React.createElement(SpacerVertical, { spaceToken: 'space.500' }))),
        illustration || React.createElement(IllustrationErrorExplodingBalloon, null),
        React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
        title && React.createElement(TitlePrimary, { correctFootprint: true }, title),
        title && description && React.createElement(SpacerVertical, { spaceToken: 'space.400' }),
        description && (React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular' }, description)),
        (description || title) && (actionButton || reloadButton) && (React.createElement(SpacerVertical, { spaceToken: 'space.500' })),
        React.createElement("div", { style: { display: 'flex' } },
            actionButton && (React.createElement(ButtonPrimary, { size: 'medium', onClick: actionButton.onClick, icon: actionButton.icon, id: actionButton.id }, actionButton.label)),
            reloadButton && actionButton && (React.createElement(SpacerHorizontal, { spaceToken: 'space.200' })),
            reloadButton && (React.createElement(ButtonPrimary, { size: 'medium', onClick: reloadHandler, icon: IconRefresh, id: reloadButton.id }, reloadButton.label))),
        contactContent && (React.createElement(React.Fragment, null,
            React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
            React.createElement(Separator, null),
            React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
            React.createElement(AlertBlock, { iconType: IconAgent, content: contactContent })))));
}
Error.propTypes = {
    id: PropTypes.string,
    illustration: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    contactContent: PropTypes.any,
    actionButton: PropTypes.shape({
        icon: PropTypes.any,
        id: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired
    }),
    reloadButton: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string.isRequired
    })
};
export default Error;
export { Error };
// Allows storybook to get props
export const ErrorPropsDoc = () => null;
export const ReloadButtonPropsDoc = () => null;
export const ActionButtonPropsDoc = () => null;
