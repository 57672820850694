import React from 'react'
import {
  SelectorOptions,
  IconLike,
  SpacerHorizontal
} from '@klarna/bubble-ui'
import PropTypes from 'prop-types'
import { hooks as i18nHooks } from 'mage-i18n'

FeedbackSelector.propTypes = {
  yesOptionLabel: PropTypes.string.isRequired,
  noOptionLabel: PropTypes.string.isRequired,
  value: PropTypes.oneOf(['yes', 'no']),
  onSelect: PropTypes.func
}

export default function FeedbackSelector ({
  value,
  onSelect = () => {},
  yesOptionLabel,
  noOptionLabel
}) {
  const t = i18nHooks.useTranslator()

  return (
    <div style={{ display: 'flex' }}>
      <SpacerHorizontal large />
      <div style={{ flex: '1 1 auto' }}>
        <SelectorOptions
          onSelect={onSelect}
          options={[
            {
              value: 'yes',
              label: t(yesOptionLabel),
              icon: <IconLike color='black' />
            },
            {
              value: 'no',
              label: t(noOptionLabel),
              icon: (
                <div style={{ transform: 'rotate(180deg)' }}>
                  <IconLike color='black' />
                </div>
              )
            }
          ]}
          value={value}
        />
      </div>
    </div>
  )
}
