/**
 * Type classification, used to filter and organize data.
 * This is identical to mParticle.EventType
 */
export var InternalEventType;
(function (InternalEventType) {
    InternalEventType[InternalEventType["Unknown"] = 0] = "Unknown";
    InternalEventType[InternalEventType["Navigation"] = 1] = "Navigation";
    InternalEventType[InternalEventType["Location"] = 2] = "Location";
    InternalEventType[InternalEventType["Search"] = 3] = "Search";
    InternalEventType[InternalEventType["Transaction"] = 4] = "Transaction";
    InternalEventType[InternalEventType["UserContent"] = 5] = "UserContent";
    InternalEventType[InternalEventType["UserPreference"] = 6] = "UserPreference";
    InternalEventType[InternalEventType["Social"] = 7] = "Social";
    InternalEventType[InternalEventType["Other"] = 8] = "Other";
    InternalEventType[InternalEventType["Media"] = 9] = "Media";
})(InternalEventType || (InternalEventType = {}));
