import * as R from 'ramda';
import { NAME } from './constants';
const features = R.path([NAME, 'features']);
const isAuthenticatedFeaturesLoaded = R.path([NAME, 'isAuthenticatedFeaturesLoaded']);
const testFeaturePropEqualities = (featureName, propEqualities) => {
    const tests = Object.entries(propEqualities).map(([key, value]) => R.pathSatisfies(R.equals(value), [featureName, key]));
    return R.pipe(features, R.allPass(tests));
};
const isFeatureToggleEnabled = (featureName) => R.pipe(features, R.pathEq('true', [featureName, 'is_enabled']));
export { features, isAuthenticatedFeaturesLoaded, isFeatureToggleEnabled, testFeaturePropEqualities };
