import React from 'react';
import { hooks } from 'mage-i18n';
import { Chip } from '@klarna/bubble-ui';
import CountryFlag from '../../../Chat/CountryFlag';
const styles = {
    container: {
        marginBottom: '10px',
        textAlign: 'right'
    }
};
const MarketOption = ({ countryCode, onClick }) => {
    const t = hooks.useTranslator();
    const label = t(`core.countries.${countryCode.toLowerCase()}`);
    const FlagIcon = () => React.createElement(CountryFlag, { countryCode: countryCode });
    return (React.createElement("div", { style: styles.container },
        React.createElement(Chip, { title: label, rightIcon: FlagIcon, onClick: onClick })));
};
export default MarketOption;
