import { useGetToken } from '@klarna/mp-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import Insights from 'mage-insights'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import AppTile from './AppTile'

const AppsWrapper = styled.div(() => {
  const gap = useGetToken('space.200').value()

  return css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap,
    width: '100%'
  })
})

function Apps ({ apps }) {
  const t = i18nHooks.useTranslator()

  const openApp = (url) => {
    window.location.assign(url)

    return Insights.event({
      category: 'homepageContent:appWidgets',
      action: 'go_to',
      label: `${url.split('/')[1] || ''}`
    })
  }

  return (
    <AppsWrapper>
      {apps.map(({ title, clientId, url, newApp }) => (
        <AppTile
          key={clientId}
          clientId={clientId}
          onClick={() => openApp(url)}
          title={t(`core.apps.${clientId}.title`) || title}
          description={t(`core.apps.${clientId}.description`)}
          isNewApp={newApp}
        />
      ))}
    </AppsWrapper>
  )
}

Apps.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    newApp: PropTypes.bool
  }))
}

export default Apps
