import { calculateSpaceWithFactor, isDesktop, isMobile, isTablet } from './responsive';
import { wrappedWithSizes } from './withSizes';
const getBreakpoint = ({ isDesktop, isTablet }) => {
    if (isDesktop)
        return 'desktop';
    if (isTablet)
        return 'tablet';
    return 'mobile';
};
export const withResponsive = WrappedComponent => wrappedWithSizes(({ width }) => {
    const props = {
        isDesktop: isDesktop(width),
        isTablet: isTablet(width),
        isMobile: isMobile(width),
        isWeb: true
    };
    return Object.assign(Object.assign({}, props), { gutter: calculateSpaceWithFactor(props), breakpoint: getBreakpoint(props) });
})(WrappedComponent);
