import React from 'react';
import { Radio } from '@klarna/bubble-ui';
import { hooks } from 'mage-i18n';
import { useChannelDataState } from '../../context/ChannelDataStateProvider';
import CountryFlag from '../Chat/CountryFlag';
import MarketChannels from './MarketChannels';
const getMarketOptions = (markets, t) => markets.map(market => ({
    label: t(`core.countries.${market.countryCode.toLowerCase()}`),
    value: market.countryCode,
    aside: React.createElement(CountryFlag, { countryCode: market.countryCode }),
    content: React.createElement(MarketChannels, { marketCountryCode: market.countryCode })
}));
const ChannelSelector = () => {
    const { data: { markets } } = useChannelDataState();
    const t = hooks.useTranslator();
    return (React.createElement(Radio, { options: getMarketOptions(markets, t) }));
};
export default ChannelSelector;
