import * as R from 'ramda';
const sortAlphabeticallyByLabel = R.sortBy(R.prop('label'));
const mapMidsToStoreSelectorOptions = (mids, merchants = [], { disabledMids = [], pendingMids = [] } = {}) => {
    const options = mids.map(mid => {
        const merchant = R.find(R.propEq(mid, 'merchantId'), merchants);
        const storeName = merchant ? R.prop('storeName', merchant) : undefined;
        const isPending = pendingMids.includes(mid);
        const baseOption = {
            disabled: disabledMids.includes(mid),
            value: mid
        };
        if (storeName) {
            return Object.assign(Object.assign({}, baseOption), { description: isPending ? `${mid} - PENDING` : mid, label: storeName });
        }
        else {
            return Object.assign(Object.assign({}, baseOption), { description: isPending ? 'PENDING' : undefined, label: mid });
        }
    });
    return sortAlphabeticallyByLabel(options);
};
export { mapMidsToStoreSelectorOptions };
