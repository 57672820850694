import * as Sentry from '@sentry/react';
export const generateRecordUserRestoreId = (chatClient) => (restoreId, region) => {
    try {
        chatClient.request({
            method: 'POST',
            url: '/v1/chat/restore-id',
            region,
            data: { restore_id: restoreId }
        });
    }
    catch (error) {
        Sentry.captureException(error);
    }
};
