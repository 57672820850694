import { TitlePrimary } from '@klarna/bubble-ui'
import { hooks as coreHooks } from 'mage-core'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'

const Greeting = () => {
  const t = i18nHooks.useTranslator()
  const tokenHelper = coreHooks.useTokenHelper()
  const givenName = tokenHelper.getGivenName()
  const message = t('help-tool.home.greeting', { givenName }, `Hello ${givenName}! How can we help you?`)

  return (
    <TitlePrimary id='uhqm-sidebar-home-title' small>
      {message}
    </TitlePrimary>
  )
}

export default Greeting
