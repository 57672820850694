import { hooks as i18nHooks } from 'mage-i18n';
import { useContext } from 'react';
import FieldTranslationContext from '../contexts/FieldTranslationContext';
export default function useLabelTranslation(fieldName) {
    const t = i18nHooks.useTranslator();
    const contextValue = useContext(FieldTranslationContext);
    if (contextValue) {
        const { namespace, suffix } = contextValue;
        const key = [namespace, fieldName, suffix].filter(Boolean).join('.');
        return t(key, {}, `Missing translation for label: ${key}`);
    }
}
