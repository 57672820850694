export const storeDataIntoSession = ({ key, data }) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data))
  } catch (e) {
    console.log('cannot store data into storage session', e.message)
  }
}

export const getDataFromSessionStorage = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key))
  } catch (e) {
    console.log('cannot get data from storage session', e.message)
    return null
  }
}
