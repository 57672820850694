import { __awaiter } from "tslib";
import AmazonConnectWidget from './components/AmazonConnectWidget';
import getAuthToken from './auth/getAuthToken';
import { getConfig } from './config';
const prepareWidgetCallbacks = (ctx) => {
    const onWidgetOpen = () => {
        console.log('widget opened');
        ctx.onChatWidgetOpens();
    };
    const onChatEnded = () => {
        console.log('onChatEnded');
    };
    const onAuthRequested = getAuthToken(ctx.chatClient);
    return { onWidgetOpen, onChatEnded, onAuthRequested };
};
const startAmazonConnectConversation = (ctx) => __awaiter(void 0, void 0, void 0, function* () {
    const widgetConfig = getConfig(ctx);
    // TODO: add handlers (open, close, navigation, chat resolved)
    const callbacks = prepareWidgetCallbacks(ctx);
    AmazonConnectWidget(widgetConfig, callbacks);
});
export default startAmazonConnectConversation;
