/**
 * This component is deprecated!
 * It's been copied from bubble-contrib to unblock the bump to bubble-ui v3,
 * but center alignment is not encouraged by the design system
 * and we will move away from using it altogether.
 */
import { ButtonPrimary, ButtonQuaternary, ButtonSecondary, ButtonTertiary, SpacerVertical, TitleDefinition, TitlePrimary, Typography, View } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
import React from 'react';
const styles = {
    mainStyle: {
        flexDirection: 'column'
    },
    illustrationWrapperStyle: {
        paddingBottom: 20,
        alignSelf: 'center'
    },
    addressStyle: {
        paddingTop: 20,
        paddingBottom: 20
    },
    definitionStyle: {
        paddingTop: 25,
        paddingBottom: 30
    },
    buttonStyle: {
        paddingTop: 20
    },
    contentStyle: {
        alignItems: 'center',
        paddingTop: 30
    },
    legalWrapperStyle: {
        alignSelf: 'center',
        maxWidth: '100%'
    },
    footnoteWrapperStyle: {
        alignSelf: 'center'
    },
    generalErrorStyle: {
        marginTop: 20,
        width: '100%'
    }
};
const Button = {
    Primary: ButtonPrimary,
    Secondary: ButtonSecondary,
    Tertiary: ButtonTertiary,
    Quaternary: ButtonQuaternary
};
const getButton = ({ content, type, props }, index) => {
    const ButtonType = Button[type];
    return (React.createElement(ButtonType, Object.assign({ key: index }, props), content));
};
function CenteredContent({ buttons, children, definition, footnote, illustration, summary, title }) {
    return (React.createElement(View, { style: styles.mainStyle },
        illustration && (React.createElement(View, { style: styles.illustrationWrapperStyle }, illustration)),
        React.createElement("div", null,
            React.createElement(TitlePrimary, { small: true, centered: true, correctFootprint: true }, title),
            React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular', textAlignToken: 'text-alignment/center' }, summary),
            React.createElement(SpacerVertical, { extraSmall: true })),
        definition && (React.createElement(View, { style: styles.definitionStyle },
            React.createElement(TitleDefinition, { small: true, centered: true, correctFootprint: true }, definition.title),
            React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular', textAlignToken: 'text-alignment/center' }, definition.content),
            React.createElement(SpacerVertical, { extraSmall: true }))),
        children && (React.createElement(View, { style: styles.contentStyle }, children)),
        footnote && (React.createElement(View, { style: styles.footnoteWrapperStyle },
            React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular', textAlignToken: 'text-alignment/center' }, footnote))),
        buttons && buttons.map((button, index) => (React.createElement(View, { key: index, style: styles.buttonStyle }, getButton(button, index))))));
}
CenteredContent.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        type: PropTypes.string,
        props: PropTypes.object
    })),
    definition: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string
    }),
    footnote: PropTypes.node,
    illustration: PropTypes.node,
    summary: PropTypes.node,
    title: PropTypes.string,
    children: PropTypes.node
};
export default CenteredContent;
