import React, { Suspense, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useGetToken } from '@klarna/mp-ui'
import { useSidePanelContext } from '@merchant-portal/framework'
import { FreshChatWidget } from 'mage-conversation'
import ViewportHeader from './Header/ViewportHeader'
import ErrorBoundary from './ErrorBoundary'
import FallbackLoader from './FallbackLoader'
import Router from './Router'
import { useHelpContext } from '../hooks'

/**
 * Marble Migration
 * This should replace the Viewport components once the migration
 * to the new framework is done. The framework now provides a
 * responsive sidepanel so there's no need for handling positioning
 * from here anymore. Once all apps are migrated, a cleanup
 * will be done as part of MPUX-1294.
 */

const OuterWrapper = styled.div({
  position: 'relative',
  overflow: 'hidden',
  height: '100vh'

})

const ScrollableContainer = styled.div({
  height: '100%',
  borderLeft: '2px rgba(0, 0, 0, 0.15) solid',
  overflowY: 'auto',
  overflowX: 'hidden',
  backgroundColor: 'white'
})

const InnerContainer = styled.div(() => {
  const padding = useGetToken('space.400').value()
  const paddingBottom = useGetToken('space.2000').value()

  return css({
    padding,
    paddingBottom
  })
})

function HelpToolContainer () {
  const { setSidePanelOpen } = useSidePanelContext()
  const { isOpen } = useHelpContext()

  const openHelpTool = () => setSidePanelOpen(true)

  if (isOpen) {
    openHelpTool()
  }

  const scrollableContainer = useRef(null)

  return (
    <ErrorBoundary>
      <Suspense fallback={<FallbackLoader />}>
        <OuterWrapper>
          <ScrollableContainer ref={scrollableContainer}>
            <ErrorBoundary showErrorPage>
              <ViewportHeader />
              <InnerContainer>
                <Router />
              </InnerContainer>
            </ErrorBoundary>
          </ScrollableContainer>
        </OuterWrapper>
      </Suspense>
      <FreshChatWidget />
    </ErrorBoundary>
  )
}

export default HelpToolContainer
