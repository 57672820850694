import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
import insights from 'mage-insights';
import React, { createContext, useContext } from 'react';
import { useAccess } from './useAccess';
import { useCreateApiKey } from './useCreateApiKey';
import { useError } from './useError';
import { useHasActiveApiKeys } from './useHasActiveApiKeys';
import { useMid } from './useMid';
import { usePlugin } from './usePlugin';
export const DEFAULT_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.default';
export const CREATE_API_KEY_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.create-api-key';
export const CREATE_API_KEY_ERROR_ID = 'create-api-key';
export const ApiKeyContext = createContext(undefined);
export const ApiKeyProvider = ({ children }) => {
    const { selectedMid } = useMid();
    const { canCreateApiKey } = useAccess();
    const { setErrorToTranslate } = useError();
    const { pluginId } = usePlugin();
    const { hasAlreadyActiveKeys, setHasAlreadyActiveKeys } = useHasActiveApiKeys({ mid: selectedMid });
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);
    const [createApiKeyData, setCreateApiKeyData] = React.useState(undefined);
    const areActionsEnabled = !!selectedMid && canCreateApiKey;
    const createApiKeyFn = useCreateApiKey({ selectedMid });
    const createApiKey = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedMid) {
            insights.event({
                category: `boost-home/plugin-guide/${pluginId}/generate-api-key`,
                action: 'click',
                label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
            });
            try {
                setIsLoading(true);
                const data = yield createApiKeyFn();
                handleCreationSuccess(data);
            }
            catch (error) {
                handleError(error);
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const handleCreationSuccess = (data) => {
        setCreateApiKeyData(data);
        setIsSuccessModalOpen(true);
        setHasAlreadyActiveKeys(true);
    };
    const handleError = (error) => {
        const sentryErrorId = Sentry.captureException(error);
        setErrorToTranslate(CREATE_API_KEY_ERROR_KEY, CREATE_API_KEY_ERROR_ID, sentryErrorId);
    };
    const onSuccessModalClose = () => {
        setIsSuccessModalOpen(false);
    };
    const apiKey = {
        createApiKeyData,
        areActionsEnabled,
        hasAlreadyActiveKeys,
        isLoading,
        isSuccessModalOpen,
        createApiKey,
        onSuccessModalClose
    };
    return (React.createElement(ApiKeyContext.Provider, { value: apiKey }, children));
};
export const useApiKey = () => {
    const value = useContext(ApiKeyContext);
    if (!value)
        throw new Error('cannot get ApiKeyContext has not been provided');
    return value;
};
