import { Framework } from '@merchant-portal/framework';
import PropTypes from 'prop-types';
import React from 'react';
import EnvironmentIdentifier from '../EnvironmentIdentifier';
const Unauthenticated = ({ footerConfig }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(EnvironmentIdentifier, null),
        React.createElement(Framework, { isLoading: true, footerConfig: { text: footerConfig.text } })));
};
Unauthenticated.propTypes = {
    footerConfig: PropTypes.object
};
export default Unauthenticated;
