import { useDispatch } from 'react-redux'
import { thunks as stateThunks } from 'mage-state'
import { useCallback } from 'react'

export default function useSupportCenterFetch () {
  const dispatch = useDispatch()

  return useCallback(
    (path, options) => {
      const fullPath = `/api/{region}/support-center${path}`

      return dispatch(stateThunks.fetch(fullPath, options))
    },
    [dispatch, stateThunks.fetch]
  )
}
