import React from 'react'
import PropTypes from 'prop-types'
import TopicList from '../Topic/TopicList'
import ArticleList from '../Article/ArticleList'
import { SpacerVertical } from '@klarna/bubble-ui'
import ResultTitle from './ResultTitle'
import NoResultsMessage from './NoResultsMessage'

ResultsView.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
  articles: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default function ResultsView ({ topics, articles }) {
  if (topics.length || articles.length) {
    return (
      <>
        <ResultTitle
          length={topics.length}
          singleResultTranslation='help-tool.search.results.singleTopicFound'
          multipleResultsTranslation='help-tool.search.results.multipleTopicsFound'
        />
        <SpacerVertical small />
        <TopicList topics={topics} analyticsCategory='uhqm/topic-title/search' />

        {!!topics.length && <SpacerVertical medium />}

        <ResultTitle
          length={articles.length}
          singleResultTranslation='help-tool.search.results.singleArticleFound'
          multipleResultsTranslation='help-tool.search.results.multipleArticlesFound'
        />
        <ArticleList articles={articles} analyticsCategory='uhqm/article-title/search' />
      </>
    )
  } else {
    return <NoResultsMessage />
  }
}
