import * as Sentry from '@sentry/react'

export default function handleError (team, error, extras = {}) {
  console.error('Help tool error caught:', error)

  if (extras.componentStack) {
    console.error('Component stack:', extras.componentStack)
  }

  const isExpectedError = error.request && [0, 400, 401, 403, 415].includes(error.request.status)

  if (!isExpectedError) {
    const mergedExtras = {
      request: error.request,
      response: error.response,
      ...extras
    }

    let errorId

    Sentry.withScope(scope => {
      scope.setTag('team', team)
      scope.setExtras(mergedExtras)

      errorId = Sentry.captureException(error)
    })

    console.error('Error id:', errorId)

    return errorId
  }
}
