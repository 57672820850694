import { mergeDeepRight } from 'ramda'
import { UHQM_SESSION_STORAGE_KEY } from '../constants'

export const initialNavigationStack = [{ path: 'home' }]

export const persistentStateKeys = ['sidebar', 'navigationStack', 'search']

export const getInitialState = (animationDuration, restorePersistedState = false) => {
  const state = {
    topics: [],
    sidebar: {
      isOpen: false,
      isOpening: false,
      animationDuration
    },
    navigationStack: initialNavigationStack,
    search: {
      query: '',
      isSearching: false
    },
    isWidgetOpen: false
  }

  if (restorePersistedState) {
    let restoredState = {}
    if (window.sessionStorage) {
      try {
        restoredState = JSON.parse(window.sessionStorage.getItem(UHQM_SESSION_STORAGE_KEY)) || {}
      } catch {
        console.error('unable to parse and restore uhqm state')
      }
    }

    return mergeDeepRight(state, restoredState)
  }

  return state
}
