import { __awaiter, __rest } from "tslib";
import { selectors as configSelectors } from 'mage-config';
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import * as R from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
const fetchMerchantData = R.memoizeWith((_, region, mids) => R.pipe(R.sort((a, b) => a.localeCompare(b)), R.prepend(region), R.join(','))(mids), (client, region, mids, { cancelToken, withMetadata = true, withPartners = false } = {}) => client.request({
    method: 'GET',
    url: '/merchants',
    params: {
        mid: mids,
        with_metadata: withMetadata,
        with_partners: withPartners
    },
    cancelToken,
    region
}));
const mergeByProp = (prop, list) => R.pipe(R.groupBy(R.prop(prop)), R.values, R.flatten, R.uniqBy(R.prop(prop)))(list);
const mergeMerchantData = (merchantData) => {
    if (!merchantData)
        return [];
    return R.pipe(R.flatten, R.reject(R.isNil), R.reduce((acc, _a) => {
        var { merchant_id: merchantId, partners = [], transacting_regions: transactingRegions = [] } = _a, rest = __rest(_a, ["merchant_id", "partners", "transacting_regions"]);
        const merchant = acc[merchantId] || { merchant_id: merchantId, partners, transacting_regions: transactingRegions };
        acc[merchantId] = Object.assign(Object.assign(Object.assign({}, merchant), rest), { partners: mergeByProp('name', [...merchant.partners, ...partners]), transacting_regions: mergeByProp('region', [...merchant.transacting_regions, ...transactingRegions]) });
        return acc;
    }, {}), R.values)(merchantData);
};
/*
this batch size ensures that the length of the url doesnt exceed 2000 chars which might break older browsers
https://support.microsoft.com/en-us/topic/maximum-url-length-is-2-083-characters-in-internet-explorer-174e7c8a-6666-f4e0-6fd6-908b53c12246
*/
const getBatchedMerchantData = (client_1, region_1, mids_1, ...args_1) => __awaiter(void 0, [client_1, region_1, mids_1, ...args_1], void 0, function* (client, region, mids, { cancelToken, batchSize = 100, withMetadata, withPartners } = {}) {
    if (!mids)
        return [];
    const merchants = [];
    const midBatches = R.splitEvery(batchSize, mids);
    for (const midBatch of midBatches) {
        try {
            const { data: merchantData } = yield fetchMerchantData(client, region, midBatch, { cancelToken, withMetadata, withPartners });
            midBatch.forEach(mid => {
                const merchant = R.find(R.propEq(mid, 'merchant_id'), merchantData);
                merchants.push(Object.assign({ merchant_id: mid }, merchant));
            });
        }
        catch (e) {
            // error is swallowed here, possibly problematic
            midBatch.forEach(mid => {
                merchants.push({
                    merchant_id: mid
                });
            });
        }
    }
    return merchants;
});
const useFetchMerchantsData = (tokenHelper) => {
    const { cancelRequest, createCancelToken } = coreHooks.useCancelToken();
    const mpApiClient = useSelector(coreSelectors.createMerchantPortalApiClient);
    const currentRegion = useSelector(configSelectors.region);
    const [isFetching, setFetching] = useState(false);
    const [merchantData, setMerchantData] = useState([]);
    const isMounted = useRef(false);
    const getMerchantData = useCallback((tokenHelper) => __awaiter(void 0, void 0, void 0, function* () {
        setFetching(true);
        const mids = tokenHelper.getMids();
        const withPartners = tokenHelper.isInKlarnaRealm();
        const otherRegions = R.reject(R.equals(currentRegion), ['eu', 'us', 'ap']);
        const cancelToken = createCancelToken();
        const currentRegionMerchants = yield getBatchedMerchantData(mpApiClient, currentRegion, mids, {
            cancelToken,
            withMetadata: true,
            withPartners
        });
        if (isMounted.current)
            setMerchantData(mergeMerchantData([currentRegionMerchants]));
        const otherRegionMerchantDataPromises = otherRegions.map(region => getBatchedMerchantData(mpApiClient, region, mids, {
            cancelToken,
            withMetadata: true,
            withPartners
        }));
        const otherRegionMerchants = yield Promise.all(otherRegionMerchantDataPromises);
        if (isMounted.current) {
            setMerchantData(mergeMerchantData([currentRegionMerchants, otherRegionMerchants.flat()]));
            setFetching(false);
        }
    }), [createCancelToken, currentRegion, mpApiClient]);
    useEffect(() => {
        isMounted.current = true;
        if (tokenHelper)
            getMerchantData(tokenHelper);
        return () => {
            isMounted.current = false;
            cancelRequest();
            setFetching(false);
        };
    }, [cancelRequest, getMerchantData, tokenHelper]);
    return {
        getMerchantData,
        isFetching,
        merchantData
    };
};
export default useFetchMerchantsData;
export { fetchMerchantData, getBatchedMerchantData, mergeByProp, mergeMerchantData };
