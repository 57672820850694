import React from 'react';
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage';
import { BotNodeId } from '../../../types';
import { INeedMoreHelpThatHelped } from '../../common';
const disputesLifecycle = [
    {
        id: BotNodeId.DISPUTES_LIFECYCLE,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.disputes.timingAndDeadlines',
                next: BotNodeId.DISPUTES_LIFECYCLE_TIMING_AND_DEADLINES
            },
            {
                value: 'helpbot.disputes.resolutionProcess',
                next: BotNodeId.DISPUTES_LIFECYCLE_RESOLUTION_PROCESS
            }
        ]
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_TIMING_AND_DEADLINES,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.disputes.disputesLifecycle.whenCanSeeDisputes.question',
                next: BotNodeId.DISPUTES_LIFECYCLE_WHEN_CAN_SEE_DISPUTES
            },
            {
                value: 'helpbot.disputes.disputesLifecycle.howLongToResolve.question',
                next: BotNodeId.DISPUTES_LIFECYCLE_HOW_LONG_TO_RESOLVE
            },
            {
                value: 'helpbot.disputes.disputesLifecycle.howMuchTimeToRefund.question',
                next: BotNodeId.DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_REFUND
            },
            {
                value: 'helpbot.disputes.disputesLifecycle.howMuchTimeToRespond.question',
                next: BotNodeId.DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_RESPOND
            },
            {
                value: 'helpbot.disputes.disputesLifecycle.howLongToSeeDispute.question',
                next: BotNodeId.DISPUTES_LIFECYCLE_HOW_LONG_TO_SEE_DISPUTES
            },
            {
                value: 'helpbot.disputes.disputesLifecycle.whenToGetCharged.question',
                next: BotNodeId.DISPUTES_LIFECYCLE_WHEN_TO_GET_CHARGED
            }
        ]
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_RESOLUTION_PROCESS,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.disputes.disputesLifecycle.howToResolve.question',
                next: BotNodeId.DISPUTES_LIFECYCLE_HOW_TO_RESOLVE
            },
            {
                value: 'helpbot.disputes.disputesLifecycle.whenToRespond.question',
                next: BotNodeId.DISPUTES_LIFECYCLE_WHEN_TO_RESPOND
            }
        ]
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_WHEN_CAN_SEE_DISPUTES,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesLifecycle.whenCanSeeDisputes.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_HOW_LONG_TO_RESOLVE,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesLifecycle.howLongToResolve.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_REFUND,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesLifecycle.howMuchTimeToRefund.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_HOW_TO_RESOLVE,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesLifecycle.howToResolve.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_WHEN_TO_RESPOND,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesLifecycle.whenToRespond.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_RESPOND,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesLifecycle.howMuchTimeToRespond.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_HOW_LONG_TO_SEE_DISPUTES,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesLifecycle.howLongToSeeDispute.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.DISPUTES_LIFECYCLE_WHEN_TO_GET_CHARGED,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.disputes.disputesLifecycle.whenToGetCharged.answer' })),
        options: () => INeedMoreHelpThatHelped
    }
];
export default disputesLifecycle;
