import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import './Code.css';
import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
const defaultOptions = {
    lineNumbers: true,
    lineWrapping: true,
    mode: 'htmlmixed',
    readOnly: true,
    viewportMargin: Infinity
};
function Code({ code, options = {}, onChange = () => { }, onBeforeChange = () => { } }) {
    const mergedOptions = Object.assign(Object.assign({}, defaultOptions), options);
    return (React.createElement(CodeMirror, { value: code, className: 'code--mirror--instance', options: mergedOptions, onChange: onChange, onBeforeChange: onBeforeChange }));
}
export default Code;
