import React from 'react'
import PropTypes from 'prop-types'
import { pipe, map, flatten, uniq, filter } from 'ramda'
import { useCMSEntries } from 'mage-cms'
import RelatedContent from './RelatedContent'

HelpPageRelatedContent.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string.isRequired,
    relatedTopic: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }),
  topicSlugs: PropTypes.arrayOf(PropTypes.string),
  analyticsCategory: PropTypes.string.isRequired
}

function getFilters (article, topicSlugs) {
  if (article?.relatedTopic) {
    return { id: article.relatedTopic.id }
  } else if (article?.id) {
    return { links_to_entry: article.id }
  } else if (topicSlugs && topicSlugs.length > 0) {
    return { 'slug[in]': topicSlugs.join(',') }
  }
}

export default function HelpPageRelatedContent ({ article, topicSlugs, analyticsCategory }) {
  const topics = useCMSEntries({
    contentType: 'UHQMTopic',
    filters: getFilters(article, topicSlugs)
  })

  const articles = pipe(
    map(topic => topic.articles || []),
    flatten,
    uniq,
    filter(eachArticle => !article || eachArticle.id !== article.id)
  )(topics || [])

  return (
    <RelatedContent
      title='help-tool.relatedContent.title'
      articles={articles}
      analyticsCategory={analyticsCategory}
    />
  )
}
