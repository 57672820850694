import React from 'react'
import PropTypes from 'prop-types'
import { hooks as i18nHooks } from 'mage-i18n'
import {
  ButtonSecondary,
  SpacerVertical,
  TitlePrimary,
  Typography
} from '@klarna/bubble-ui'
import { hooks as coreHooks } from 'mage-core'
import { useHistory } from 'react-router-dom'
import { useHelpContext } from '../../hooks'

ViewTicketPage.propTypes = {
  ticketId: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired
}

export default function ViewTicketPage ({ ticketId, region }) {
  const { reset } = useHelpContext()
  const t = i18nHooks.useTranslator()
  const tokenHelper = coreHooks.useTokenHelper()
  const email = tokenHelper.getEmail()
  const history = useHistory()

  return (
    <div data-testid='view-ticket-page'>
      <TitlePrimary>{t('help-tool.support-page.view-ticket.title')}</TitlePrimary>
      <SpacerVertical small />
      <Typography textToken='text-style/text/paragraphs/body/regular' UNSAFE_props={{ 'data-testid': 'ticket-details' }}>
        <span data-testid='ticket-details-description'>
          {t('help-tool.support-page.view-ticket.details')}
        </span>
        <br />
        <span data-testid='ticket-details-id'>
          {t('help-tool.support-page.view-ticket.ticket', { ticketId })}
        </span>
        <br />
        <span data-testid='ticket-details-email'>
          {t('help-tool.support-page.view-ticket.email', { email })}
        </span>
      </Typography>
      <SpacerVertical small />
      <Typography textToken='text-style/text/paragraphs/body/regular'>{t('help-tool.support-page.view-ticket.status')}</Typography>
      <Typography textToken='text-style/text/paragraphs/body/regular'>
        <ButtonSecondary size='medium' data-testid='view-ticket-link' onClick={onClick}>
          {t('help-tool.support-page.view-ticket.track')}
        </ButtonSecondary>
      </Typography>
    </div>
  )

  function onClick () {
    reset()

    const target = `/support/ticket/${region}-${ticketId}`

    if (window.location.pathname.startsWith('/support')) {
      history.push(target)
    } else {
      setTimeout(() => {
        window.location = target
      }, 300)
    }
  }
}
