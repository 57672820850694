import React from 'react'
import PropTypes from 'prop-types'
import Feedback from '../Feedback'

ArticleFeedback.propTypes = {
  articleId: PropTypes.string.isRequired,
  articleTitle: PropTypes.string.isRequired
}

export default function ArticleFeedback ({ articleId, articleTitle }) {
  return (
    <Feedback
      title='help-tool.feedback.title'
      yesOptionLabel='help-tool.feedback.optionYes'
      noOptionLabel='help-tool.feedback.optionNo'
      yesFeedbackAlert='help-tool.feedback.positiveFeedback'
      category='uhqm/feedback'
      referenceType='article'
      referenceId={articleId}
    />
  )
}
