import PropTypes from 'prop-types';
import React from 'react';
import SelectLanguage from './SelectLanguage';
import UpdateProfile from './UpdateProfile';
export const types = {
    language: 'language',
    profile: 'profile'
};
export default function UserMenuDialogSwitcher({ onChooseDialog, onClose, type }) {
    switch (type) {
        case types.language:
            onChooseDialog();
            return React.createElement(SelectLanguage, { onClose: onClose });
        case types.profile:
            onChooseDialog();
            return React.createElement(UpdateProfile, { onClose: onClose });
        default:
            return null;
    }
}
UserMenuDialogSwitcher.propTypes = {
    onChooseDialog: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(types))
};
