/* eslint-disable no-undef */
export var BotNodeId;
(function (BotNodeId) {
    BotNodeId[BotNodeId["PAYOUTS"] = 0] = "PAYOUTS";
    BotNodeId[BotNodeId["ORDERS"] = 1] = "ORDERS";
    BotNodeId[BotNodeId["SETTLEMENTS"] = 2] = "SETTLEMENTS";
    BotNodeId[BotNodeId["DISPUTES"] = 3] = "DISPUTES";
    // ORDERS - SUB-CATEGORIES
    BotNodeId[BotNodeId["ORDER_MANAGEMENT"] = 4] = "ORDER_MANAGEMENT";
    BotNodeId[BotNodeId["ORDER_PROCESSING"] = 5] = "ORDER_PROCESSING";
    BotNodeId[BotNodeId["ORDER_MODIFICATIONS_AND_ISSUES"] = 6] = "ORDER_MODIFICATIONS_AND_ISSUES";
    BotNodeId[BotNodeId["ORDER_REFUND_ISSUES"] = 7] = "ORDER_REFUND_ISSUES";
    BotNodeId[BotNodeId["ORDER_RESERVATIONS"] = 8] = "ORDER_RESERVATIONS";
    BotNodeId[BotNodeId["ORDER_PAYMENTS"] = 9] = "ORDER_PAYMENTS";
    // ORDER_MANAGEMENT - QUESTIONS
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_CREATE_ORDER_POS"] = 10] = "ORDER_MANAGEMENT_CREATE_ORDER_POS";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_EXTEND_EXPIRY_RESERVATION"] = 11] = "ORDER_MANAGEMENT_EXTEND_EXPIRY_RESERVATION";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS"] = 12] = "ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_PARTIALLY"] = 13] = "ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_PARTIALLY";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_FULLY"] = 14] = "ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_FULLY";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_CAN_I_UPDATE_CUSTOMER_INFO"] = 15] = "ORDER_MANAGEMENT_CAN_I_UPDATE_CUSTOMER_INFO";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_EXTEND_DUE_DATE"] = 16] = "ORDER_MANAGEMENT_EXTEND_DUE_DATE";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_REFUND_ORDER"] = 17] = "ORDER_MANAGEMENT_REFUND_ORDER";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_CANCEL_ORDER"] = 18] = "ORDER_MANAGEMENT_CANCEL_ORDER";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS"] = 19] = "ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DIRECT_PARTNER"] = 20] = "ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DIRECT_PARTNER";
    BotNodeId[BotNodeId["ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DISTRIBUTION_PARTNER"] = 21] = "ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DISTRIBUTION_PARTNER";
    BotNodeId[BotNodeId["ORDER_REFUND_ISSUES_REFUNDED_WHEN_CUSTOMER_GET_FUNDS"] = 22] = "ORDER_REFUND_ISSUES_REFUNDED_WHEN_CUSTOMER_GET_FUNDS";
    BotNodeId[BotNodeId["ORDER_RESERVATIONS_UNABLE_TO_EXTEND_TIME"] = 23] = "ORDER_RESERVATIONS_UNABLE_TO_EXTEND_TIME";
    // ORDER_PAYMENTS - QUESTIONS
    BotNodeId[BotNodeId["ORDER_PAYMENTS_WHEN_WILL_I_GET_PAID"] = 24] = "ORDER_PAYMENTS_WHEN_WILL_I_GET_PAID";
    BotNodeId[BotNodeId["ORDER_PAYMENTS_REFUND_ORDER_RECEIVED_PAYMENT"] = 25] = "ORDER_PAYMENTS_REFUND_ORDER_RECEIVED_PAYMENT";
    BotNodeId[BotNodeId["ORDER_PAYMENTS_REFERENCE_NOT_SHOW_UP"] = 26] = "ORDER_PAYMENTS_REFERENCE_NOT_SHOW_UP";
    BotNodeId[BotNodeId["ORDER_PAYMENTS_GOT_PAID_SPECIFIC_ORDER"] = 27] = "ORDER_PAYMENTS_GOT_PAID_SPECIFIC_ORDER";
    // DISPUTES - SUB-CATEGORIES
    BotNodeId[BotNodeId["MERCHANT_DISPUTES_APP"] = 28] = "MERCHANT_DISPUTES_APP";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE"] = 29] = "DISPUTES_LIFECYCLE";
    BotNodeId[BotNodeId["DISPUTES_HANDLING"] = 30] = "DISPUTES_HANDLING";
    BotNodeId[BotNodeId["DISPUTES_MANAGEMENT"] = 31] = "DISPUTES_MANAGEMENT";
    BotNodeId[BotNodeId["DISPUTES_CONTACT_INFO_NOTIFICATIONS"] = 32] = "DISPUTES_CONTACT_INFO_NOTIFICATIONS";
    // DISPUTES - DISPUTES APP QUESTIONS
    BotNodeId[BotNodeId["DISPUTES_APP_RESPOND_TO_DISPUTE"] = 33] = "DISPUTES_APP_RESPOND_TO_DISPUTE";
    BotNodeId[BotNodeId["DISPUTES_APP_OVERVIEW_DISPUTES_CUSTOMER"] = 34] = "DISPUTES_APP_OVERVIEW_DISPUTES_CUSTOMER";
    BotNodeId[BotNodeId["DISPUTES_APP_OVERVIEW_DISPUTES_RESPONSE"] = 35] = "DISPUTES_APP_OVERVIEW_DISPUTES_RESPONSE";
    BotNodeId[BotNodeId["DISPUTES_APP_UPDATE_CONTACT_INFORMATION"] = 36] = "DISPUTES_APP_UPDATE_CONTACT_INFORMATION";
    BotNodeId[BotNodeId["DISPUTES_APP_UPDATE_CUSTOMER_SERVICE_ON_KLARNA_APP"] = 37] = "DISPUTES_APP_UPDATE_CUSTOMER_SERVICE_ON_KLARNA_APP";
    BotNodeId[BotNodeId["DISPUTES_APP_IMPROVE_DISPUTES_PERFORMANCE"] = 38] = "DISPUTES_APP_IMPROVE_DISPUTES_PERFORMANCE";
    BotNodeId[BotNodeId["DISPUTES_APP_OVERVIEW_DISPUTES_PERFORMANCE"] = 39] = "DISPUTES_APP_OVERVIEW_DISPUTES_PERFORMANCE";
    // DISPUTES - DISPUTES LIFECYCLE QUESTIONS
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_TIMING_AND_DEADLINES"] = 40] = "DISPUTES_LIFECYCLE_TIMING_AND_DEADLINES";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_RESOLUTION_PROCESS"] = 41] = "DISPUTES_LIFECYCLE_RESOLUTION_PROCESS";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_WHEN_CAN_SEE_DISPUTES"] = 42] = "DISPUTES_LIFECYCLE_WHEN_CAN_SEE_DISPUTES";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_HOW_LONG_TO_RESOLVE"] = 43] = "DISPUTES_LIFECYCLE_HOW_LONG_TO_RESOLVE";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_REFUND"] = 44] = "DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_REFUND";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_HOW_TO_RESOLVE"] = 45] = "DISPUTES_LIFECYCLE_HOW_TO_RESOLVE";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_WHEN_TO_RESPOND"] = 46] = "DISPUTES_LIFECYCLE_WHEN_TO_RESPOND";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_RESPOND"] = 47] = "DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_RESPOND";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_HOW_LONG_TO_SEE_DISPUTES"] = 48] = "DISPUTES_LIFECYCLE_HOW_LONG_TO_SEE_DISPUTES";
    BotNodeId[BotNodeId["DISPUTES_LIFECYCLE_WHEN_TO_GET_CHARGED"] = 49] = "DISPUTES_LIFECYCLE_WHEN_TO_GET_CHARGED";
    // DISPUTES - DISPUTES HANDLING QUESTIONS
    BotNodeId[BotNodeId["DISPUTES_HANDLING_WHAT_IS_DISPUTE"] = 50] = "DISPUTES_HANDLING_WHAT_IS_DISPUTE";
    BotNodeId[BotNodeId["DISPUTES_HANDLING_HOW_MANY_TIMES_DEFEND"] = 51] = "DISPUTES_HANDLING_HOW_MANY_TIMES_DEFEND";
    BotNodeId[BotNodeId["DISPUTES_HANDLING_DO_I_RECEIVE_NOTIFICATION"] = 52] = "DISPUTES_HANDLING_DO_I_RECEIVE_NOTIFICATION";
    BotNodeId[BotNodeId["DISPUTES_HANDLING_HOW_TO_AVOID_DISPUTES_FEES"] = 53] = "DISPUTES_HANDLING_HOW_TO_AVOID_DISPUTES_FEES";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_NOT_FOUND"] = 54] = "SETTLEMENTS_PAYOUT_NOT_FOUND";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_NOT_FOUND_ORDER_NUMBER_NOT_IN_REPORT"] = 55] = "SETTLEMENTS_PAYOUT_NOT_FOUND_ORDER_NUMBER_NOT_IN_REPORT";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_NOT_FOUND_CHECK_IF_PAID_FOR_ORDER"] = 56] = "SETTLEMENTS_PAYOUT_NOT_FOUND_CHECK_IF_PAID_FOR_ORDER";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT"] = 57] = "SETTLEMENTS_PAYOUT";
    // SETTLEMENTS_PAYOUT_WHEN_IS_NEXT,
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_WHY_CALCULATION_NOT_MATCH"] = 58] = "SETTLEMENTS_PAYOUT_WHY_CALCULATION_NOT_MATCH";
    // SETTLEMENTS_PAYOUT_WHEN_GET_PAID,
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_MISSING_PAYOUT"] = 59] = "SETTLEMENTS_PAYOUT_MISSING_PAYOUT";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_HOW_CALCULATED"] = 60] = "SETTLEMENTS_PAYOUT_HOW_CALCULATED";
    BotNodeId[BotNodeId["SETTLEMENTS_HOW_PAYOUT_WORKS"] = 61] = "SETTLEMENTS_HOW_PAYOUT_WORKS";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_SCHEDULE"] = 62] = "SETTLEMENTS_PAYOUT_SCHEDULE";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_SCHEDULE_WHY_DELAY"] = 63] = "SETTLEMENTS_PAYOUT_SCHEDULE_WHY_DELAY";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_SCHEDULE_CHANGE_PAYOUT_DELAY"] = 64] = "SETTLEMENTS_PAYOUT_SCHEDULE_CHANGE_PAYOUT_DELAY";
    BotNodeId[BotNodeId["SETTLEMENTS_GENERATING_REPORTS"] = 65] = "SETTLEMENTS_GENERATING_REPORTS";
    BotNodeId[BotNodeId["SETTLEMENTS_GENERATING_REPORTS_SEE_REPORTS"] = 66] = "SETTLEMENTS_GENERATING_REPORTS_SEE_REPORTS";
    BotNodeId[BotNodeId["SETTLEMENTS_GENERATING_REPORTS_CONVERT_CSV_TO_EXCEL"] = 67] = "SETTLEMENTS_GENERATING_REPORTS_CONVERT_CSV_TO_EXCEL";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION"] = 68] = "SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_FEES_CALCULATED"] = 69] = "SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_FEES_CALCULATED";
    BotNodeId[BotNodeId["SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_PAYOUT_CALCULATED"] = 70] = "SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_PAYOUT_CALCULATED";
    BotNodeId["WHAT_HELP_WITH"] = "WHAT_HELP_WITH";
    BotNodeId["SELECT_PAYOUT"] = "SELECT_PAYOUT";
    BotNodeId["SELECT_ORDERS"] = "SELECT_ORDERS";
    BotNodeId["THAT_HELPED"] = "THAT_HELPED";
    BotNodeId["SELECT_SUPPORT_CHANNEL"] = "SELECT_SUPPORT_CHANNEL";
    BotNodeId["PAYOUT_NOT_PAID_CAPTURED_ORDERS"] = "PAYOUT_NOT_PAID_CAPTURED_ORDERS";
    BotNodeId["PAYOUT_WHEN_WILL_I_GET_PAID"] = "PAYOUT_WHEN_WILL_I_GET_PAID";
    BotNodeId["PAYOUT_PAID_WRONG_AMOUNT_CAPTURED_ORDERS"] = "PAYOUT_PAID_WRONG_AMOUNT_CAPTURED_ORDERS";
    BotNodeId["PAYOUT_CHANGE_PAYMENT_FREQUENCY"] = "PAYOUT_CHANGE_PAYMENT_FREQUENCY";
    BotNodeId["PAYOUT_CHANGE_PAYMENT_FREQUENCY_IS_UNABLE"] = "PAYOUT_CHANGE_PAYMENT_FREQUENCY_IS_UNABLE";
    BotNodeId["PAYOUT_CHANGE_PAYMENT_FREQUENCY_IS_ABLE"] = "PAYOUT_CHANGE_PAYMENT_FREQUENCY_IS_ABLE";
    BotNodeId["PAYOUT_NOT_PAID_IS_SCHEDULE_FOLLOWED"] = "PAYOUT_NOT_PAID_IS_SCHEDULE_FOLLOWED";
    BotNodeId["PAYOUT_PAID_WRONG_AMOUNT_IS_SCHEDULE_FOLLOWED"] = "PAYOUT_PAID_WRONG_AMOUNT_IS_SCHEDULE_FOLLOWED";
    BotNodeId["PAYOUT_NO_CAPTURED_ORDERS"] = "PAYOUT_NO_CAPTURED_ORDERS";
    BotNodeId["PAYOUT_IS_HOLDBACK_OR_DEBT"] = "PAYOUT_IS_HOLDBACK_OR_DEBT";
    BotNodeId["PAYOUT_SCHEDULE_FOLLOWED_EXPLANATION"] = "PAYOUT_SCHEDULE_FOLLOWED_EXPLANATION";
    BotNodeId["PAYOUT_NO_HOLDBACK"] = "PAYOUT_NO_HOLDBACK";
    BotNodeId["PAYOUT_WITH_HOLDBACK"] = "PAYOUT_WITH_HOLDBACK";
    BotNodeId["PAYOUT_FEES_RETURNS_HOLDBACKS_EXPLANATION"] = "PAYOUT_FEES_RETURNS_HOLDBACKS_EXPLANATION";
    BotNodeId["PAYOUT_PAID_WRONG_AMOUNT_SCHEDULE_FOLLOWED_EXPLANATION"] = "PAYOUT_PAID_WRONG_AMOUNT_SCHEDULE_FOLLOWED_EXPLANATION";
    BotNodeId["PAYOUT_WHEN_WILL_I_GET_PAID_CAPTURED_ORDERS"] = "PAYOUT_WHEN_WILL_I_GET_PAID_CAPTURED_ORDERS";
    BotNodeId["PAYOUT_WHEN_WILL_I_GET_PAID_SCHEDULE_FOLLOWED"] = "PAYOUT_WHEN_WILL_I_GET_PAID_SCHEDULE_FOLLOWED";
    BotNodeId["ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER"] = "ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER";
    BotNodeId["ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER_YES"] = "ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER_YES";
    BotNodeId["ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER_NO"] = "ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER_NO";
    BotNodeId["ORDERS_MISSED_CAPTURE_DEADLINE"] = "ORDERS_MISSED_CAPTURE_DEADLINE";
    BotNodeId["ORDERS_EXPIRATION_DATE_SURPASSED"] = "ORDERS_EXPIRATION_DATE_SURPASSED";
    BotNodeId["ORDERS_EXPIRATION_DATE_SURPASSED_CREDIT"] = "ORDERS_EXPIRATION_DATE_SURPASSED_CREDIT";
    BotNodeId["ORDERS_MISSED_CAPTURE_DEADLINE_NO"] = "ORDERS_MISSED_CAPTURE_DEADLINE_NO";
    BotNodeId["ORDERS_REFUND_AN_ORDER"] = "ORDERS_REFUND_AN_ORDER";
    BotNodeId["ORDERS_FULLY_CAPTURED_YES"] = "ORDERS_FULLY_CAPTURED_YES";
    BotNodeId["ORDERS_FULLY_CAPTURED_NO"] = "ORDERS_FULLY_CAPTURED_NO";
    BotNodeId["ORDERS_FULLY_CAPTURED_PARTIALLY"] = "ORDERS_FULLY_CAPTURED_PARTIALLY";
})(BotNodeId || (BotNodeId = {}));
export var UxEvent;
(function (UxEvent) {
    UxEvent["GO_TO_HELP_HOME"] = "GO_TO_HELP_HOME";
    UxEvent["GO_TO_CHANNEL_SELECTION"] = "GO_TO_CHANNEL_SELECTION";
})(UxEvent || (UxEvent = {}));
export var RequestMethodEnum;
(function (RequestMethodEnum) {
    RequestMethodEnum[RequestMethodEnum["GET"] = 0] = "GET";
    RequestMethodEnum[RequestMethodEnum["POST"] = 1] = "POST";
})(RequestMethodEnum || (RequestMethodEnum = {}));
export var RegionEnum;
(function (RegionEnum) {
    RegionEnum["EU"] = "eu";
    RegionEnum["US"] = "us";
    RegionEnum["AP"] = "ap";
})(RegionEnum || (RegionEnum = {}));
