import React, { useEffect } from 'react';
import { useChannelDataState } from '../../context/ChannelDataStateProvider';
import ChannelSelectorContainer from './ChannelSelectorContainer';
import useContactChannels from '../../hooks/useContactChannels';
import FallbackLoader from '../FallbackLoader';
import { useChannelSelectorState } from '../../context/ChannelSelectorState';
import useIsChatActive from '../../hooks/useIsChatActive';
const ChannelSelectorPage = () => {
    const chatFeatures = useIsChatActive();
    const { setChatIsLoading, getIsChatLoading } = useChannelSelectorState();
    const { isLoading, data: { markets } } = useChannelDataState();
    const { navigateToCreateTicketPage, startChatConversation } = useContactChannels();
    const mustDisplayChannelSelector = () => !isLoading && markets.length > 0;
    const mustNavigateToCreateTicketPage = () => !isLoading && markets.length < 1;
    if (mustNavigateToCreateTicketPage())
        navigateToCreateTicketPage();
    useEffect(() => {
        if (markets.length === 1) {
            if (chatFeatures.getIsChatActive(markets[0].countryCode)) {
                setChatIsLoading(markets[0].countryCode);
                startChatConversation(markets[0].countryCode);
            }
            else {
                navigateToCreateTicketPage();
            }
        }
    }, [isLoading, markets]);
    const showLoader = isLoading || (markets.length === 1 && getIsChatLoading(markets[0].countryCode));
    return (React.createElement("div", null,
        showLoader && React.createElement(FallbackLoader, null),
        !showLoader && mustDisplayChannelSelector() && React.createElement(ChannelSelectorContainer, null)));
};
export default ChannelSelectorPage;
