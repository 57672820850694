import { useState } from 'react'
import useDebounce from './useDebounce'

export default function useDebouncedValue (value) {
  const [deboundedValue, setDebouncedValue] = useState(value)

  useDebounce(value, setDebouncedValue)

  return deboundedValue
}
