import { __awaiter } from "tslib";
const SHOWN_AT_LOCAL_STORAGE_KEY = 'mfa-modal-visibility';
const enableMFA = (emberClient, userId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield emberClient.put(`/users/${userId}`, {
        mfa: true
    });
});
const getMFAShownAt = (localStorage) => {
    const timestamp = localStorage.get(SHOWN_AT_LOCAL_STORAGE_KEY);
    if (timestamp) {
        return parseInt(timestamp);
    }
};
const setMFAShownAt = (localStorage) => {
    localStorage.set(SHOWN_AT_LOCAL_STORAGE_KEY, Date.now());
};
const MFAState = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
    UNKNOWN: 'UNKNOWN'
};
const getMFAState = (user, isMfaEnabled) => {
    if (!user) {
        return MFAState.UNKNOWN;
    }
    if (isMfaEnabled) {
        return MFAState.ENABLED;
    }
    return MFAState.DISABLED;
};
export { enableMFA, getMFAShownAt, getMFAState, MFAState, setMFAShownAt };
