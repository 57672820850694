import { MfaLockedButton } from 'mage-components';
import { hooks as i18nHooks } from 'mage-i18n';
import { TrackImpression } from 'mage-insights';
import React from 'react';
import useGenerateButton from './useGenerateButton';
export default function GenerateButton() {
    const { handleLockedClick, handleUnlockedClick, areActionsEnabled, event } = useGenerateButton();
    const t = i18nHooks.useTranslator();
    return (React.createElement(TrackImpression, { event: event },
        React.createElement(MfaLockedButton, { onUnlockedClick: handleUnlockedClick, onLockedClick: handleLockedClick, disabled: !areActionsEnabled, "data-testid": 'generate-clientid-button' }, t('home-fe.boosters-home-v03.client-identifier-section.generate-button'))));
}
