import React from 'react';
import { hooks } from 'mage-i18n';
import { Typography, SpacerVertical } from '@klarna/bubble-ui';
import ChannelSelector from './ChannelSelector';
const ChannelSelectorContainer = () => {
    const t = hooks.useTranslator();
    return (React.createElement("div", null,
        React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/tertiary' }, t('help-tool.marketSelector.whichMarketToContact')),
        React.createElement(SpacerVertical, { medium: true }),
        React.createElement(ChannelSelector, null)));
};
export default ChannelSelectorContainer;
