import React from 'react';
import { SelectorDirect, Loader } from '@klarna/bubble-ui';
import { hooks } from 'mage-i18n';
import useContactChannels from '../../hooks/useContactChannels';
import useIsChatActive from '../../hooks/useIsChatActive';
import { useChannelSelectorState } from '../../context/ChannelSelectorState';
const generateOption = (channel, t) => ({
    value: channel,
    label: t(`helpbot.contact.${channel}`)
});
const getChannelOptions = (isChatActive, t) => {
    const options = [];
    if (isChatActive) {
        options.push(generateOption('chat', t));
    }
    else {
        options.push(generateOption('ticket', t));
    }
    return options;
};
const MarketChannels = ({ marketCountryCode }) => {
    const t = hooks.useTranslator();
    const { navigateToCreateTicketPage, startChatConversation } = useContactChannels();
    const { getIsChatLoading, setChatIsLoading } = useChannelSelectorState();
    const chatFeatures = useIsChatActive();
    const isChatActive = chatFeatures.getIsChatActive(marketCountryCode);
    const options = getChannelOptions(isChatActive, t);
    const handleOnSelect = (channel) => {
        if (channel === 'chat') {
            startChatConversation(marketCountryCode);
            setChatIsLoading(marketCountryCode);
            return;
        }
        navigateToCreateTicketPage();
    };
    return (React.createElement(React.Fragment, null, getIsChatLoading(marketCountryCode)
        ? React.createElement(Loader, null)
        // @ts-ignore: FIXME
        : React.createElement(SelectorDirect, { onSelect: handleOnSelect, options: options })));
};
export default MarketChannels;
