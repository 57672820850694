import { ActionIconChevronRight, Badge, SpacerVertical, Typography } from '@klarna/bubble-ui'
import { useGetToken } from '@klarna/mp-ui'
import { AppIcon } from 'mage-components'
import { hooks as i18nHooks } from 'mage-i18n'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const ContainerStyled = styled.div(() => {
  const hoverBackgroundColor = useGetToken('colors/backgrounds/subtle-inline', { interactiveState: 'hovered' })
  const padding = useGetToken('space.300').value()
  const borderRadius = useGetToken('corner-radius/m').value()

  return css({
    borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding,

    ':hover': {
      backgroundColor: hoverBackgroundColor,
      cursor: 'pointer'
    }
  })
})

const HeaderStyled = styled.div(() => {
  const gap = useGetToken('space.200').value()

  return css({
    display: 'flex',
    gap,
    width: '100%',
    alignItems: 'center'
  })
})

function AppTile ({ onClick = () => {}, clientId, title, description, isNewApp }) {
  const t = i18nHooks.useTranslator()

  return (
    <ContainerStyled onClick={onClick}>
      <div>
        <HeaderStyled>
          <AppIcon clientId={clientId} />
          <div>
            <Typography textToken='text-style/text/labels/body/medium'>{title}</Typography>
          </div>
          {isNewApp && <Badge>{t('core.appIcon.newLabel')}</Badge>}
        </HeaderStyled>
        {description && (
          <>
            <SpacerVertical spaceToken='space.200' />
            <Typography textToken='text-style/text/labels/body/regular' colorToken='colors/text/neutral'>{description}</Typography>
          </>
        )}
      </div>
      <ActionIconChevronRight />
    </ContainerStyled>
  )
}

AppTile.propTypes = {
  clientId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func,
  isNewApp: PropTypes.bool
}

export default AppTile
