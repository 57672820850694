import { ParticleDesignVersions } from '@klarna/bubble-ui';
import { translations as configTranslations } from 'mage-config';
import { createState, translations as sharedTranslations } from 'mage-i18n';
export default (window, createAppState, translations = [], { sessionId } = {}) => (Object.assign({ config: window.config || {}, core: {
        clientId: window.clientId,
        slug: window.slug,
        realm: 'merchants',
        designVersion: ParticleDesignVersions.PANCAKE_LIGHT,
        sessionId
    }, i18n: createState(configTranslations, sharedTranslations, ...translations) }, createAppState(window)));
