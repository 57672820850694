import React from 'react'
import { SelectField } from 'formik-fields'
import PropTypes from 'prop-types'
import { components as coreComponents, selectors as coreSelectors } from 'mage-core'
import { translator } from 'mage-i18n'
import { connect, useSelector } from 'react-redux'
import * as R from 'ramda'
import { mapMidsToStoreSelectorOptions } from '../../utils'

function StoreSelector ({ mids, t }) {
  const merchants = useSelector(coreSelectors.getMerchants)
  const updatedOptions = mapMidsToStoreSelectorOptions(mids, merchants)
  const options = updatedOptions.map(option =>
    option.value !== option.label
      ? { ...option, label: `${option.label} (${option.value})`, key: option.value }
      : { ...option, key: option.value }
  )

  return (
    <SelectField
      data-testid='store-selector'
      name='mid'
      label={t('help-tool.support-page.create-ticket.fields.mid.label')}
      options={options}
    />
  )
}

StoreSelector.propTypes = {
  mids: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired
}

StoreSelector.defaultProps = {
  mids: []
}

export default R.compose(
  coreComponents.withTokenHelper(tokenHelper => ({
    mids: tokenHelper.getMids()
  })),
  connect(state => ({
    t: translator(state)
  }))
)(StoreSelector)
