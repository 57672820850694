import React from 'react'
import { hooks as i18nHooks } from 'mage-i18n'
import { SelectField } from 'formik-fields'

const topics = ['orders', 'settlements', 'agreements', 'others']

export default function TopicSelector () {
  const t = i18nHooks.useTranslator()

  const options = topics.map(topic => ({
    key: topic,
    label: t(`help-tool.support-page.create-ticket.fields.topic.options.${topic}`, {}, topic)
  }))

  return (
    <SelectField
      data-testid='topic-selector'
      name='topic'
      label={t('help-tool.support-page.create-ticket.fields.topic.label')}
      options={options}
    />
  )
}
