import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
import { RegionEnum } from '../components/SupportHelpBot/types';
const DEFAULT_REGIONS = [RegionEnum.EU, RegionEnum.AP, RegionEnum.US];
export const requestWrapper = (client, method, url, params, regions = DEFAULT_REGIONS) => (Promise.all(regions.map((region) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield makeRequest({ client, method, url, region, params });
    return {
        region,
        response
    };
}))));
const makeRequest = (_a) => __awaiter(void 0, [_a], void 0, function* ({ client, method, url, region, params }) {
    try {
        return yield client.request({
            method,
            url,
            region,
            params
        });
    }
    catch (err) {
        Sentry.captureException(err);
        throw err;
    }
});
