export default {
    chatWidget: {
        position: 'fixed',
        bottom: '80px',
        right: '40px',
        zIndex: 100
    },
    chatBox: {
        width: '600px',
        height: '800px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '10px',
        backgroundColor: '#fff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
    },
    chatCloseButton: {
        position: 'absolute',
        top: '-5%',
        right: '-5%'
    }
};
