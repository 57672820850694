import { Field } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
import CopyToClipboardButton from '../CopyToClipboardButton';
import useClientKeys from '../useClientKeys';
const CopyFieldContainer = styled.div `
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex-shrink: 0;
  position: relative;
  min-height: 0;
  min-width: 0;

  input {
    padding-right: 5em;
  }
`;
const ButtonContainer = styled.div `
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex-shrink: 0;
  position: absolute;
  min-height: 0;
  min-width: 0;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 4;
`;
function Core({ clientKey, error }) {
    var _a;
    const t = i18nHooks.useTranslator();
    const LABEL = t('credentials.clientKeyField.label');
    const DEFAULT_ERROR_MESSAGE = t('credentials.clientKeyField.defaultErrorMessage');
    return (React.createElement(CopyFieldContainer, null,
        React.createElement(Field, { label: error ? `${LABEL} - ${(_a = error.message) !== null && _a !== void 0 ? _a : DEFAULT_ERROR_MESSAGE}` : LABEL, value: clientKey !== null && clientKey !== void 0 ? clientKey : '', error: !!error, width: '100%' }),
        clientKey &&
            React.createElement(ButtonContainer, null,
                React.createElement(CopyToClipboardButton, { contentToCopy: clientKey }))));
}
function ClientKeyFieldWithHook({ mid, region }) {
    const { clientKey, error } = useClientKeys({ mid, region });
    return React.createElement(Core, { clientKey: clientKey !== null && clientKey !== void 0 ? clientKey : '', error: error });
}
function ClientKeyFieldWithValue({ clientKey }) {
    return React.createElement(Core, { clientKey: clientKey, error: null });
}
/**
 * This component is basically a wrapper around the Core component. It decides whether to use the hook or
 * the value passed as prop. It's a workaround to avoid using hooks inside conditionals
 */
export default function ClientKeyField({ mid, region, clientKey }) {
    if (mid && !clientKey) {
        return React.createElement(ClientKeyFieldWithHook, { mid: mid, region: region });
    }
    return React.createElement(ClientKeyFieldWithValue, { clientKey: clientKey !== null && clientKey !== void 0 ? clientKey : '' });
}
