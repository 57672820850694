import { __awaiter } from "tslib";
import { useHelpContext } from './';
import { initiateChat } from 'mage-conversation';
import { useChannelDataState } from '../context/ChannelDataStateProvider';
import { useChannelSelectorState } from '../context/ChannelSelectorState';
import { gatherCallbacks } from '../utilities/callback';
import { generateSidebarController } from '../utilities/sidebarController';
import { useChatContext } from './useChatContext';
import { useRefreshClientGenerator } from './useRefreshClientGenerator';
import useIsChatActive from './useIsChatActive';
import { hooks as i18nHooks } from 'mage-i18n';
import { useChatClient } from './useChatClient';
const useContactChannels = () => {
    useRefreshClientGenerator();
    // @ts-ignore
    const helpHook = useHelpContext();
    // @ts-ignore
    const { toggleWidget } = useHelpContext();
    const { data: channelData } = useChannelDataState();
    const { resetIsChatLoading } = useChannelSelectorState();
    const chatFeatures = useIsChatActive();
    const navigateToCreateTicketPage = () => {
        helpHook.navigation.push('support');
    };
    const sidebarController = generateSidebarController(toggleWidget);
    const context = useChatContext();
    const language = i18nHooks.useLanguage();
    const client = useChatClient();
    const closeViewport = () => sidebarController(false);
    const getChatContext = (marketCode, tag, amazonConnectEnabled) => {
        const region = chatFeatures.getWorkingHoursRegion(marketCode);
        const restoreIdByRegion = channelData.restoreId;
        const onChatWidgetOpens = gatherCallbacks([closeViewport, resetIsChatLoading]);
        const useAmazonConnect = amazonConnectEnabled || context.env === 'staging';
        return Object.assign(Object.assign({}, context), { region, market: marketCode, restoreIdByRegion,
            onChatWidgetOpens,
            language,
            tag, chatProvider: useAmazonConnect ? 'AMAZON_CONNECT' : 'FRESHCHAT' });
    };
    const getFreshchatTag = (marketCode) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield client.request({
            method: 'GET',
            url: `/v1/tags/${marketCode.toLocaleLowerCase()}`,
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    });
    const getIsAmazonConnectEnabled = (market) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield client.request({
            method: 'GET',
            url: `/v2/chat/is-enabled?market=${market}`,
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data.isEnabled;
    });
    const startChatConversation = (marketCode) => {
        Promise.all([
            getFreshchatTag(marketCode),
            getIsAmazonConnectEnabled(marketCode)
        ]).then(([tag, isAmazonConnectEnabled]) => {
            return initiateChat(getChatContext(marketCode, tag, isAmazonConnectEnabled));
        }).catch(() => {
            return initiateChat(getChatContext(marketCode));
        });
    };
    return {
        navigateToCreateTicketPage,
        startChatConversation
    };
};
export default useContactChannels;
