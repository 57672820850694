import React, { useEffect } from 'react';
import useIsChatActive from '../../../../hooks/useIsChatActive';
import useContactChannels from '../../../../hooks/useContactChannels';
import { useChannelSelectorState } from '../../../../context/ChannelSelectorState';
import { useChannelDataState } from '../../../../context/ChannelDataStateProvider';
import FallbackLoader from '../../../FallbackLoader';
import MarketOption from './MarketOption';
const MarketSelector = ({ markets, onMarketSelected }) => (React.createElement(React.Fragment, null, markets.map((code) => React.createElement(MarketOption, { key: code, countryCode: code, onClick: () => onMarketSelected(code) }))));
const MarketSelectorContainer = () => {
    const chatFeatures = useIsChatActive();
    const { setChatIsLoading, getIsChatLoading } = useChannelSelectorState();
    // @ts-ignore
    const { isLoading, data: { markets } } = useChannelDataState();
    const { navigateToCreateTicketPage, startChatConversation } = useContactChannels();
    const showLoader = () => isLoading || (markets.length === 1 && getIsChatLoading(markets[0].countryCode));
    const onMarketSelected = (countryCode) => {
        if (chatFeatures.getIsChatActive(countryCode)) {
            startChatConversation(countryCode);
            setChatIsLoading(countryCode);
            return;
        }
        navigateToCreateTicketPage();
    };
    useEffect(() => {
        if (isLoading)
            return;
        if (!markets.length)
            return navigateToCreateTicketPage();
        if (markets.length === 1) {
            onMarketSelected(markets[0].countryCode);
        }
    }, [isLoading, markets]);
    if (showLoader())
        return React.createElement(FallbackLoader, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(MarketSelector, { markets: markets.map((m) => m.countryCode), onMarketSelected: onMarketSelected }),
        getIsChatLoading(markets[0].countryCode) && React.createElement(FallbackLoader, null)));
};
export default MarketSelectorContainer;
