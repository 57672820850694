import { startConversationUserInfo } from './startConversationUserInfo';
import { startAmazonConnectConversation } from './amazon-connect';
export const initiateChat = (ctx) => {
    if (ctx.chatProvider === 'AMAZON_CONNECT') {
        startAmazonConnectConversation(ctx);
    }
    else {
        startConversationUserInfo(ctx);
    }
};
