import { usePageContext } from '@merchant-portal/framework';
import { hooks as i18nHooks } from 'mage-i18n';
import React, { useEffect } from 'react';
import AppList from './AppList';
import ExternalAppList from './ExternalAppList';
export default function MarketingHome() {
    const { setPageContext = () => { } } = usePageContext();
    const t = i18nHooks.useTranslator();
    useEffect(() => {
        setPageContext({
            title: t('core.apps.marketing-home-fe.title'),
            description: t('home-fe.marketing-home.description')
        });
    }, [t, setPageContext]);
    return (React.createElement("div", { id: 'marketing-home' },
        React.createElement(ExternalAppList, null),
        React.createElement(AppList, null)));
}
