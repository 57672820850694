import React from 'react'
import { TitleSecondary, SpacerVertical } from '@klarna/bubble-ui'
import { Message } from 'mage-i18n'
import { useHelpContext } from '../hooks'
import AllTopicsList from './Topic/AllTopicsList'

function HomePage () {
  const { topics } = useHelpContext()

  return (
    <div>
      <TitleSecondary>
        <Message path='' fallback='What do you need help with?' />
      </TitleSecondary>
      <SpacerVertical small />
      <AllTopicsList topics={topics} />
    </div>
  )
}

export default HomePage
