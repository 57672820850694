import { selectors as experimentationSelectors } from '@merchant-portal/experimentation';
import { hooks as coreHooks } from 'mage-core';
import { useSelector } from 'react-redux';
const useIsMfaLocked = () => {
    const isDeeplinkMfaFlowEnabled = useSelector(experimentationSelectors.isFeatureToggleEnabled('merchant-portal.deep-link-2fa-flow.enabled'));
    // check token's MF-authentication status
    const tokenHelper = coreHooks.useTokenHelper();
    const authStatus = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getAuthenticationStatus();
    const isMfAuthenticated = (authStatus === null || authStatus === void 0 ? void 0 : authStatus.password) === 'AUTHENTICATED' && (authStatus === null || authStatus === void 0 ? void 0 : authStatus.mfa) === 'AUTHENTICATED';
    const isMfaLocked = isDeeplinkMfaFlowEnabled && !isMfAuthenticated;
    return isMfaLocked;
};
export default useIsMfaLocked;
