import React from 'react'
import PropTypes from 'prop-types'
import { useCMSEntries } from 'mage-cms'
import { useDebouncedValue } from '../hooks'
import RelatedContent from './RelatedContent'

QueryRelatedContent.propTypes = {
  title: PropTypes.string.isRequired,
  analyticsCategory: PropTypes.string.isRequired,
  query: PropTypes.string
}

export default function QueryRelatedContent ({ title, analyticsCategory, query }) {
  const debouncedQuery = useDebouncedValue(query)

  const queryMissing = !debouncedQuery || debouncedQuery === ''

  const articles = useCMSEntries({
    contentType: 'UHQMArticle',
    filters: { query: debouncedQuery, 'hidden[ne]': true },
    skip: queryMissing
  })

  return <RelatedContent title={title} articles={articles} analyticsCategory={analyticsCategory} />
}
