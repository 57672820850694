import { useGetToken } from '@klarna/mp-ui';
export const useDesktop = () => {
    const breakpoint = useGetToken('breakpoint/l').value();
    return `@media screen and (min-width: ${breakpoint}px)`;
};
export const useTablet = () => {
    const breakpoint = useGetToken('breakpoint/m').value();
    return `@media screen and (min-width: ${breakpoint}px)`;
};
export const useMobile = () => {
    const breakpoint = useGetToken('breakpoint/s').value();
    return `@media screen and (min-width: ${breakpoint}px)`;
};
