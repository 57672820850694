import * as c from './constants'
import { getDataFromSessionStorage, storeDataIntoSession } from './utils/sessionStorage'
import { CHAT_SESSION_STORAGE_KEY } from './constants'
import { safeClearInterval } from './utils/intervalProcess'

const CHAT_INIT_INTERVAL = 100
const RESTORE_MAX_TIME = 15 * 1000

const openChatFromOptions = (options) => {
  window.fcWidget.init(options)
  window.fcWidget.open()
}

const isFreshchatReady = () => Boolean(window.fcWidget)
const isThereActiveChat = (storedData) => Boolean(storedData?.widgetOptions)

const cleanUpSessionDataOnClose = () => {
  window.fcWidget.on('widget:closed', () => {
    window.fcWidget.destroy()
    cleanChatDataSession()
  })
}

const restoreChatIfAnyActiveSession = () => {
  const data = getDataFromSessionStorage(CHAT_SESSION_STORAGE_KEY)
  if (isThereActiveChat(data)) {
    openChatFromOptions(data.widgetOptions)
    cleanUpSessionDataOnClose()
  }
}

export const intervalCallback = (intervalProcess) => {
  if (!isFreshchatReady()) return
  safeClearInterval(intervalProcess)
  restoreChatIfAnyActiveSession()
}

export const storeChatDataSession = (data) => {
  storeDataIntoSession({ key: c.CHAT_SESSION_STORAGE_KEY, data })
}
export const cleanChatDataSession = () => {
  storeDataIntoSession({ key: c.CHAT_SESSION_STORAGE_KEY, data: {} })
}

(function () {
  const intervalProcess = setInterval(
    () => intervalCallback(intervalProcess), CHAT_INIT_INTERVAL
  )
  setTimeout(() => safeClearInterval(intervalProcess), RESTORE_MAX_TIME)
})()
