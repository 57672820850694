import React from 'react';
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage';
import { BotNodeId } from '../../../types';
import { INeedMoreHelpThatHelped } from '../../common';
export const entrypoint = {
    value: 'helpbot.orders.refundIssues.title',
    next: BotNodeId.ORDER_REFUND_ISSUES
};
export const flow = [
    {
        id: BotNodeId.ORDER_REFUND_ISSUES,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.orders.orderManagement.refundOrder.question',
                next: BotNodeId.ORDER_MANAGEMENT_REFUND_ORDER
            },
            {
                value: 'helpbot.orders.refundIssues.refundedOrderWhenCustomerFunds.question',
                next: BotNodeId.ORDER_REFUND_ISSUES_REFUNDED_WHEN_CUSTOMER_GET_FUNDS
            }
        ]
    },
    {
        id: BotNodeId.ORDER_REFUND_ISSUES_REFUNDED_WHEN_CUSTOMER_GET_FUNDS,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.orders.refundIssues.refundedOrderWhenCustomerFunds.answer' })),
        options: () => INeedMoreHelpThatHelped
    }
];
