export const COLORS = {
    Salmiak: {
        red: 0x17,
        green: 0x17,
        blue: 0x17,
        alpha: 1
    },
    Pumice: {
        red: 0x96,
        green: 0x93,
        blue: 0x91,
        alpha: 1
    },
    Ming: {
        red: 0x48,
        green: 0x7B,
        blue: 0x94,
        alpha: 1
    },
    Flint: {
        red: 0x78,
        green: 0x75,
        blue: 0x73,
        alpha: 1
    },
    Cardinal: {
        red: 0xD4,
        green: 0x37,
        blue: 0x31,
        alpha: 1
    },
    Scarlet: {
        red: 0xF7,
        green: 0x4B,
        blue: 0x45,
        alpha: 1
    },
    Ballet: {
        red: 0xFF,
        green: 0xF6,
        blue: 0xF5,
        alpha: 1
    }
};
