import cs from './cs_CZ.json'
import da from './da_DK.json'
import de from './de_DE.json'
import el from './el_GR.json'
import en from './en_US.json'
import es from './es_ES.json'
import fi from './fi_FI.json'
import fr from './fr_FR.json'
import it from './it_IT.json'
import nl from './nl_NL.json'
import no from './no_NO.json'
import pl from './pl_PL.json'
import pt from './pt_PT.json'
import ro from './ro_RO.json'
import sv from './sv_SE.json'

export default {
  cs,
  da,
  de,
  el,
  en,
  es,
  fi,
  fr,
  it,
  nl,
  no,
  pl,
  pt,
  ro,
  sv
}
