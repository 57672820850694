import { hooks as coreHooks } from 'mage-core';
import { selectors as stateSelectors } from 'mage-state';
import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useApiKey } from './useApiKey';
import { useClientId } from './useClientId';
import { useMid } from './useMid';
import { getMustDisplayBoostApp } from './utils/getMustdDisplayBoostApp';
const IS_SIWK_ENABLED_FOR_PLUGINS = false;
const KEC_APP_ID = 'kec-fe';
const OSM_APP_ID = 'upstream-fe';
const SIWK_APP_ID = 'siwk-fe';
export const ActiveBoostProductsContext = createContext(undefined);
export const ActiveBoostProductsProvider = ({ children }) => {
    const tokenHelper = coreHooks.useTokenHelper();
    const { selectedMid } = useMid();
    const { clientIdToken } = useClientId();
    const { hasAlreadyActiveKeys } = useApiKey();
    const getApps = useSelector(stateSelectors.getUserAccessibleApps);
    const availableApps = getApps(tokenHelper) || [];
    const permissions = getMidPermissions(selectedMid, tokenHelper);
    const input = {
        selectedMid,
        clientId: clientIdToken,
        hasKeys: hasAlreadyActiveKeys,
        availableApps,
        permissions
    };
    const mustDisplayOSM = getMustDisplayBoostApp(Object.assign(Object.assign({}, input), { appId: OSM_APP_ID }));
    const mustDisplayKEC = getMustDisplayBoostApp(Object.assign(Object.assign({}, input), { appId: KEC_APP_ID }));
    const mustDisplaySIWK = getMustDisplayBoostApp(Object.assign(Object.assign({}, input), { appId: SIWK_APP_ID })) && IS_SIWK_ENABLED_FOR_PLUGINS;
    const activeBoostProducts = {
        mustDisplayOSM,
        mustDisplayKEC,
        mustDisplaySIWK,
        areCredentialsReady: hasAlreadyActiveKeys && !!clientIdToken
    };
    return (React.createElement(ActiveBoostProductsContext.Provider, { value: activeBoostProducts }, children));
};
const getMidPermissions = (selectedMid, tokenHelper) => {
    if (!tokenHelper || !selectedMid) {
        return {};
    }
    return tokenHelper.getAllPermissionsForMid(selectedMid);
};
export const useActiveBoostProducts = () => {
    const value = useContext(ActiveBoostProductsContext);
    if (!value)
        throw new Error('cannot get ActiveBoostProductsContext as not been provided');
    return value;
};
