import React from 'react';
import { IconHamburger, TouchableIcon } from '@klarna/bubble-ui';
import { useGetToken } from '@klarna/mp-ui';
import { useToggleSidebarHandler } from './SidebarContext';
import styled, { css } from 'styled-components';
import { zIndex } from '../constants';
import { useDesktop, useTablet } from '../../../hooks/useMediaQuery';
const WrapperStyled = styled.div(() => {
    const desktopMediaQuery = useDesktop();
    const tabletMediaQuery = useTablet();
    const topbarHeight = useGetToken('space.800').value();
    const horizontalPadding = useGetToken('space.300').value();
    return css({
        alignItems: 'center',
        display: 'inline-flex',
        height: topbarHeight,
        left: horizontalPadding,
        pointerEvents: 'all',
        position: 'fixed',
        top: '0',
        zIndex: zIndex.sidebar.hamburgerMenu,
        [tabletMediaQuery]: {
            position: 'relative'
        },
        [desktopMediaQuery]: {
            display: 'none'
        }
    });
});
function HambugerMenu() {
    const toogle = useToggleSidebarHandler();
    return (React.createElement(WrapperStyled, { "data-testid": 'topbar-menubutton' },
        React.createElement(TouchableIcon, { onClick: toogle, Icon: IconHamburger })));
}
export default HambugerMenu;
