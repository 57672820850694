import PropTypes from 'prop-types';
import React from 'react';
import FieldTranslationContext from '../contexts/FieldTranslationContext';
FieldTranslationProvider.propTypes = {
    namespace: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    children: PropTypes.node
};
export default function FieldTranslationProvider({ namespace, suffix, children }) {
    return (React.createElement(FieldTranslationContext.Provider, { value: { namespace, suffix } }, children));
}
