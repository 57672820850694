import './CodeSnippet.css';
import React from 'react';
import Code from './Code';
function CodeSnippetNoCopy({ code, options = {
    mode: 'htmlmixed',
    lineNumbers: true,
    readOnly: true,
    viewportMargin: Infinity,
    lineWrapping: true
} }) {
    return (React.createElement("div", { className: 'code-snippet-no-copy' },
        React.createElement(Code, { code: code, options: {
                mode: (options === null || options === void 0 ? void 0 : options.mode) || 'htmlmixed',
                lineNumbers: !!(options === null || options === void 0 ? void 0 : options.lineNumbers),
                readOnly: !!(options === null || options === void 0 ? void 0 : options.readOnly),
                viewportMargin: (options === null || options === void 0 ? void 0 : options.viewportMargin) || Infinity,
                lineWrapping: !!(options === null || options === void 0 ? void 0 : options.lineWrapping)
            } })));
}
export default CodeSnippetNoCopy;
