export const PLUGINS = [
    {
        id: 'adobe-commerce',
        name: 'Adobe Commerce',
        logo: {
            imageName: 'adobe.svg',
            height: '20px'
        },
        docUrl: 'https://docs.klarna.com/platform-solutions/e-commerce-platforms/adobe-commerce/before-you-start/?utm_source=merchant%20portal',
        pluginLink: 'https://commercemarketplace.adobe.com/klarna-m2-klarna.html',
        kecDocsLink: 'https://docs.klarna.com/platform-solutions/e-commerce-platforms/adobe-commerce/conversion-boosters/express-checkout/?utm_source=merchant%20portal#customize-the-express-checkout-button'
    },
    {
        id: 'woo-commerce',
        name: 'WooCommerce',
        logo: {
            imageName: 'woo.svg',
            height: '24px'
        },
        docUrl: 'https://docs.klarna.com/platform-solutions/e-commerce-platforms/woocommerce/before-you-start/?utm_source=merchant%20portal',
        pluginLink: 'https://woocommerce.com/products/klarna-payments/',
        kecDocsLink: 'https://docs.klarna.com/platform-solutions/e-commerce-platforms/woocommerce/conversion-boosters/how-to-install-express-checkout-in-woocommerce/?utm_source=merchant%20portal#customize-the-express-checkout-button'
    },
    {
        id: 'prestashop',
        name: 'PrestaShop',
        logo: {
            imageName: 'prestashop.svg',
            height: '24px'
        },
        docUrl: 'https://docs.klarna.com/platform-solutions/e-commerce-platforms/prestashop/before-you-start/?utm_source=merchant%20portal',
        pluginLink: 'https://addons.prestashop.com/en/other-payment-methods/43440-klarna-payments-official.html',
        kecDocsLink: 'https://docs.klarna.com/platform-solutions/e-commerce-platforms/prestashop/conversion-boosters/how-to-install-express-checkout-in-prestashop/?utm_source=merchant%20portal#customize-the-express-checkout-button'
    },
    {
        id: 'salesforce-commerce',
        name: 'Salesforce Commerce Cloud',
        logo: {
            imageName: 'salesforce.svg',
            height: '24px'
        },
        docUrl: 'https://docs.klarna.com/platform-solutions/e-commerce-platforms/salesforce-commerce-cloud/before-you-start/?utm_source=merchant%20portal',
        pluginLink: 'https://github.com/klarna/sfcc-klarna-payments',
        kecDocsLink: 'https://docs.klarna.com/platform-solutions/e-commerce-platforms/salesforce-commerce-cloud/conversion-boosters/express-checkout/?utm_source=merchant%20portal#configuration'
    },
    {
        id: 'sap-commerce',
        name: 'SAP Commerce',
        logo: {
            imageName: 'sap.png',
            height: '16px'
        },
        docUrl: 'https://github.com/klarna/SAP-Commerce-klarna-payments/tree/master/docs',
        pluginLink: 'https://github.com/klarna/SAP-Commerce-klarna-payments',
        kecDocsLink: '/kec/installation'
    }
];
