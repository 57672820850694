import { path } from 'ramda'
import { useFeatures } from '@merchant-portal/experimentation'

export default function useFilterArticlesByExperiment (articles) {
  const features = useFeatures()

  if (!features || !articles) return null

  return articles.filter(filterByActiveExperiments(features))
}

const filterByActiveExperiments = features => article => {
  if (article.experimentationFeature) {
    const activeVariate = path([article.experimentationFeature, 'variate_id'], features)

    if (activeVariate) {
      return activeVariate === article.experimentationVariate
    } else {
      return article.experimentationFallback
    }
  } else {
    return true
  }
}
