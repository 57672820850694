export const getCountryCodesAndRegions = (regionalResponses) => (regionalResponses.map(mapCountryCodesAndRegion).flat().filter(markets => markets));
export const getCountryCodes = (countryCodesAndRegions) => (countryCodesAndRegions
    ? countryCodesAndRegions.map(countryCodeAndRegion => countryCodeAndRegion.countryCode)
    : []);
export const getCountryCodesAndFeatureData = (regionalResponses) => (regionalResponses.map(mapCountryCodesAndFeatureData).flat()
    .filter(markets => markets));
export const mergeAndGetMarkets = (countryCodesAndRegions, countryCodesAndFeatureData) => {
    if (!countryCodesAndRegions)
        return [];
    const mergedMarkets = [];
    countryCodesAndRegions.forEach((value, index) => (mergedMarkets.push(Object.assign(Object.assign({ isChatFeatureEnabled: false }, countryCodesAndRegions[index]), (countryCodesAndFeatureData === null || countryCodesAndFeatureData === void 0 ? void 0 : countryCodesAndFeatureData.find((innerIndex) => innerIndex.countryCode === countryCodesAndRegions[index].countryCode))))));
    return mergedMarkets;
};
const mapCountryCodesAndRegion = ({ region, response }) => (response.data.map((countryCode) => ({ region, countryCode })));
const mapCountryCodesAndFeatureData = ({ response }) => (response.data.map(
// eslint-disable-next-line camelcase
({ market, is_feature_enabled }) => ({ countryCode: market, isChatFeatureEnabled: is_feature_enabled })));
