import React from 'react'
import { useCMSEntries } from 'mage-cms'
import TopicList from './TopicList'

export default function AllTopicsList () {
  const topics = useCMSEntries({ contentType: 'UHQMTopic' })

  if (!topics) return null

  return (
    <TopicList topics={topics} analyticsCategory='uhqm/topic-title/all' limit={6} />
  )
}
