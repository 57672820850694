import { __awaiter } from "tslib";
import { useTabVisibility } from '@klarna/mp-ui';
import { selectors as coreSelectors } from 'mage-core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createAction } from 'redux-actions';
const requestData = (client, url, { method, data, params }) => client.request({
    data,
    method,
    params,
    url
});
const INIT = 'data-provider/INIT';
const SUCCESS = 'data-provider/SUCCESS';
const FAILURE = 'data-provider/FAILURE';
const initRequest = createAction(INIT);
const widgetSuccess = createAction(SUCCESS);
const widgetFailure = createAction(FAILURE);
const dataProviderReducer = (state, action) => {
    switch (action.type) {
        case INIT:
            return Object.assign(Object.assign({}, state), { isFetching: true, isError: false });
        case SUCCESS:
            return Object.assign(Object.assign({}, state), { hasSuccessfulLoad: true, isFetching: false, isError: false, data: action.payload });
        case FAILURE:
            return Object.assign(Object.assign({}, state), { isFetching: false, isError: true });
        default:
            return state;
    }
};
export default function DataProvider({ autoRefreshInterval, backendName, cache, children, isContentLoading, method, onError, onLoading, params, query, url, showLoaderOnInitialRequestOnly = false }) {
    const [cachedData, setCachedData] = useState({});
    const createBackendClient = useSelector(coreSelectors.createBackendClient);
    const client = createBackendClient(backendName);
    const isVisible = useTabVisibility();
    // This makes it 100% self contained. If we want it in the store we need to change this
    // bit and add the reducer to the main store reducers.
    const [state, dispatch] = React.useReducer(dataProviderReducer, {
        isFetching: false,
        isError: false
    });
    const { data, isFetching, isError, hasSuccessfulLoad } = state;
    useEffect(() => {
        let didCancel = false;
        const fetchData = () => __awaiter(this, void 0, void 0, function* () {
            const cacheKey = `${backendName}_${url}_${JSON.stringify(query)}_${JSON.stringify(params)}`;
            if (isVisible) {
                if (cache && cachedData[cacheKey]) {
                    dispatch(widgetSuccess(cachedData[cacheKey]));
                }
                else {
                    dispatch(initRequest());
                }
                try {
                    const result = yield requestData(client, url, { method, data: query, params });
                    if (!didCancel) {
                        dispatch(widgetSuccess(result.data));
                        if (cache) {
                            setCachedData(Object.assign(Object.assign({}, cachedData), { [cacheKey]: result.data }));
                        }
                    }
                }
                catch (error) {
                    if (!didCancel) {
                        dispatch(widgetFailure());
                    }
                }
            }
        });
        if (autoRefreshInterval) {
            const refreshInterval = setInterval(() => {
                fetchData();
            }, autoRefreshInterval * 1000);
            fetchData();
            return () => {
                didCancel = true;
                clearInterval(refreshInterval);
            };
        }
        else {
            fetchData();
        }
        return () => {
            didCancel = true;
        };
    }, [client, query, params, isVisible]);
    const loadingValue = isFetching || isContentLoading;
    // Only show loader the first time
    if (loadingValue && hasSuccessfulLoad && showLoaderOnInitialRequestOnly) {
        return children({ data, isLoading: loadingValue });
    }
    if (loadingValue && onLoading) {
        return onLoading();
    }
    if (isError && onError) {
        return onError();
    }
    return children({ data, isLoading: loadingValue, isError });
}
DataProvider.propTypes = {
    autoRefreshInterval: PropTypes.number,
    backendName: PropTypes.string.isRequired,
    cache: PropTypes.bool,
    children: PropTypes.func,
    isContentLoading: PropTypes.bool,
    method: PropTypes.oneOf(['GET', 'POST']).isRequired,
    onError: PropTypes.func,
    onLoading: PropTypes.func,
    params: PropTypes.object,
    query: PropTypes.object,
    url: PropTypes.string.isRequired,
    showLoaderOnInitialRequestOnly: PropTypes.bool
};
DataProvider.defaultProps = {
    cache: false,
    isContentLoading: false,
    method: 'GET',
    showLoaderOnInitialRequestOnly: false
};
