import { curry, fromPairs, map, mapObjIndexed, path, pathEq, pickBy, pipe } from 'ramda';
import useCMSContext from './useCMSContext';
import useCMSRequest from './useCMSRequest';
export default function useCMSEntries({ contentType, sortField, filters, include = 1, skip = false }) {
    const { contentTypesResponse } = useCMSContext();
    const response = useCMSEntriesRequest(contentType, sortField, filters, include, skip);
    const transformedEntries = transform(contentTypesResponse, response, include);
    return transformedEntries;
}
const getContentTypeId = path(['sys', 'contentType', 'sys', 'id']);
function itemData(contentTypes, response, remainingDepth, item) {
    const data = Object.assign({ id: item.sys.id, contentType: getContentTypeId(item) }, item.fields);
    return remainingDepth === 0
        ? data
        : Object.assign(Object.assign({}, data), linkedData(contentTypes, response, remainingDepth - 1, item.fields));
}
const isTranslated = curry((contentTypes, entry) => {
    const requiredFields = contentTypes
        .find(contentType => contentType.sys.id === entry.contentType)
        .fields.filter(field => field.required)
        .map(field => field.id);
    return requiredFields.every(field => entry[field]);
});
function transform(contentTypesResponse, response, include) {
    if (!contentTypesResponse || !response) {
        return null;
    }
    const contentTypes = contentTypesResponse.items;
    return response.items
        .map(item => (Object.assign(Object.assign({}, itemData(contentTypes, response, include, item)), documentFields(contentTypes, response, item.fields))))
        .filter(isTranslated(contentTypes));
}
function linkedData(contentTypes, response, remainingDepth, fields) {
    return mapObjIndexed(value => Array.isArray(value)
        ? value
            .map(item => linkedItemIfReference(contentTypes, response, remainingDepth, true, item))
            .filter(Boolean)
        : linkedItemIfReference(contentTypes, response, remainingDepth, false, value), fields);
}
function linkedItemIfReference(contentTypes, response, remainingDepth, checkTranslation, value) {
    const isLink = pathEq('Link', ['sys', 'type'], value);
    if (isLink) {
        const linkedItem = getLinkedItem(contentTypes, response, remainingDepth, value.sys.linkType, value.sys.id);
        if (!linkedItem) {
            // Entry is unpublished
            return null;
        }
        if (checkTranslation && !isTranslated(contentTypes, linkedItem)) {
            // Entry is missing translation
            return null;
        }
        return linkedItem;
    }
    else {
        return value;
    }
}
function getLinkedItem(contentTypes, response, remainingDepth, linkType, id) {
    let entries = [];
    if (response.items && response.includes && response.includes[linkType]) {
        entries = response.items.concat(response.includes[linkType]);
    }
    else if (!response.items && response.includes && response.includes[linkType]) {
        entries = response.includes[linkType];
    }
    else if (response.items) {
        entries = response.items;
    }
    const entry = entries.find(entry => entry.sys.id === id);
    return entry ? itemData(contentTypes, response, remainingDepth, entry) : null;
}
function documentFields(contentTypes, response, fields) {
    return pipe(pickBy(value => value.nodeType === 'document'), mapValues(value => linkContentItem(contentTypes, response, value)))(fields);
}
function linkContent(contentTypes, response, content) {
    return content.map(contentItem => linkContentItem(contentTypes, response, contentItem));
}
function linkContentItem(contentTypes, response, contentItem) {
    return Object.assign(Object.assign(Object.assign({}, contentItem), (contentItem.content
        ? { content: linkContent(contentTypes, response, contentItem.content) }
        : {})), { data: Object.assign(Object.assign({}, contentItem.data), (['entry-hyperlink', 'asset-hyperlink', 'embedded-asset-block'].includes(contentItem.nodeType)
            ? {
                target: getLinkedItem(contentTypes, response, 0, contentItem.data.target.sys.linkType, contentItem.data.target.sys.id)
            }
            : {})) });
}
function useCMSEntriesRequest(contentType, sortField, filters, include, skip) {
    const filterFields = pipe(mapKeys(toFilterParameter), mapValues(value => (Array.isArray(value) ? value.join(',') : value)))(filters || {});
    return useCMSRequest(`/entries`, Object.assign(Object.assign({ select: 'fields,sys.id,sys.contentType', content_type: contentType, include }, filterFields), (sortField && { order: `fields.${sortField}` })), skip);
}
function toFilterParameter(key) {
    const reservedFields = ['links_to_entry', 'query'];
    const sysFields = ['id'];
    if (reservedFields.includes(key)) {
        return key;
    }
    else if (sysFields.includes(key)) {
        return `sys.${key}`;
    }
    else {
        return `fields.${key}`;
    }
}
const mapKeys = iterator => pipe(Object.entries, map(([key, value]) => [iterator(key), value]), fromPairs);
const mapValues = iterator => pipe(Object.entries, map(([key, value]) => [key, iterator(value)]), fromPairs);
