import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import AppCard from '../../../../../components/AppCard';
import CardContainer from '../../../../../components/AppCard/CardContainer';
import { B2B_COLORS } from '../../../../../constants';
import useUserAccessibleApps from '../../../../../hooks/useUserAccessibleApps';
import brandingImage from '../../../assets/branding-cover.png';
import expressCheckoutCoverImage from '../../../assets/kec-cover.png';
import onSiteMessagingCoverImage from '../../../assets/on-site-messaging-cover.png';
import signInWithKlarnaCoverImage from '../../../assets/siwk-cover.png';
function BoosterAppList() {
    const t = i18nHooks.useTranslator();
    const boosterApps = {
        'kec-fe': {
            backgroundColor: B2B_COLORS.Peach,
            imageUrl: expressCheckoutCoverImage,
            sortOrder: 2
        },
        'siwk-fe': {
            backgroundColor: B2B_COLORS.Lavender,
            imageUrl: signInWithKlarnaCoverImage,
            sortOrder: 3
        },
        'upstream-fe': {
            backgroundColor: B2B_COLORS.Sky,
            imageUrl: onSiteMessagingCoverImage,
            sortOrder: 1
        },
        'branding-fe': {
            backgroundColor: B2B_COLORS.Vanilla,
            imageUrl: brandingImage,
            sortOrder: 4
        }
    };
    const userAccessibleBoosterApps = useUserAccessibleApps(Object.keys(boosterApps));
    const apps = userAccessibleBoosterApps
        .map(app => (Object.assign({ title: t(`home-fe.boosters-home.booster-apps.${app.clientId}.title`), description: t(`home-fe.boosters-home.booster-apps.${app.clientId}.description`), callToAction: t(`home-fe.boosters-home.booster-apps.${app.clientId}.callToAction`), clientId: app.clientId, url: app.url }, boosterApps[app.clientId])))
        .sort((a, b) => a.sortOrder - b.sortOrder);
    if (apps.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
        React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/grande' }, t('home-fe.boosters-home.booster-apps.title')),
        React.createElement(SpacerVertical, { spaceToken: 'space.300' }),
        React.createElement(CardContainer, null, apps.map(app => (React.createElement(AppCard, { key: app.clientId, title: app.title, description: app.description, imageUrl: app.imageUrl, backgroundColor: app.backgroundColor, clientId: app.clientId, url: app.url, buttonLabel: app.callToAction }))))));
}
export default BoosterAppList;
