import React, { useContext, useState } from 'react';
import Notifications from '../Notifications';
const NotificationsContext = React.createContext({});
/**
 * Context used for displaying notifications to the user, such as Toast messages
 */
const useNotificationsContext = () => {
    return useContext(NotificationsContext);
};
/**
 * Context that exposes a set of functions related to showing
 * notifications to the users, such as Toast messages.
 */
const NotificationsContextProvider = ({ children }) => {
    const [notificationFunctions, setNotificationFunctions] = useState(null);
    return (React.createElement(NotificationsContext.Provider, { value: notificationFunctions },
        React.createElement(Notifications, { setNotificationFunctions: setNotificationFunctions }),
        notificationFunctions ? children : null));
};
export { useNotificationsContext, NotificationsContextProvider };
// Allows storybook to get props
export const NotificationsContextPropsDoc = () => null;
