import { LOCATION_CHANGE } from 'connected-react-router';
import dotProp from 'dot-prop-immutable';
import { actions as coreActions, selectors as coreSelectors } from 'mage-core';
// NOTE: the path needs to be specific, to avoid a circular dependency
import * as i18nSelectors from 'mage-i18n/src/selectors';
import * as constants from './constants';
export default insights => store => next => action => {
    const { type: actionType = '', meta: actionMeta = {} } = action;
    const realm = coreSelectors.keycloakRealm(store.getState());
    // @ts-ignore: coreActions type not specific enough
    if (actionType === coreActions.keycloak.realm.set.toString()) {
        insights.setRealm(action.payload);
    }
    // @ts-ignore: coreActions type not specific enough
    if (actionType === coreActions.requester.fetch.toString() && !action.error) {
        insights.setUser(action.payload);
    }
    if (actionType === LOCATION_CHANGE) {
        const { location } = action.payload;
        const { pathname, search } = dotProp.get(action, 'meta.customPageView', location);
        insights.pageview(pathname, search);
    }
    if (actionType.endsWith('_FAILED') || action.error) {
        insights.logException(action);
    }
    if (constants.INSIGHTS_EVENT in actionMeta) {
        const language = i18nSelectors.language(store.getState());
        insights.event(Object.assign({ customData: {
                user_language: language,
                user_realm: realm
            } }, actionMeta[constants.INSIGHTS_EVENT]));
    }
    return next(action);
};
