import { Separator, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { hooks as i18nHooks, Message, selectors as i18nSelectors } from 'mage-i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import DataProvider from './DataProvider';
import { ErrorComponent } from './ErrorComponent';
import LoadingComponent from './Loader';
const styles = {
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    fadeOut: {
        transition: 'opacity 1.5s cubic-bezier(0,.5,0,.25), filter 6s ease-out',
        opacity: 0.1,
        filter: 'blur(2px)'
    },
    fadeIn: {
        opacity: 1,
        filter: 'none'
    }
};
const Container = styled.div(({ isWide, width }) => {
    if (isWide) {
        return css({
            display: 'flex',
            flexDirection: 'column',
            width
        });
    }
    return css({
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '50px'
    });
});
const ValueWrapper = styled.span(({ isLoading }) => {
    if (isLoading) {
        return css({
            transition: 'opacity 1.5s cubic-bezier(0,.5,0,.25), filter 6s ease-out',
            opacity: 0.1,
            filter: 'blur(2px)'
        });
    }
    return css({
        opacity: 1,
        filter: 'none'
    });
});
const WidgetValue = ({ i18nPath, loading, value, isWide, width }) => {
    return (React.createElement(Container, { isWide: isWide, width: width },
        React.createElement(ValueWrapper, { isLoading: loading }, isWide
            ? (React.createElement(React.Fragment, null,
                React.createElement(SpacerVertical, { spaceToken: 'space.200' }),
                React.createElement(Typography, { textToken: 'text-style/headings/blocks/bold/tertiary' }, value),
                React.createElement(SpacerVertical, { spaceToken: 'space.100' }))) : (React.createElement(Typography, { textToken: 'text-style/text/labels/body/medium' }, value))),
        React.createElement(Typography, { textToken: 'text-style/text/labels/body/regular' },
            React.createElement(Message, { path: i18nPath }))));
};
WidgetValue.propTypes = {
    i18nPath: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isWide: PropTypes.bool,
    loading: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export const SalesThisWeekWidget = ({ isWidgetLoading, queryParams, currency, isWide }) => {
    const t = i18nHooks.useTranslator();
    const currencyFormatter = useSelector(state => i18nSelectors.formatters.currency(state)(currency, true));
    return (React.createElement(DataProvider, { backendName: 'orders', url: 'aggregated-sales-statistics', params: queryParams, showLoaderOnInitialRequestOnly: true, isContentLoading: isWidgetLoading, autoRefreshInterval: 45, cache: true, onError: () => (React.createElement(ErrorComponent, { errorText: t('home-fe.insightsWidget.error.content') })), onLoading: () => (React.createElement(LoadingComponent, null)) }, ({ data, isLoading }) => {
        const orderCount = (data === null || data === void 0 ? void 0 : data.count) || '-';
        const orderVolume = (data === null || data === void 0 ? void 0 : data.total_amount) / 100 || '-';
        const orderVolumeAverage = (data === null || data === void 0 ? void 0 : data.average_amount) / 100 || '-';
        return (React.createElement("div", { "data-testid": 'insights_widget', style: isWide ? styles.row : styles.column },
            React.createElement(WidgetValue, { value: orderCount, loading: isLoading, i18nPath: 'home-fe.insightsWidget.orderCount', isWide: isWide, width: '30%' }),
            !isWide && React.createElement(Separator, null),
            React.createElement(WidgetValue, { value: typeof orderVolumeAverage === 'number' ? currencyFormatter.format(orderVolumeAverage) : orderVolumeAverage, loading: isLoading, i18nPath: 'home-fe.insightsWidget.orderVolumeAverage', isWide: isWide, width: '35%' }),
            !isWide && React.createElement(Separator, null),
            React.createElement(WidgetValue, { value: typeof orderVolume === 'number' ? currencyFormatter.format(orderVolume) : orderVolume, loading: isLoading, i18nPath: 'home-fe.insightsWidget.orderVolume', isWide: isWide, width: '35%' })));
    }));
};
SalesThisWeekWidget.propTypes = {
    currency: PropTypes.string.isRequired,
    isWidgetLoading: PropTypes.bool,
    queryParams: PropTypes.object.isRequired,
    isWide: PropTypes.bool
};
