import React, { useState } from 'react'
import CreateTicketPage from './CreateTicketPage'
import ViewTicketPage from './ViewTicketPage'

export default function SupportPage () {
  const [ticketId, setTicketId] = useState(false)
  const [region, setRegion] = useState(false)

  return ticketId ? (
    <ViewTicketPage ticketId={ticketId} region={region} />
  ) : (
    <CreateTicketPage setTicketId={setTicketId} setRegion={setRegion} />
  )
}
