import * as Sentry from '@sentry/react'

const freshChatConfig = {
  development_eu: {
    host: 'https://wchat.freshchat.com',
    widget: 'https://wchat.freshchat.com/js/widget.js',
    token: '80f94737-3122-4eee-9241-ebe566732075'
  },
  staging_eu: {
    host: 'https://wchat.freshchat.com',
    widget: 'https://wchat.freshchat.com/js/widget.js',
    token: '80f94737-3122-4eee-9241-ebe566732075'
  },
  production_eu: {
    host: 'https://wchat.eu.freshchat.com',
    widget: 'https://wchat.eu.freshchat.com/js/widget.js',
    token: 'f34afccd-cb8a-49b3-b8d9-c6d0055da762'
  },
  development_us: {
    host: 'https://wchat.freshchat.com',
    widget: 'https://wchat.freshchat.com/js/widget.js',
    token: '1332a4eb-93bc-420b-9860-49e18c332fe5'
  },
  staging_us: {
    host: 'https://wchat.freshchat.com',
    widget: 'https://wchat.freshchat.com/js/widget.js',
    token: '1332a4eb-93bc-420b-9860-49e18c332fe5'
  },
  production_us: {
    host: 'https://wchat.freshchat.com',
    widget: 'https://wchat.freshchat.com/js/widget.js',
    token: '86a7059a-314b-42ce-a58c-abca6e336097'
  },
  development_ap: {
    host: 'https://wchat.au.freshchat.com',
    widget: 'https://wchat.au.freshchat.com/js/widget.js',
    token: '7bd76b27-663b-452d-9998-b0c16ee9fa37'
  },
  staging_ap: {
    host: 'https://wchat.au.freshchat.com',
    widget: 'https://wchat.au.freshchat.com/js/widget.js',
    token: '7bd76b27-663b-452d-9998-b0c16ee9fa37'
  },
  production_ap: {
    host: 'https://wchat.au.freshchat.com',
    widget: 'https://wchat.au.freshchat.com/js/widget.js',
    token: '17105859-3291-45f1-9050-1b62c2f23246'
  }
}

function alertInvalidFreshchatConfigFound (env, region) {
  Sentry.addBreadcrumb({
    message: 'Invalid freshchat configuration found',
    data: {
      env,
      region
    },
    level: 'info'
  })
}

export default function getFreshChatConfig (env, region) {
  const config = `${env}_${region}`.toLowerCase()
  if (freshChatConfig[config]) {
    return freshChatConfig[config]
  }

  alertInvalidFreshchatConfigFound(env, region)
  return freshChatConfig.production_eu
}
