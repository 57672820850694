import './CodeSnippet.css';
import { ButtonQuaternary } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import Clipboard from 'react-clipboard.js';
import Code from './Code';
function CodeSnippet({ code, options = {
    mode: 'htmlmixed',
    lineNumbers: true,
    readOnly: true,
    viewportMargin: Infinity,
    lineWrapping: true
} }) {
    const [copied, setCopied] = React.useState(false);
    const t = i18nHooks.useTranslator();
    const onSuccess = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };
    return (React.createElement("div", { className: 'code-snippet' },
        React.createElement(Code, { code: code, options: {
                mode: (options === null || options === void 0 ? void 0 : options.mode) || 'htmlmixed',
                lineNumbers: !!(options === null || options === void 0 ? void 0 : options.lineNumbers),
                readOnly: !!(options === null || options === void 0 ? void 0 : options.readOnly),
                viewportMargin: (options === null || options === void 0 ? void 0 : options.viewportMargin) || Infinity,
                lineWrapping: !!(options === null || options === void 0 ? void 0 : options.lineWrapping)
            } }),
        React.createElement("div", { className: 'button' },
            React.createElement(Clipboard, { component: 'span', "data-clipboard-text": code, onSuccess: onSuccess },
                React.createElement(ButtonQuaternary, { size: 'small' }, copied ? t('core.codeSnippet.clipboardButtonLabelSuccess') : t('core.codeSnippet.clipboardButtonLabel'))))));
}
export default CodeSnippet;
