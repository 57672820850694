const REGEX_URL_PLUGIN = '/boost/plugin/([a-z-]*)';
export const getPluginFromUrl = () => {
    const regex = new RegExp(REGEX_URL_PLUGIN, 'g');
    const currentUrl = new URL(window.location.href);
    const matches = regex.exec(currentUrl.pathname);
    if (!matches || matches.length < 2) {
        throw Error('cannot parse plugin ID from current URL');
    }
    return matches[1];
};
