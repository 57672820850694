import React from 'react';
import ReactDOM from 'react-dom';
const Portal = ({ children, root }) => {
    const container = React.useRef(document.createElement('div'));
    // Portal should not affect children layout
    container.current.style.display = 'contents';
    const getRootEl = React.useCallback(() => (root || document.body), [root]);
    React.useEffect(() => {
        getRootEl().appendChild(container.current);
        return () => {
            getRootEl().removeChild(container.current);
        };
    }, [root]);
    return ReactDOM.createPortal(children, container.current);
};
export default Portal;
