// eslint-disable-next-line
import React from 'react'
import Script from 'react-load-script'
import { useSelector } from 'react-redux'
import { selectors as configSelectors } from 'mage-config'
import * as Sentry from '@sentry/react'
import { getFreshchatWidgetUrl } from '../getFreshchatWidgetOptions'
import './fcwidget.css'

export default function FreshChatWidget () {
  const env = useSelector(configSelectors.environment)
  const region = useSelector(configSelectors.region)
  const widgetUrl = getFreshchatWidgetUrl({ env, region })

  if (!widgetUrl) {
    return null
  }

  return (
    <Script
      url={widgetUrl}
      onError={err => Sentry.captureException(err)}
    />
  )
}
