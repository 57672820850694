import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
const getAuthToken = (client) => () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data } = yield client.request({
            method: 'GET',
            url: '/v2/chat/auth',
            headers: { 'Content-Type': 'application/json' }
        });
        return data.token;
    }
    catch (error) {
        // TODO: Improve
        Sentry.captureException(error, { data: 'Error get auth token for Amazon Connect' });
    }
});
export default getAuthToken;
