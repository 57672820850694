import { __rest } from "tslib";
import { AppIcon } from 'mage-components';
import { translator } from 'mage-i18n';
import mageInsights from 'mage-insights';
import * as R from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EmptyOrErrorComponent from './EmptyOrErrorComponent';
import { AcceptInviteModal } from './Modals/AcceptInviteModal';
import { TaskItems } from './TaskItems';
const EMPTY_ARRAY = [];
const ignoredTasks = [
    'home-fe:2fa-config'
];
// The tasks will be sorted and displayed according to this order
const appTypePriorityList = [
    'users-fe:mid-invited',
    'orders-fe:expiring',
    'orders-fe:tocapture',
    'disputes-fe:unresolved',
    'users-fe:mid-added'
];
const getRelativeUrl = (url) => {
    try {
        const parsedUrl = new URL(url);
        const relativeUrl = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
        return relativeUrl;
    }
    catch (e) {
        return url;
    }
};
const generateGetPriority = (priorityList) => function (appType) {
    return priorityList.includes(appType) ? priorityList.indexOf(appType) : priorityList.length;
};
const getPriority = generateGetPriority(appTypePriorityList);
const filterUndefinedTasks = (tasks) => {
    return tasks.filter(task => typeof task !== 'undefined');
};
const filterTasks = (tasks) => {
    return tasks.filter(({ appType }) => !ignoredTasks.includes(appType));
};
const normalizeTasks = (tasks) => {
    const normalizedTasks = {};
    tasks.forEach(task => {
        const { appType, targetId, count = 0 } = task, partialTask = __rest(task, ["appType", "targetId", "count"]);
        if (appType in normalizedTasks) {
            normalizedTasks[appType].count += count;
            if (targetId) {
                normalizedTasks[appType].targetIds.push(targetId);
            }
        }
        else {
            normalizedTasks[appType] = Object.assign(Object.assign({}, partialTask), { appType,
                count, sortIndex: getPriority(appType), targetIds: targetId ? [targetId] : [] });
        }
    });
    return Object.values(normalizedTasks)
        .sort((a, b) => a.sortIndex - b.sortIndex);
};
const generateMapTasksToOptions = t => (tasks) => {
    const options = [];
    tasks.forEach(({ appId, appType, count, url }) => {
        const icon = React.createElement(AppIcon, { clientId: appId });
        const label = t === null || t === void 0 ? void 0 : t(`home-fe.tasks.${count > 0 ? 'aggregated' : 'single'}.${appType}`, { count });
        if (label) {
            const value = {
                appType,
                url: getRelativeUrl(url)
            };
            options.push({ icon, label, value });
        }
    });
    return options;
};
const TaskList = ({ raiseAnalyticsEvent, t, tasks = EMPTY_ARRAY }) => {
    const [options, setOptions] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    useEffect(() => {
        const updatedOptions = R.pipe(filterUndefinedTasks, filterTasks, normalizeTasks, generateMapTasksToOptions(t))(tasks);
        return setOptions(updatedOptions);
    }, [t, tasks]);
    const handleSelect = useCallback(({ appType, url }) => {
        if (raiseAnalyticsEvent) {
            raiseAnalyticsEvent({
                category: 'home-fe/tasklist',
                action: 'tasklist-click',
                label: appType
            });
        }
        // Don't reload if url is just a query parameter
        if (/^\/?\?/.test(url)) {
            history.push(url);
        }
        else {
            window.location.assign(url);
        }
    }, [raiseAnalyticsEvent]);
    return (React.createElement(React.Fragment, null,
        searchParams.get('task') === 'processStoreInvites' && React.createElement(AcceptInviteModal, null),
        options.length
            ? (React.createElement(TaskItems, { onSelect: handleSelect, options: options }))
            : (React.createElement(EmptyOrErrorComponent, { desc: t('home-fe.tasks.empty.desc'), isError: false, title: t('home-fe.tasks.empty.title') }))));
};
export default R.compose(connect((state) => ({
    raiseAnalyticsEvent: mageInsights.event,
    t: translator(state)
})))(TaskList);
export { generateGetPriority, generateMapTasksToOptions, getRelativeUrl, normalizeTasks, TaskList };
