import {
  IllustrationEmptyState,
  IllustrationErrorExplodingBalloon,
  SpacerVertical,
  Typography
} from '@klarna/bubble-ui'
import { useGetToken } from '@klarna/mp-ui'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div(() => {
  const paddingHorizontal = useGetToken('space.300').value()

  return {
    padding: `0 ${paddingHorizontal}px`
  }
})

const EmptyOrErrorComponent = ({ title, desc, isError }) => {
  const dataTestId = (isError)
    ? 'illustration-error-exploding-ballon'
    : 'illustration-empty-state'
  const Illustration = (isError)
    ? IllustrationErrorExplodingBalloon
    : IllustrationEmptyState
  return (
    <Container>
      <Illustration data-testid={dataTestId} />
      <br />
      <Typography textToken='text-style/text/paragraphs/tall/regular'>{title}</Typography>
      <SpacerVertical spaceToken='space.100' />
      <Typography textToken='text-style/text/paragraphs/body/regular'>{desc}</Typography>
    </Container>
  )
}

export default EmptyOrErrorComponent

EmptyOrErrorComponent.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  isError: PropTypes.bool.isRequired
}
