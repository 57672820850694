const BUTTON_CONTAINER_HEIGHT = 120

export function getButtonStyles ({ isXLDesktop }) {
  return {
    position: isXLDesktop ? 'absolute' : 'fixed',
    bottom: 0,
    transition: 'bottom .2s ease-in-out',
    width: '100%',
    height: BUTTON_CONTAINER_HEIGHT,
    background: 'linear-gradient(0, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)',
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  }
}
