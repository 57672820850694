import insights from 'mage-insights';
import React, { useEffect, useState } from 'react';
import { useApiKey } from '../../hooks/useApiKey';
import { useClientId } from '../../hooks/useClientId';
import { CollapsibleStepsContext } from './context';
const CLICK_EVENT = {
    action: 'click'
};
const CollapsibleStepsProvider = ({ children }) => {
    const [isFirstStepOpen, setIsFirstStepOpen] = useState(true);
    const [isSecondStepOpen, setIsSecondStepOpen] = useState(true);
    const [isThirdStepOpen, setIsThirdStepOpen] = useState(true);
    const { clientIdToken } = useClientId();
    const { hasAlreadyActiveKeys } = useApiKey();
    useEffect(() => {
        if (!!clientIdToken && hasAlreadyActiveKeys) {
            setIsThirdStepOpen(true);
        }
    }, [clientIdToken, hasAlreadyActiveKeys]);
    function toggleFirstStep() {
        insights.event(Object.assign(Object.assign({}, CLICK_EVENT), { category: 'v3/boost-home/install-plugin/collapse' }));
        setIsFirstStepOpen(value => !value);
    }
    function toggleSecondStep() {
        insights.event(Object.assign(Object.assign({}, CLICK_EVENT), { category: 'v3/boost-home/enable-plugin/collapse' }));
        setIsSecondStepOpen(value => !value);
    }
    function toggleThirdStep() {
        insights.event(Object.assign(Object.assign({}, CLICK_EVENT), { category: 'v3/boost-home/tailor-feature/collapse' }));
        setIsThirdStepOpen(value => !value);
    }
    const value = {
        isFirstStepOpen,
        isSecondStepOpen,
        isThirdStepOpen,
        toggleFirstStep,
        toggleSecondStep,
        toggleThirdStep
    };
    return (React.createElement(CollapsibleStepsContext.Provider, { value: value }, children));
};
export default CollapsibleStepsProvider;
