import Cookies from 'universal-cookie';
const getConsentGroups = ({ cookies = new Cookies() } = {}) => {
    const consentGroups = {};
    const optanonConsentCookie = cookies.get('OptanonConsent') || '';
    const match = optanonConsentCookie.match(/groups=([\w:,]*)/);
    if (match) {
        const groupStrings = match[1].split(',').filter(Boolean);
        for (const groupString of groupStrings) {
            const [key, value] = groupString.split(':');
            consentGroups[key] = value === '1';
        }
    }
    return consentGroups;
};
const getCookie = ({ cookieName = '', cookies = new Cookies() } = {}) => cookies.get(cookieName);
const hasCookieConsentBeenGiven = ({ cookies = new Cookies() } = {}) => (cookies.get('OptanonAlertBoxClosed') !== undefined);
const hasPerformanceCookiesConsent = ({ cookies = new Cookies() } = {}) => {
    const optanonConsentCookie = cookies.get('OptanonConsent') || '';
    return /groups=[\w:,]*C0002:1/.test(optanonConsentCookie);
};
export { getConsentGroups, getCookie, hasCookieConsentBeenGiven, hasPerformanceCookiesConsent };
