import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as s from '../selectors';
export const FormattedDate = ({ type, formatters, value, fallback }) => {
    let formatted = fallback;
    if (typeof value === 'string') {
        value = new Date(value);
    }
    if (value) {
        try {
            formatted = formatters[type].format(value);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }
    return formatted;
};
FormattedDate.defaultProps = {
    fallback: 'n/a'
};
FormattedDate.propTypes = {
    type: PropTypes.oneOf([
        'datetime',
        'date',
        'time',
        'utcdate'
    ]).isRequired,
    formatters: PropTypes.shape({
        datetime: PropTypes.object.isRequired,
        date: PropTypes.object.isRequired,
        time: PropTypes.object.isRequired,
        utcdate: PropTypes.object.isRequired
    }),
    value: PropTypes.any.isRequired,
    fallback: PropTypes.string
};
export default connect((state) => ({
    formatters: {
        datetime: s.formatters.datetime(state),
        date: s.formatters.date(state),
        time: s.formatters.time(state),
        utcdate: s.formatters.utcdate(state)
    }
}))(FormattedDate);
