import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@klarna/bubble-ui'
import { useHelpContext } from '../hooks'

HelpPageLink.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
  pageParameters: PropTypes.object
}

function HelpPageLink ({ children, page, pageParameters, ...props }) {
  const { navigation } = useHelpContext()

  function handleClick () {
    navigation.push(page, pageParameters)
  }

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Link textToken='text-style/text/paragraphs/body/medium' isUnderlined {...props} onPress={handleClick}>
      {children}
    </Link>
  )
}

export default HelpPageLink
