import React, { useContext } from 'react';
import TokenHelperContext from './TokenHelperContext';
const withTokenHelper = (propsCreator = () => ({})) => WrappedComponent => {
    const wrappedComponentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const TokenHelper = passThroughProps => {
        const tokenHelper = useContext(TokenHelperContext);
        const props = tokenHelper ? propsCreator(tokenHelper, passThroughProps) : {};
        return React.createElement(WrappedComponent, Object.assign({}, passThroughProps, props));
    };
    TokenHelper.displayName = `WithTokenHelper(${wrappedComponentDisplayName})`;
    return TokenHelper;
};
export default withTokenHelper;
