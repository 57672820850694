import React from 'react'
import PropTypes from 'prop-types'
import SearchResults from './SearchResults'
import { useHelpContext } from '../../hooks'

Search.propTypes = {
  children: PropTypes.node
}

export default function Search ({ children }) {
  const {
    navigation: {
      activePage: { searchQuery }
    }
  } = useHelpContext()

  return (
    <>
      {searchQuery && <SearchResults />}
      <div style={{ display: searchQuery ? 'none' : 'block' }}>{children}</div>
    </>
  )
}
