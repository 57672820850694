import React from 'react';
import DefaultBotMessage from '../../../components/botMessages/DefaultBotMessage';
import { BotNodeId } from '../../../types';
import { INeedMoreHelpThatHelped } from '../../common';
export const entrypoint = {
    value: 'helpbot.orders.orderProcessing',
    next: BotNodeId.ORDER_PROCESSING
};
export const flow = [
    {
        id: BotNodeId.ORDER_PROCESSING,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.orders.orderManagement.createOrderPos.question',
                next: BotNodeId.ORDER_MANAGEMENT_CREATE_ORDER_POS
            },
            {
                value: 'helpbot.orders.orderManagement.howToCaptureOrders.question',
                next: BotNodeId.ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS
            },
            {
                value: 'helpbot.orders.orderManagement.refundOrder.question',
                next: BotNodeId.ORDER_MANAGEMENT_REFUND_ORDER
            },
            {
                value: 'helpbot.orders.orderManagement.cancelOrder.question',
                next: BotNodeId.ORDER_MANAGEMENT_CANCEL_ORDER
            }
        ]
    },
    {
        id: BotNodeId.ORDER_MANAGEMENT_CREATE_ORDER_POS,
        botMessage: () => (React.createElement(DefaultBotMessage, { translationKey: 'helpbot.orders.orderManagement.createOrderPos.answer' })),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS,
        botMessage: null,
        options: () => [
            {
                value: 'helpbot.orders.orderManagement.howToCaptureOrders.partially.question',
                next: BotNodeId.ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_PARTIALLY
            },
            {
                value: 'helpbot.orders.orderManagement.howToCaptureOrders.fully.question',
                next: BotNodeId.ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_FULLY
            }
        ]
    },
    {
        id: BotNodeId.ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_PARTIALLY,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.orders.orderManagement.howToCaptureOrders.partially.answer' }),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_FULLY,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.orders.orderManagement.howToCaptureOrders.fully.answer' }),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.ORDER_MANAGEMENT_REFUND_ORDER,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.orders.orderManagement.refundOrder.answer' }),
        options: () => INeedMoreHelpThatHelped
    },
    {
        id: BotNodeId.ORDER_MANAGEMENT_CANCEL_ORDER,
        botMessage: () => React.createElement(DefaultBotMessage, { translationKey: 'helpbot.orders.orderManagement.cancelOrder.answer' }),
        options: () => INeedMoreHelpThatHelped
    }
];
