import { SpacerVertical, Typography } from '@klarna/bubble-ui'
import { useGetToken } from '@klarna/mp-ui'
import { hooks as coreHooks } from 'mage-core'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled, { css } from 'styled-components'

import LoadingComponent from '../Loader'
import EmptyOrErrorComponent from './EmptyOrErrorComponent'
import TaskDataProvider from './TaskDataProvider'
import Tasklist from './TaskList'

const RoundedContainer = styled.div(() => {
  const borderColor = useGetToken('colors/borders/subtle-floating').toString()
  const borderRadius = useGetToken('corner-radius/m').value()
  const paddingVertical = useGetToken('space.300').value()

  return css({
    border: `1px solid ${borderColor}`,
    borderRadius,
    padding: `${paddingVertical}px 0`
  })
})

const TitleContainer = styled.div(() => {
  const paddingHorizontal = useGetToken('space.300').value()

  return {
    padding: `0 ${paddingHorizontal}px`
  }
})

function TaskListComponent () {
  const t = i18nHooks.useTranslator()
  const tokenHelper = coreHooks.useTokenHelper()
  const mids = tokenHelper.getMids()
  const isTransactingUser = tokenHelper.isTransactingUser()
  const hasOrdersAccess = tokenHelper.hasClientForEntityType('merchant', 'orders-fe')

  if (!isTransactingUser) {
    return null
  }

  return (
    <RoundedContainer>
      <TitleContainer>
        <Typography textToken='text-style/headings/blocks/bold/grande'>
          {t('home-fe.tasks.title')}
        </Typography>
      </TitleContainer>

      <SpacerVertical spaceToken='space.300' />

      <TaskDataProvider
        mids={mids}
        includeOrders={hasOrdersAccess}
        onError={() => (<EmptyOrErrorComponent isError title={t('home-fe.tasks.error.title')} desc={t('home-fe.tasks.error.desc')} />)}
        onLoading={() => <LoadingComponent />}
      >
        {
          data => <Tasklist tasks={data} />
        }
      </TaskDataProvider>

    </RoundedContainer>
  )
}

export default TaskListComponent
