import { useHelpContext } from './';
import { useSelector } from 'react-redux';
import { selectors as configSelectors } from 'mage-config';
import { selectors as coreSelectors, hooks as coreHooks } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import { useFeatures } from '@merchant-portal/experimentation';
import { useChatClient } from './useChatClient';
import { generateRecordUserRestoreId } from '../utilities/recordUserRestoreId';
import { gatherCallbacks } from '../utilities/callback';
import { generateSidebarController } from '../utilities/sidebarController';
import { DesignTokens } from '@klarna/bubble-ui';
export const useChatContext = () => {
    // @ts-ignore
    const { toggleWidget } = useHelpContext();
    const t = i18nHooks.useTranslator();
    // @ts-ignore
    const env = useSelector(configSelectors.environment);
    const selectedMid = useSelector(coreSelectors.getSelectedMid);
    const tokenHelper = coreHooks.useTokenHelper();
    const features = useFeatures();
    const chatClient = useChatClient();
    const createClient = useSelector(coreSelectors.createBackendClient);
    const sidebarController = generateSidebarController(toggleWidget);
    const closeViewport = () => sidebarController(false);
    const openViewport = () => sidebarController(true);
    const onNewUser = generateRecordUserRestoreId(chatClient);
    const onCloseChat = () => openViewport();
    const onChatWidgetOpens = gatherCallbacks([closeViewport]);
    const designContext = DesignTokens.useDesignContext();
    return {
        market: '',
        features,
        env,
        language: i18nHooks.useLanguage(),
        selectedMid,
        tokenHelper,
        t,
        chatClient,
        onNewUser,
        onCloseChat,
        onChatWidgetOpens,
        // @ts-ignore
        createClient,
        designContext
    };
};
