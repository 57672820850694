import { __awaiter } from "tslib";
import { useApiKeys } from '@merchant-portal/credentials';
export const useCreateApiKey = ({ selectedMid }) => {
    const { createApiKey } = useApiKeys({ mid: selectedMid !== null && selectedMid !== void 0 ? selectedMid : '', fetchingApiKeysInitially: false });
    return () => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedMid) {
            throw new Error('SelectedMid is required to create an API key.');
        }
        const { credentialId, secret } = yield createApiKey();
        return { username: credentialId, password: secret };
    });
};
