import { getConfig } from '../config';
import * as u from '../utils';
const withContext = (fn) => {
    const enabled = getConfig('insights.googleAnalytics.enabled', false);
    const context = getConfig('context');
    if (!enabled)
        return;
    if (!context.gtag) {
        context.dataLayer = context.dataLayer || [];
        context.gtag = (...args) => {
            context.dataLayer.push(args);
        };
        context.gtag('js', new Date());
    }
    fn(context.gtag);
};
const create = () => withContext(() => { });
const pageview = () => withContext(gtag => {
    const tagId = getConfig('insights.googleAnalytics.tagId');
    gtag('config', tagId);
});
const setUser = userData => withContext(gtag => {
    const { user_id: userId } = userData;
    gtag('set', 'user_id', userId);
    data({
        user_id: userId
    });
});
const setRealm = realm => withContext(() => {
    data({
        user_realm: realm
    });
});
const data = custom => withContext(gtag => {
    const dimensions = getConfig('insights.googleAnalytics.dimensions', {});
    const metrics = getConfig('insights.googleAnalytics.metrics', {});
    const set = u.translateKeys(custom, dimensions, metrics);
    if (Object.keys(set).length === 0)
        return;
    gtag('set', set);
});
const event = ({ category, action, label, value, customData = {} }) => withContext(gtag => {
    data(customData);
    const clientId = getConfig('clientId', '');
    if (!category || !action) {
        // eslint-disable-next-line no-console
        console.warn('insights/event requires category & action');
        return;
    }
    const eventCategory = `${clientId}/${category}`;
    gtag('event', action, {
        event_category: eventCategory,
        event_label: label,
        value
    });
});
const logException = ({ type = 'n/a' }) => withContext(gtag => {
    const clientId = getConfig('clientId', '');
    gtag('event', 'exception', {
        description: `${clientId}/${type}`
    });
});
export { create, data, event, logException, pageview, setRealm, setUser };
