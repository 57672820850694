import merge from 'deepmerge';
import dotProp from 'dot-prop-immutable';
import { setConfig } from './config';
export default (clientId, config, context = window) => {
    const initialConfig = merge.all([
        { clientId, context },
        dotProp.get(config, 'common', {}),
        dotProp.get(config, `apps.${clientId}.config`, {})
    ]);
    setConfig(initialConfig);
};
