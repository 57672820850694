import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { StyleContext } from './utils/context';
import { calculateSpaceWithFactor, getSpaceFactorFromBreakpoint, getValueFromObject, getValueWithBreakpointFallback } from './utils/responsive';
import { withResponsive } from './utils/withResponsive';
const getColumnStyles = ({ alignItems: _alignItems, columns: _columns, display: _display, spacing: _spacing, order: _order = 1, justify, breakpoint, spaceBottom, extraStyles }) => {
    const alignItems = getValueWithBreakpointFallback(_alignItems, breakpoint, 'normal');
    const columns = getValueWithBreakpointFallback(_columns, breakpoint, 12);
    const justifyContent = getValueWithBreakpointFallback(justify, breakpoint, 'normal');
    const order = getValueWithBreakpointFallback(_order, breakpoint, 1);
    const spacingFactor = getValueWithBreakpointFallback(_spacing, breakpoint, getSpaceFactorFromBreakpoint(breakpoint));
    // we don't use a fallback for the display property since typically we want to hide
    // elements on specific breakpoints, and not on breakpoint and above
    const display = getValueFromObject(_display, breakpoint, 'flex');
    const gutter = calculateSpaceWithFactor(spacingFactor);
    // when columns are undefined, we default to 12 columns width, if columns is not a number
    // aka 'none' or false, we'll use different values for the styles instead of calculating
    // the width
    const shouldUseColumns = Number.isInteger(columns);
    // Keep 7 significant numbers
    const widthFactor = shouldUseColumns ? Math.round((Math.min(12, columns) / 12) * 10e7) / 10e5 : 0;
    const maxWidth = `${widthFactor}%`;
    return Object.assign(Object.assign({}, extraStyles), { boxSizing: 'border-box', flex: `1 0 ${shouldUseColumns ? maxWidth : 'auto'}`, flexDirection: 'column', maxWidth: shouldUseColumns ? maxWidth : 'none', paddingBottom: spaceBottom && gutter * 2, paddingLeft: gutter / 2, paddingRight: gutter / 2, order,
        display,
        justifyContent,
        alignItems });
};
const ColumnContainer = ({ alignItems, breakpoint, children, columns, display, justify, order, spacing, extraStyles = {} }) => {
    const { spacing: contextSpacing, spaceBottom } = useContext(StyleContext);
    return (React.createElement("div", { className: 'mp-grid-column', style: getColumnStyles({
            alignItems,
            breakpoint,
            columns,
            display,
            justify,
            order,
            spacing: spacing || contextSpacing,
            spaceBottom,
            extraStyles
        }) }, children));
};
ColumnContainer.propTypes = {
    alignItems: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    breakpoint: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    columns: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    display: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    justify: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    order: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    spacing: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    extraStyles: PropTypes.object
};
export const Column = withResponsive(ColumnContainer);
