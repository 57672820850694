import { useResponsiveContext } from '@klarna/mp-ui';
import React from 'react';
import styled, { css } from 'styled-components';
import { NotificationEmpty, NotificationList, NotificationLoading } from './NotificationContent';
const NotificationContextMenu = styled.div(() => {
    const { isMobile } = useResponsiveContext();
    return css({
        backgroundColor: 'white',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '24px 24px 32px 24px',
        gap: '16px',
        position: 'fixed',
        top: '65px',
        minWidth: '400px',
        maxWidth: '400px',
        minHeight: '209px',
        right: '290px',
        zIndex: 10,
        boxShadow: '0px 12px 24px 0px #0000001A'
    }, isMobile
        ? {
            right: 'auto',
            width: 'calc(100% - 32px)',
            marginLeft: '16px',
            maxWidth: '100%'
        }
        : {});
});
export const NotificationPanel = React.forwardRef(({ notifications, isLoading = false }, ref) => {
    const renderContent = () => {
        if (isLoading) {
            return React.createElement(NotificationLoading, null);
        }
        if (notifications.length === 0) {
            return React.createElement(NotificationEmpty, null);
        }
        return React.createElement(NotificationList, { notifications: notifications });
    };
    return React.createElement(NotificationContextMenu, { ref: ref }, renderContent());
});
