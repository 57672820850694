import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { APP_CONTAINER_SELECTOR_FRAMEWORK } from '../../constants'

const rootNode = document.getElementById('root')
const mutationObserverConfig = { attributes: true, childList: true, subtree: true }

TourSpotlight.propTypes = {
  target: PropTypes.string,
  onClickOutside: PropTypes.func
}

const spotlightPadding = 10

function TourSpotlight ({ target, onClickOutside }) {
  const targetRef = useRef(null)
  const [spotlightPosition, setSpotlightPosition] = useState(null)

  function updateSpotlightPosition () {
    targetRef.current = document.querySelector(target)
    if (targetRef.current && typeof targetRef.current.getBoundingClientRect === 'function') {
      const rect = targetRef.current.getBoundingClientRect()
      const height = rect.height + 2 * spotlightPadding
      const width = rect.width + 2 * spotlightPadding
      const top = rect.y - spotlightPadding + window.scrollY
      const left = rect.x - spotlightPadding
      setSpotlightPosition({ height, width, top, left })
    }
  }

  const isInsideSpotlight = ({ x, y }) => {
    if (targetRef.current && typeof targetRef.current.getBoundingClientRect === 'function') {
      const rect = targetRef.current.getBoundingClientRect()
      const height = rect.height + 2 * spotlightPadding
      const width = rect.width + 2 * spotlightPadding
      const top = rect.y - spotlightPadding
      const left = rect.x - spotlightPadding

      return y > top && y < top + height && x > left && x < left + width
    }
  }

  const captureClicks = event => {
    if (!isInsideSpotlight(event, spotlightPosition)) {
      onClickOutside()
    }
  }

  useEffect(() => {
    const root = document.querySelector(APP_CONTAINER_SELECTOR_FRAMEWORK).parentElement
    root.addEventListener('click', captureClicks)
    return () => root.removeEventListener('click', captureClicks)
  }, [])

  useEffect(() => {
    updateSpotlightPosition()

    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
    const scrollContainer = document.querySelector(APP_CONTAINER_SELECTOR_FRAMEWORK).parentElement

    scrollContainer.addEventListener('scroll', updateSpotlightPosition)
    const observer = new MutationObserver(updateSpotlightPosition)
    observer.observe(rootNode, mutationObserverConfig)
    return () => {
      observer.disconnect()
      scrollContainer.removeEventListener('scroll', updateSpotlightPosition)
    }
  }, [target])

  if (!spotlightPosition) {
    return null
  }
  return (
    <div
      style={{
        ...spotlightPosition,
        position: 'absolute',
        background: 'gray'
      }}
    />
  )
}

export default TourSpotlight
