import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorComponent from './ErrorComponent';
class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorId: undefined
        };
    }
    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const errorId = Sentry.captureException(error);
            this.setState({ errorId });
        });
    }
    render() {
        const { children } = this.props;
        const { errorId } = this.state;
        return errorId
            ? React.createElement(ErrorComponent, { errorId: errorId })
            : children;
    }
}
Error.propTypes = {
    children: PropTypes.node.isRequired
};
export default Error;
