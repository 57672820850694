import dotProp from 'dot-prop-immutable';
let config = {};
const setConfig = (...args) => {
    config = (args.length > 1)
        ? dotProp.set(config, ...args)
        : args[0];
};
const getConfig = (...args) => {
    return args.length
        ? dotProp.get(config, ...args)
        : config;
};
export { getConfig, setConfig };
