import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ButtonSecondary, ButtonPrimary } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import { useHelpContext } from '../../hooks'

TopicList.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  analyticsCategory: PropTypes.string.isRequired,
  limit: PropTypes.number
}

const GRID_SIZE = 5

const Wrapper = styled.div`
  display: block;
  margin-bottom: ${GRID_SIZE}px;
  margin-right: ${GRID_SIZE}px;
`

export default function TopicList ({ topics, analyticsCategory, limit = null }) {
  const t = i18nHooks.useTranslator()

  const [isExpanded, setIsExpanded] = useState(false)

  const visibleTopicsLength = isExpanded ? topics.length : limit || topics.length

  const visibleTopics = topics.slice(0, visibleTopicsLength)

  const showExpandButton = topics.length > visibleTopics.length

  return (
    <div>
      {visibleTopics.map((topic, index) => (
        <TopicButton
          key={topic.id}
          topic={topic}
          index={index}
          analyticsCategory={analyticsCategory}
        />
      ))}

      {showExpandButton && (
        <ButtonPrimary
          id='uhqm-topic-link'
          onClick={() => {
            setIsExpanded(true)
          }}
          size='small'
        >
          {`${t('help-tool.topics.expandButton')} >>`}
        </ButtonPrimary>
      )}
    </div>
  )
}

TopicButton.propTypes = {
  topic: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  analyticsCategory: PropTypes.string.isRequired
}

function TopicButton ({ topic, index, analyticsCategory }) {
  const { navigation } = useHelpContext()

  return (
    <Wrapper>
      <ButtonSecondary
        id='uhqm-topic-link'
        onClick={() => {
          navigation.push('topic', { topicSlug: topic.slug })
        }}
        size='small'
      >
        {topic.name}
      </ButtonSecondary>
    </Wrapper>
  )
}
