const mapPartnerAccountsToOptions = (partnerAccountIds, partnerAccountsData) => {
    return partnerAccountIds.map(partnerAccountId => {
        const partnerAccountShortId = partnerAccountId.split(':').pop();
        const data = partnerAccountsData.find(({ id }) => id === partnerAccountId);
        return {
            description: partnerAccountShortId,
            disabled: false,
            value: partnerAccountId,
            label: data ? data.name : partnerAccountShortId
        };
    });
};
export { mapPartnerAccountsToOptions };
