import { Loader, SpacerVertical } from '@klarna/bubble-ui';
import { selectors as coreSelectors } from 'mage-core';
import { node } from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthenticationContext } from '../AuthenticationContext';
import CenteredContent from '../CenteredContent';
AuthenticationRequired.propTypes = { children: node };
function AuthenticationRequired({ children }) {
    const isAuthenticated = useSelector(coreSelectors.isAuthenticated);
    const { setAuthenticationRequired, isAuthenticationRequired } = useAuthenticationContext();
    useEffect(() => {
        if (!isAuthenticationRequired) {
            setAuthenticationRequired(true);
        }
    }, [isAuthenticationRequired]);
    return isAuthenticated
        ? children
        : (React.createElement(React.Fragment, null,
            React.createElement(SpacerVertical, { large: true }),
            React.createElement(CenteredContent, { illustration: React.createElement(Loader, null) })));
}
export default AuthenticationRequired;
