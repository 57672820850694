import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useWhenClickOutside } from '../../hooks';
import HeaderMenuButton from '../HeaderMenuButton';
import Menu from './Menu';
import UserMenuDialogSwitcher, { types as UserMenuDialogSwitcherTypes } from './UserMenuDialogSwitcher';
import UserMenuIcon from './UserMenuIcon';
const menuStyles = {
    right: 0,
    position: 'absolute',
    zIndex: 101
};
function UserMenu({ onLogoutClick }) {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);
    const [dialog, setDialog] = useState(null);
    const ref = useRef();
    const preventScroll = () => {
        document.body.style.overflow = 'hidden';
        window.scrollTo({ top: 0, left: 0 });
    };
    const openLanguageDialog = useCallback(() => {
        preventScroll();
        setDialog(UserMenuDialogSwitcherTypes.language);
    }, [UserMenuDialogSwitcherTypes.language]);
    const openProfileDialog = useCallback(() => {
        preventScroll();
        setDialog(UserMenuDialogSwitcherTypes.profile);
    }, [UserMenuDialogSwitcherTypes.language]);
    const hideUserMenu = useCallback(() => setShow(false), []);
    const clearDialog = useCallback(() => {
        document.body.style.overflow = 'unset';
        setDialog(null);
    }, []);
    useWhenClickOutside(ref, hideUserMenu);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: ref, style: { position: 'relative', maxHeight: '40px' } },
            React.createElement(HeaderMenuButton, { id: 'header-usermenu-button', onClick: toggle },
                React.createElement(UserMenuIcon, { isTooltipEnabled: !show, toggled: show })),
            show && (React.createElement("div", { style: menuStyles },
                React.createElement(Menu, { onLogoutClick: onLogoutClick, onLanguageClick: openLanguageDialog, onProfileClick: openProfileDialog })))),
        React.createElement(UserMenuDialogSwitcher, { type: dialog, onChooseDialog: hideUserMenu, onClose: clearDialog })));
}
UserMenu.propTypes = {
    onLogoutClick: PropTypes.func.isRequired
};
export default UserMenu;
