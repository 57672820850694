import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Flex, useWidth } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import Insights from 'mage-insights';
import React, { useEffect, useState } from 'react';
import bannerDesktopImage from '../../../assets/top-desktop-banner-image.png';
import bannerMobileImage from '../../../assets/top-mobile-banner-image.png';
const WelcomeBanner = () => {
    const [ref, width] = useWidth();
    const [isSmallVersion, setIsSmallVersion] = useState(false);
    const t = i18nHooks.useTranslator();
    useEffect(() => {
        setIsSmallVersion(width <= 696);
    }, [width]);
    const trackUsage = (action) => {
        Insights.event({
            category: 'boost-home/welcome-banner',
            action
        });
    };
    useEffect(() => {
        trackUsage('impression');
    }, []);
    const containerStyleProps = {
        backgroundColor: isSmallVersion ? '#D1E2FF' : 'transparent',
        boxSizing: 'border-box',
        borderRadius: '0 0 16px 16px',
        padding: isSmallVersion ? '35px 45px 50px' : '92px 40px 92px',
        width: isSmallVersion ? '100%' : 'calc(100% - 371px)'
    };
    const flexStyleProps = {
        backgroundColor: '#D1E2FF',
        backgroundImage: `url(${isSmallVersion ? bannerMobileImage : bannerDesktopImage})`,
        backgroundPosition: `${isSmallVersion ? '0 0 ' : 'right 111px bottom'}`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        borderRadius: '16px',
        paddingTop: isSmallVersion ? '340px' : 0
    };
    return (React.createElement("span", { ref: ref },
        React.createElement(Flex, { justifyContent: 'space-between', alignItems: isSmallVersion ? 'center' : 'flex-end', column: isSmallVersion, style: flexStyleProps },
            React.createElement("span", { style: containerStyleProps },
                React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/primary' }, t('home-fe.boosters-home.welcome-banner.title')),
                React.createElement(SpacerVertical, { spaceToken: 'space.200' }),
                React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular' }, t('home-fe.boosters-home.welcome-banner.text'))))));
};
export default WelcomeBanner;
