import { isNil } from 'ramda';
import { BASE_GUTTER, BREAKPOINT, DEFAULT_SPACING } from './constants';
export const isMobile = width => width < BREAKPOINT.TABLET;
export const isTablet = width => width >= BREAKPOINT.TABLET && width < BREAKPOINT.DESKTOP;
export const isDesktop = width => width >= BREAKPOINT.DESKTOP;
export const getValueFromObject = (objectOrValue, breakpoint, defaultValue, withFallback = false) => {
    if (typeof objectOrValue === 'object') {
        const result = withFallback
            ? getBreakpointFallback(objectOrValue, breakpoint)
            : objectOrValue[breakpoint];
        return !isNil(result) ? result : defaultValue;
    }
    return objectOrValue || defaultValue;
};
export const getValueWithBreakpointFallback = (objectOrValue, breakpoint, defaultValue) => {
    return getValueFromObject(objectOrValue, breakpoint, defaultValue, true);
};
export const calculateSpaceWithFactor = spacing => spacing >= 0 ? BASE_GUTTER * spacing : BASE_GUTTER;
export const getSpaceFactorFromBreakpoint = breakpoint => DEFAULT_SPACING[breakpoint];
export const getBreakpointFallback = (object, breakpoint) => {
    const fallback = {
        mobile: 'mobile',
        tablet: 'mobile',
        desktop: 'tablet'
    };
    if (!isNil(object[breakpoint])) {
        return object[breakpoint];
    }
    else {
        return breakpoint === fallback[breakpoint]
            ? null
            : getBreakpointFallback(object, fallback[breakpoint]);
    }
};
