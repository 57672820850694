import Joi from 'joi';
import _ from 'lodash';
import { selectors as coreSelectors } from 'mage-core';
import { useSelector } from 'react-redux';
export var OriginValidationError;
(function (OriginValidationError) {
    OriginValidationError["INVALID_DOMAIN"] = "INVALID_DOMAIN";
    OriginValidationError["INVALID_PROTOCOL"] = "INVALID_PROTOCOL";
})(OriginValidationError || (OriginValidationError = {}));
export const useBackendClient = () => {
    const createClient = useSelector(coreSelectors.createBackendClient);
    return createClient('credentials');
};
export function toCamelCase(obj) {
    return _.transform(obj, (result, value, key, target) => {
        const camelKey = _.isArray(target) ? key : _.camelCase(key.toString());
        result[camelKey] = _.isObject(value) && !_.isDate(value) ? toCamelCase(value) : value;
    });
}
export function validateOrigin(origin, allowTestOrigins = false) {
    // Protocol validation
    const nonProductionProtocolRegex = /^https?:\/\//i;
    const productionProtocolRegex = /^https:\/\//i;
    const protocolSchema = allowTestOrigins
        ? Joi.string().pattern(nonProductionProtocolRegex)
        : Joi.string().pattern(productionProtocolRegex);
    const { error: protocolError } = protocolSchema.validate(origin);
    if (protocolError) {
        return {
            isValid: false,
            error: OriginValidationError.INVALID_PROTOCOL
        };
    }
    // Domain validation
    const testingOriginRegex = /^(https?:\/\/)(\*|(\*\.|([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)*)[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z]{2,63})+|localhost)(:\d{1,5})?$/i;
    const domainSchema = allowTestOrigins
        ? Joi.string().pattern(testingOriginRegex)
        : Joi.string().replace(/^https:\/\//i, '').domain({ tlds: false });
    const { error: domainError } = domainSchema.validate(origin);
    if (domainError) {
        return {
            isValid: false,
            error: OriginValidationError.INVALID_DOMAIN
        };
    }
    return {
        isValid: true,
        error: null
    };
}
