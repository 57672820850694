import React from 'react';
import { ChatBubble } from '@klarna/bubble-ui';
const styles = {
    chatBubble: {
        marginBottom: '0px',
        alignSelf: 'flex-end'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    }
};
const HelpbotResponse = ({ message }) => {
    return (React.createElement("div", { style: styles.wrapper },
        React.createElement("div", { style: styles.chatBubble },
            React.createElement(ChatBubble, { direction: 'right', color: 'black', maxWidth: 300 }, message))));
};
export { HelpbotResponse };
