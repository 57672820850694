import React from 'react';
import { useHasPartnerAccount } from '../../../../../../../hooks/useHasPartnerAccount';
import { PLUGINS } from '../../../../../constants';
import PluginLogo from '../PluginLogo';
const PARTNER_ACCOUNT_PLUGIN_IDS = ['adobe-commerce', 'woo-commerce'];
export const usePluginOptions = () => {
    const hasPartnerAccount = useHasPartnerAccount();
    const pluginOptions = [
        ...PLUGINS.map(plugin => ({
            value: plugin.id,
            label: plugin.name,
            icon: React.createElement(PluginLogo, { imageName: plugin.logo.imageName, height: plugin.logo.height })
        }))
    ];
    if (hasPartnerAccount) {
        return pluginOptions.filter(o => PARTNER_ACCOUNT_PLUGIN_IDS.includes(o.value));
    }
    return pluginOptions;
};
