import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
import { hooks as i18nHooks } from 'mage-i18n';
import { useEffect, useState } from 'react';
const fetchNotifications = (_a) => __awaiter(void 0, [_a], void 0, function* ({ client, targetId }) {
    return (yield client.get(`/v1/users/${targetId}/tasks/?category=notification`));
});
const notifierResponseToNotification = ({ i18n, notifications }) => {
    const notificationsWithTranslations = notifications.data.filter((notification) => (i18n(`core.notification.apps.${notification.appId}.${notification.type}.title`) !== ''));
    return notificationsWithTranslations.map((notifierData) => ({
        analytics: {
            category: `${notifierData.appId}:${notifierData.type}`
        },
        title: i18n(`core.notification.apps.${notifierData.appId}.${notifierData.type}.title`),
        content: i18n(`core.notification.apps.${notifierData.appId}.${notifierData.type}.content`),
        read: notifierData.read,
        link: {
            content: i18n(`core.notification.apps.${notifierData.appId}.${notifierData.type}.link`),
            href: notifierData.url
        },
        rawObject: notifierData
    }));
};
export function useFetchNotifications({ client, targetId }) {
    const [notifications, setNotifications] = useState({
        notifications: [],
        isLoading: true
    });
    const i18n = i18nHooks.useTranslator();
    const markAsRead = () => __awaiter(this, void 0, void 0, function* () {
        setNotifications({
            notifications: notifications.notifications.map(notification => (Object.assign(Object.assign({}, notification), { read: true }))),
            isLoading: false
        });
        const unreadNotifications = notifications.notifications
            .filter(({ read }) => !read);
        const updatePromises = unreadNotifications
            .map((notification) => __awaiter(this, void 0, void 0, function* () {
            const body = { url: notification.rawObject.url, read: true, category: 'notification' };
            yield client.post(`/v1/users/${targetId}/tasks/${notification.rawObject.appId}/${notification.rawObject.type}`, body);
        }));
        Promise.all(updatePromises)
            .catch(catchedError => {
            // eslint-disable-next-line no-console
            console.error('Failed to mark notifications as read', catchedError);
            Sentry.captureException(catchedError);
        });
    });
    useEffect(() => {
        fetchNotifications({ client, targetId })
            .then(res => notifierResponseToNotification({ i18n, notifications: res }))
            .then(notifications => setNotifications({ isLoading: false, notifications }))
            .catch(() => setNotifications({ isLoading: false, notifications: [] }));
    }, []);
    return [notifications.notifications, notifications.isLoading, markAsRead];
}
