import { selectors as experimentationSelectors } from '@merchant-portal/experimentation';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMid } from '../useMid';
import { ShowMidSelectorContext } from './context';
const IS_MID_SELECTOR_DISABLED_XP_KEY = 'merchant-portal.boost.pig.mid-selector.disabled';
export const ShowMidSelectorProvider = ({ children }) => {
    const features = useSelector(experimentationSelectors.features);
    const feature = features === null || features === void 0 ? void 0 : features[IS_MID_SELECTOR_DISABLED_XP_KEY];
    const canHideMidSelector = (feature === null || feature === void 0 ? void 0 : feature.variate_id) === 'hide';
    const { userMids, handleMidChange } = useMid();
    const hasOneMid = (userMids === null || userMids === void 0 ? void 0 : userMids.length) === 1;
    const mustShowMidSelector = !canHideMidSelector || !hasOneMid;
    if (!mustShowMidSelector) {
        handleMidChange(userMids[0]);
    }
    const midController = {
        mustShowMidSelector
    };
    return (React.createElement(ShowMidSelectorContext.Provider, { value: midController }, children));
};
