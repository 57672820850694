import { usePageContext } from '@merchant-portal/framework';
import { hooks as coreHooks } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import React, { useEffect } from 'react';
import LoginRequired from './LogInRequired';
import SetUpRequired from './SetUpRequired';
const checkIfSetUpIsRequired = (authenticationStatus) => {
    const isSetUpRequired = authenticationStatus.password === 'NOT_SET_UP' || authenticationStatus.mfa === 'NOT_SET_UP';
    return isSetUpRequired;
};
const Content = () => {
    const { setPageContext = () => { } } = usePageContext();
    const tokenHelper = coreHooks.useTokenHelper();
    const t = i18nHooks.useTranslator();
    const authenticationStatus = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getAuthenticationStatus();
    const isSetUpRequired = authenticationStatus ? checkIfSetUpIsRequired(authenticationStatus) : true;
    useEffect(() => {
        setPageContext({
            title: isSetUpRequired ? t('core.stepUpAuthentication.setUp.title') : t('core.stepUpAuthentication.login.title')
        });
    }, []);
    return isSetUpRequired ? React.createElement(SetUpRequired, null) : React.createElement(LoginRequired, null);
};
export default Content;
