const maskUrl = host => url => {
  return url
    .replace(/(\/)[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}(\/?)/g, (match, startSlash, endSlash) => `${startSlash}{uuid}${endSlash}`)
    .replace(/(\/)[A-Z]{1,2}\d{5,7}(\/?)/g, (match, startSlash, endSlash) => `${startSlash}{mid}${endSlash}`)
    .replace(/(\/)\d{5,}(\/?)/g, (match, startSlash, endSlash) => `${startSlash}{id}${endSlash}`)
    .replace(/\?.*$/, '?{query}') // <-- Remove everything after any question mark, i.e. the query parameters
    .replace(/(\/)(?:eu|us|ap)(\/)/g, (match, startSlash, endSlash) => `${startSlash}{region}${endSlash}`)
    .replace(host, '')
}

export {
  maskUrl
}
