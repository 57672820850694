import React from 'react'
import { useHelpContext } from '../hooks'
import TopicOverviewPage from './Topic/TopicOverviewPage'
import HomePage from './HomePage'
import ArticlePage from './Article/ArticlePage'
import TourPage from './Tour/TourPage'
import Search from './Search'
import SupportPage from './SupportPage'
import SupportChannelSelectorPage from './SupportChannelSelector'
import { SupportHelpbot } from './SupportHelpBot'

const routes = {
  topic: TopicOverviewPage,
  article: ArticlePage,
  home: HomePage,
  tour: TourPage,
  support: SupportPage,
  supportHelpbot: SupportHelpbot,
  supportChannelSelector: SupportChannelSelectorPage
}

export default function Router () {
  const {
    navigation,
    navigation: {
      activePage: { path, parameters }
    }
  } = useHelpContext()

  const Page = routes[path]

  return (
    <div data-testid='uhqm-router-container'>
      <Search>
        <Page parameters={parameters} navigation={navigation} />
      </Search>
    </div>
  )
}
