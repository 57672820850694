import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  ButtonPrimary,
  TitlePrimary,
  ProgressBar,
  SpacerVertical,
  Typography
} from '@klarna/bubble-ui'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { renderOptions } from '../Article/ArticlePage'
import Overlay from './TourOverlay'
import Spotlight from './TourSpotlight'
import { hooks as i18nHooks } from 'mage-i18n'
import { useHelpContext, useElementDetector } from '../../hooks'
import { useHistory } from 'react-router-dom'
import TermList from './TermList'

Tour.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      selector: PropTypes.string,
      selectorFallbackContent: PropTypes.string,
      title: PropTypes.string,
      body: PropTypes.object,
      stepUrl: PropTypes.url,
      optional: PropTypes.bool,
      stepCompleteSelectors: PropTypes.arrayOf(PropTypes.string),
      terms: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          word: PropTypes.string.isRequired,
          definition: PropTypes.string.isRequired
        })
      )
    })
  )
}

function navigateIfNotCurrent (target, history) {
  if (!target) return
  const currentPath = window.location.pathname.replace(/\/+$/, '')
  const normalizedTargetPath = target.replace(/\/+$/, '')

  if (currentPath !== normalizedTargetPath) {
    const currentApp = currentPath.replace(/^\/+/, '').split('/')[0]
    const [targetApp, ...pathFragments] = normalizedTargetPath.replace(/^\/+/, '').split('/')

    if (currentApp !== targetApp) {
      setTimeout(() => {
        window.location.pathname = normalizedTargetPath
      }, 300)
    } else {
      history.push(`/${pathFragments.join('/')}`)
    }
  }
}

function Tour ({ steps, title }) {
  const [stepIndex, setStepIndex] = useState(0)
  const [isVisible, setVisible] = useState(steps.length > 0)
  const [isSelectorElementPresent, setIsSelectorElementPresent] = useState(false)
  const [showSelectorFallbackContent, setShowSelectorFallbackContent] = useState(false)
  const pathByStepIndex = useRef({})
  const { navigation } = useHelpContext()
  const history = useHistory()
  const t = i18nHooks.useTranslator()

  const currentStep = steps[stepIndex]

  useEffect(() => {
    navigateIfNotCurrent(currentStep.stepUrl, history)
  }, [])

  const previousStep = stepIndex === 0 ? null : steps[stepIndex - 1]

  useElementDetector(
    previousStep && previousStep.optional ? [previousStep.selector] : null,
    isPresent => isPresent && setStepIndex(stepIndex - 1),
    2000
  )

  useElementDetector(currentStep.stepCompleteSelectors, isPresent => {
    if (isPresent) {
      goToNextStep()
    }
  })

  useEffect(() => {
    setShowSelectorFallbackContent(false)
  }, [currentStep.selector])

  useElementDetector(
    [currentStep.selector],
    isPresent => setIsSelectorElementPresent(isPresent),
    5000,
    isPresent => {
      setShowSelectorFallbackContent(currentStep.selectorFallbackContent && !isPresent)
    }
  )

  const progress = ((stepIndex + 1) / steps.length) * 100

  const showNext =
    !currentStep.stepCompleteSelectors &&
    stepIndex < steps.length - 1 &&
    !showSelectorFallbackContent

  const showDone = stepIndex === steps.length - 1 && !showSelectorFallbackContent

  const content = showSelectorFallbackContent
    ? currentStep.selectorFallbackContent
    : currentStep.body

  return (
    <div>
      <Typography textToken='text-style/text/paragraphs/body/medium'>{title}</Typography>
      <SpacerVertical small />
      <ProgressBar percentage={progress} />
      <SpacerVertical large />
      <TitlePrimary small>{currentStep.title}</TitlePrimary>
      <SpacerVertical small />
      {documentToReactComponents(content, renderOptions(navigation))}
      <SpacerVertical small />
      <TermList terms={currentStep.terms} />
      <div style={{ display: 'flex', marginTop: 10 }}>
        {stepIndex > 0 && (
          <ButtonPrimary
            size='small'
            onClick={() => {
              goToPreviousStep()
            }}
          >
            {t('help-tool.tour.prev', {}, 'Previous')}
          </ButtonPrimary>
        )}
        {showNext && (
          <div style={{ marginLeft: 'auto' }}>
            <ButtonPrimary
              size='small'
              onClick={() => {
                goToNextStep()
              }}
            >
              {t('help-tool.tour.next', {}, 'Next')}
            </ButtonPrimary>
          </div>
        )}
        {showDone && (
          <div style={{ marginLeft: 'auto' }}>
            <ButtonPrimary
              size='small'
              onClick={() => {
                navigation.pop()
              }}
            >
              {t('help-tool.tour.done', {}, 'Done')}
            </ButtonPrimary>
          </div>
        )}
        {isVisible && isSelectorElementPresent && (
          <Overlay onClick={() => setVisible(false)}>
            <Spotlight target={currentStep.selector} onClickOutside={() => setVisible(false)} />
          </Overlay>
        )}
      </div>
    </div>
  )

  function goToPreviousStep () {
    let lastNotOptionalIndex = stepIndex - 1
    while (lastNotOptionalIndex >= 0 && steps[lastNotOptionalIndex].optional) {
      lastNotOptionalIndex -= 1
    }
    const stepUrl = steps[lastNotOptionalIndex].stepUrl

    if (stepUrl) {
      navigateIfNotCurrent(stepUrl, history)
    } else {
      navigateIfNotCurrent(pathByStepIndex.current[lastNotOptionalIndex], history)
    }
    setStepIndex(lastNotOptionalIndex)
    setVisible(true)
  }

  function goToNextStep () {
    const nextNonOptionalStepIndex = steps.findIndex((x, idx) => idx > stepIndex && !x.optional)
    const stepUrl = steps[nextNonOptionalStepIndex].stepUrl
    pathByStepIndex.current[stepIndex] = window.location.pathname
    navigateIfNotCurrent(stepUrl, history)
    setStepIndex(nextNonOptionalStepIndex)
    setVisible(true)
  }
}

export default Tour
