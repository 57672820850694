import React, { useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { APP_CONTAINER_SELECTOR_FRAMEWORK } from '../../constants'
import PropTypes from 'prop-types'

TourOverlay.propTypes = {
  onClick: PropTypes.func.isRequired
}

const overlayContainerStyle =
  'position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 100; mix-blend-mode: hard-light; pointer-events: none;'

function TourOverlay ({ children, onClick }) {
  const overlayContainerElement = document.createElement('div')
  overlayContainerElement.style = overlayContainerStyle
  const overlay = useRef(overlayContainerElement)

  useLayoutEffect(() => {
    const main = document.querySelector(APP_CONTAINER_SELECTOR_FRAMEWORK).parentNode

    if (main) {
      main.appendChild(overlay.current)
      return () => main.removeChild(overlay.current)
    }
  }, [overlay.current])

  return (
    overlay &&
    overlay.current &&
    ReactDOM.createPortal(
      <div
        onClick={onClick}
        id='tour-overlay'
        style={{
          zIndex: 9999,
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: '#000',
          opacity: 0.6,
          mixBlendMode: 'hard-light',
          pointerEvents: 'none'
        }}
      >
        {children}
      </div>,
      overlay.current
    )
  )
}

export default TourOverlay
