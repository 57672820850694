import { dropLast, update, append } from 'ramda'
import actions from './HelpContextActions'
import { getInitialState, initialNavigationStack } from './HelpContextState'
import * as Sentry from '@sentry/react'

export function reducer (state, action) {
  try {
    switch (action.type) {
      case actions.SET_SIDEBAR_OPEN:
        return {
          ...state,
          sidebar: { isOpen: action.isOpen, isOpening: action.isOpen }
        }
      case actions.SET_SIDEBAR_OPENING:
        return {
          ...state,
          sidebar: { ...state.sidebar, isOpening: action.isOpening }
        }
      case actions.NAVIGATE_POP: {
        const navigationStack = dropLast(1)(state.navigationStack)
        return { ...state, navigationStack }
      }
      case actions.NAVIGATE_PUSH: {
        const { path, parameters } = action
        const navigationStack = append({ path, parameters })(state.navigationStack)
        const sidebar = { ...state.sidebar, isOpen: true }
        return { ...state, navigationStack, sidebar }
      }
      case actions.NAVIGATE_REPLACE: {
        const { path, parameters } = action
        const lastIdx = Math.max(state.navigationStack.length - 1, 0)
        const navigationStack = update(lastIdx, { path, parameters }, state.navigationStack)
        return { ...state, navigationStack }
      }
      case actions.NAVIGATE_RESET: {
        return {
          ...state,
          navigationStack: initialNavigationStack,
          search: getInitialState(state.sidebar.animationDuration).search
        }
      }
      case actions.SET_SEARCH_QUERY: {
        const lastIdx = Math.max(state.navigationStack.length - 1, 0)
        const navigationStack = update(
          lastIdx,
          {
            ...state.navigationStack[lastIdx],
            searchQuery: action.query
          },
          state.navigationStack
        )
        return { ...state, navigationStack }
      }
      case actions.SET_IS_SEARCHING: {
        const lastIdx = Math.max(state.navigationStack.length - 1, 0)
        const navigationStack = update(
          lastIdx,
          {
            ...state.navigationStack[lastIdx],
            isSearching: action.isSearching
          },
          state.navigationStack
        )
        return { ...state, navigationStack }
      }
      case actions.RESET_HELPTOOL: {
        const initialState = getInitialState(state.sidebar.animationDuration)
        return {
          ...initialState,
          sidebar: {
            ...initialState.sidebar,
            isOpen: true
          }
        }
      }
      case actions.RESET: {
        return getInitialState(state.sidebar.animationDuration)
      }
      case actions.OPEN_WIDGET: {
        return {
          ...state,
          isWidgetOpen: true
        }
      }
      case actions.CLOSE_WIDGET: {
        return {
          ...state,
          isWidgetOpen: false
        }
      }
      default: {
        return state
      }
    }
  } catch (error) {
    console.error('Error in Help Context Reducer: ', error)
    Sentry.withScope(scope => {
      scope.setTag('team', 'met')
      Sentry.captureException(error)
    })
    return state
  }
}

export default reducer
