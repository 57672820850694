import { usePageContext } from '@merchant-portal/framework';
import { hooks as i18nHooks } from 'mage-i18n';
import React, { useEffect } from 'react';
import BoosterAppList from './BoosterAppList';
import WelcomeBanner from './WelcomeBanner';
export default function BoostHome() {
    const { setPageContext = () => { } } = usePageContext();
    const t = i18nHooks.useTranslator();
    useEffect(() => {
        setPageContext({
            title: t('core.apps.boosters-home-fe.title')
        });
    }, [t, setPageContext]);
    return (React.createElement("div", { id: 'boost-home' },
        React.createElement(WelcomeBanner, null),
        React.createElement(BoosterAppList, null)));
}
