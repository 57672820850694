import { __rest } from "tslib";
import { AlertBlockError, SpacerVertical, SwitchToggle } from '@klarna/bubble-ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useLabelTranslation from '../hooks/useLabelTranslation';
import useValidationErrorLabel from '../hooks/useValidationErrorLabel';
ToggleField.propTypes = {
    name: PropTypes.string.isRequired
};
export default function ToggleField(_a) {
    var { name } = _a, props = __rest(_a, ["name"]);
    const label = useLabelTranslation(name);
    const errorMessage = useValidationErrorLabel(name);
    const [field, , { setValue }] = useField(name);
    return (React.createElement(React.Fragment, null,
        React.createElement(SwitchToggle, Object.assign({}, field, props, { checked: field.value, onChange: setValue, rightAligned: true }), label),
        errorMessage && (React.createElement(React.Fragment, null,
            React.createElement(SpacerVertical, { small: true }),
            React.createElement(AlertBlockError, { content: [errorMessage] })))));
}
