import { getFreshChatConfig } from './config';
import generateTag from './generateTag';
const FRESHCHAT_APP_LOGO_URL = 'https://a.klarnacdn.net/klapp/assets/support-chat/klarna_logo.png';
const BACKGROUND_IMAGE_BASE_64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';
export const getFreschatWidgetOptions = (ctx) => {
    const freshChatConfig = getFreshChatConfig(ctx.env, ctx.region);
    const font = ctx.designContext.get('text-style/headings/titles/medium/tertiary');
    return {
        token: freshChatConfig.token,
        host: freshChatConfig.host,
        tags: [generateTag(ctx)],
        externalId: ctx.tokenHelper.getEmail(),
        restoreId: getRestoreId(ctx),
        config: {
            agent: {
                hidePic: true,
                hideName: true,
                hideBio: true
            },
            headerProperty: {
                hideChatButton: true,
                appName: 'Klarna',
                appLogo: FRESHCHAT_APP_LOGO_URL,
                backgroundColor: ctx.designContext.get('colors/backgrounds/inverse').toString(),
                foregroundColor: ctx.designContext.get('colors/text/inverse').toString(),
                backgroundImage: BACKGROUND_IMAGE_BASE_64,
                fontName: font.fontFamily
            },
            content: {
                headers: {
                    chat: ctx.t('conversation.widget.title'),
                    chat_help: ' '
                }
            }
        }
    };
};
export const getFreshchatWidgetUrl = (ctx) => {
    const freshChatConfig = getFreshChatConfig(ctx.env, ctx.region);
    return freshChatConfig.widget;
};
const getRestoreId = ({ restoreIdByRegion, region }) => restoreIdByRegion === null || restoreIdByRegion === void 0 ? void 0 : restoreIdByRegion[region];
