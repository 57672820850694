import styled, { css } from 'styled-components';
import { useResponsiveContext } from '@klarna/mp-ui';
import { POPOVER_Z } from '../../common/FeatureTour';
const popoverPadding = 18;
const beforeArrowCss = ({ backgroundColor, position, refCoordinates }) => {
    const styles = {
        top: css `
        &:before {
          border-color: ${backgroundColor} transparent transparent transparent;
          bottom: -17px;
          left: 16px;
        }
      `,
        right: css `
        &:before {
          border-color: transparent ${backgroundColor} transparent transparent;
          right: calc(100% - 1px);
          top: 16px;
        }
      `,
        bottom: css `
        &:before {
          border-color: transparent transparent ${backgroundColor} transparent;
          bottom: calc(100% - 1px);
          left: calc(${refCoordinates.left + refCoordinates.width / 2 - popoverPadding}px);
        }
      `
    };
    const commonArrowCss = css `
    &:before {
      border-width: 9px;
      border-style: solid;
      content: "";
      position: absolute;
    }
  `;
    return css `
    ${commonArrowCss}
    ${styles[position]}
  `;
};
const containerCss = ({ isMobile, refCoordinates, padding = 0, position }) => {
    const styles = {
        top: css `
        bottom: calc(100% - ${refCoordinates.top - 24 - padding}px);
        left: ${refCoordinates.left - padding}px;
        margin-left: 0;
        max-width: 350px;
      `,
        right: css `
        top: ${refCoordinates.top}px;
        left: ${refCoordinates.right}px;
        width: 296px;
        margin-left: 16px;
      `,
        bottom: css `
        top: ${refCoordinates.bottom + 24 + padding}px;
        left: ${refCoordinates.left - padding}px;
        margin-left: 0;
      `
    };
    const mobileStyles = {
        top: css `
        left: initial;
        width: calc(100vw - 24px);
        max-width: initial;
        margin-left: 8px;
      `,
        bottom: css `
        left: initial;
        width: calc(100vw - 24px);
        max-width: initial;
        margin-left: 8px;
      `
    };
    return css `
    ${styles[position]}
    ${isMobile && mobileStyles[position] ? mobileStyles[position] : ''}
  `;
};
export const CloseButtonContainer = styled.div `
  position: absolute;
  top: -15px;
  right: -15px;
`;
export const PopoverContainer = styled.div(({ refCoordinates, backgroundColor, padding, position }) => {
    const { isMobile } = useResponsiveContext();
    return css({
        backgroundColor,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '18px',
        position: 'fixed',
        zIndex: POPOVER_Z
    }, ...containerCss({ refCoordinates, isMobile, padding, position }), ...beforeArrowCss({ backgroundColor, position, refCoordinates }));
});
