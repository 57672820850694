import React from 'react'
import PropTypes from 'prop-types'
import { SelectorDirect } from '@klarna/bubble-ui'
import { useHelpContext } from '../../hooks'

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  analyticsCategory: PropTypes.string.isRequired
}

export default function ArticleList ({ articles, analyticsCategory }) {
  const { navigation } = useHelpContext()

  function handleSelect (articleId) {
    const article = articles.find(article => article.id === articleId)

    navigation.push('article', { articleSlug: article.slug })
  }

  if (!articles || !articles.length) return null

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <SelectorDirect id='uhqm-article-list' onSelect={handleSelect} options={getOptions(articles)} />
  )
}

function getOptions (articles) {
  return articles.map(article => ({ value: article.id, label: article.title }))
}
