import React from 'react'
import PropTypes from 'prop-types'
import { useCMSEntries } from 'mage-cms'
import { Analytics } from 'mage-insights'
import Tour from './Tour'
import NotAvailableOnMobile from './NotAvailableOnMobile'
import { useResponsiveContext } from '@klarna/mp-ui'

TourPage.propTypes = {
  parameters: PropTypes.shape({
    tourSlug: PropTypes.string
  })
}

function TourPage ({ parameters: { tourSlug } }) {
  const { isMobile, isTablet } = useResponsiveContext()

  const entries = useCMSEntries({
    contentType: 'UHQMTour',
    filters: { slug: tourSlug },
    include: 2 // Includes items.terms
  })

  if (isMobile || isTablet) {
    return <NotAvailableOnMobile />
  }

  const entry = entries && entries.length > 0 ? entries[0] : null
  const steps = entry ? entry.items : []

  if (!steps || steps.length === 0) {
    return null
  }

  return (
    <>
      <Tour steps={steps} title={entry.title} />
      <Analytics
        category='uhqm/page/tour'
        action='pageview'
        label={tourSlug}
        referenceType='tour'
        referenceId={entries[0].id}
      />
    </>
  )
}

export default TourPage
