const DEFAULT_RESTORE_ID = {
    eu: '',
    ap: '',
    us: ''
};
export const getTransformedRestoreId = (regionalResponses) => {
    const restoreId = DEFAULT_RESTORE_ID;
    regionalResponses.forEach((regionalResponse) => {
        var _a;
        return (restoreId[regionalResponse.region] = ((_a = regionalResponse.response.data) === null || _a === void 0 ? void 0 : _a.restore_id) || '');
    });
    return restoreId;
};
export const getTransformedWorkingHours = (regionalResponses) => {
    const workingHours = regionalResponses.map(({ response, region }) => { var _a; return (transformWorkingHours((_a = response.data) === null || _a === void 0 ? void 0 : _a.working_hours, region)); })
        .flat()
        .filter(workingHours => workingHours);
    return workingHours;
};
const transformWorkingHours = (workingHoursInResponse, region) => (workingHoursInResponse
    ? workingHoursInResponse.map(transformWorkingHour(region)).filter(filterWorkingHours)
    : []);
const transformWorkingHour = (region) => (workingHour) => ({
    marketCountryCodes: workingHour.market_country_codes,
    timeFramesByWeekday: workingHour.timeframes_by_weekday,
    timezone: workingHour.timezone,
    region: region
});
const filterWorkingHours = (workingHour) => (workingHour.marketCountryCodes.length > 0);
