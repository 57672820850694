import { hooks } from 'mage-i18n';
import React from 'react';
// @ts-ignore
import { View, Typography } from '@klarna/bubble-ui';
import { useHelpbotState } from '../contexts/HelpbotStateProvider';
const styles = {
    optionContainer: {
        marginBottom: '10px',
        alignItems: 'flex-end'
    },
    optionChip: {
        border: '1px solid rgb(227, 229, 233)',
        borderRadius: '9999px',
        padding: '9px 15px',
        width: 'fit-content',
        cursor: 'pointer'
    }
};
const HelpbotOption = ({ option: { value } }) => {
    const { handleAnswerForActiveStep } = useHelpbotState();
    const t = hooks.useTranslator();
    const [hovered, setHovered] = React.useState(false);
    const handleOnPress = () => {
        handleAnswerForActiveStep(value);
    };
    const chipCustomStyle = hovered ? { backgroundColor: 'rgb(241, 241, 241)' } : {};
    return (React.createElement(View, { style: styles.optionContainer },
        React.createElement(View, { style: Object.assign(Object.assign({}, styles.optionChip), chipCustomStyle), onClick: handleOnPress, onMouseOver: () => setHovered(true), onMouseOut: () => setHovered(false) },
            React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular' }, t(value)))));
};
export { HelpbotOption };
