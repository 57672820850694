import { handleActions } from 'redux-actions'

import { NAME } from './constants'

const init = {
  banners: []
}

const reducer = handleActions({}, init)

export default () => ({
  [NAME]: reducer
})

export { reducer }
