import { ActionIconChevronBottom, ButtonQuaternary } from '@klarna/bubble-ui';
import { Selector } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import Insights from 'mage-insights';
import PropTypes from 'prop-types';
import React from 'react';
import { CONSTANTS } from '../model';
const trackCurrencyChange = (currency) => {
    return Insights.event({
        category: 'homepageContent:salesWidget',
        action: 'change_currency',
        label: currency
    });
};
export const CurrencySelector = ({ currency, onChange }) => {
    const t = i18nHooks.useTranslator();
    const i18nPath = 'home-fe';
    return (React.createElement(Selector, { value: currency, onClose: () => trackCurrencyChange(currency), onChange: onChange, options: Object.keys(CONSTANTS.CURRENCIES).map(key => ({ label: key, value: CONSTANTS.CURRENCIES[key] })), closeLabel: t(`${i18nPath}.insightsWidget.closeText`) },
        React.createElement(ButtonQuaternary, { icon: ActionIconChevronBottom, iconAlignment: 'right', size: 'small' }, t(`${i18nPath}.insightsWidget.currencyFootnote`, { currency }))));
};
CurrencySelector.propTypes = {
    currency: PropTypes.string,
    onChange: PropTypes.func
};
